
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ChannelFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Channel"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"operatorRef"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"title"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"channelType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"epgStationId"},"arguments":[],"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"ChannelImagesFragment"},"directives":[]}]}}],"loc":{"start":0,"end":160}};
    doc.loc.source = {"body":"#import \"./ChannelImagesFragment.graphql\"\n\nfragment ChannelFragment on Channel {\n\tid\n\toperatorRef\n\ttitle\n\tchannelType\n\tepgStationId\n\t...ChannelImagesFragment\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("./ChannelImagesFragment.graphql").definitions));


      module.exports = doc;
    
