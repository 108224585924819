import React, { useMemo } from 'react';
import { Content, ImagePreset } from '@vodafoneis/sjonvarpskjarni-js-lib';
import styled from 'styled-components';
import { ImageComponent } from './ImageComponent';

const ContentBackground = styled(ImageComponent)`
	display: flex;
	position: absolute;
	width: 1920px;
	height: 1080px;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top center;
	background-attachment: fixed;
`;

type ContentBackgroundImageProps = {
	content?: Content;
	className?: string
};

export const ContentBackgroundImage: React.FC<ContentBackgroundImageProps> = ({ content, className, children }) => {
	const backdropImageUrls = useMemo(
		() =>
			content ? [content.getBackdropImageUrl({ presets: [ImagePreset.BACKDROP, ImagePreset.BACKDROP_OVERLAY] })] : [],
		[content]
	);

	return <ContentBackground className={className} images={backdropImageUrls}>{children}</ContentBackground>;
};
