import styled from 'styled-components';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { PRIMARY } from '../styles/colors';
import { useCallback, useContext, useMemo } from 'react';
import { UserContext } from '../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Make the focusable container stretch the width of the page to allow for correct
// navigation to anywhere on the page
const CurrentUserContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
`;

const CurrentUserName = styled.div`
	display: flex;
	flex-wrap: nowrap;
	font-size: 24px;
`;

const LoginLogoutButton = withFocusable()(styled.div<{ focused: boolean }>`
	color: ${PRIMARY};
	font-size: 24px;
	border-bottom: 2px solid;
	border-bottom-color: ${({ focused }) => (focused ? PRIMARY : 'transparent')};
	padding-bottom: 3px;
	margin-bottom: -3px;
`);

export const CurrentUser = () => {
	const { user, isLoggedIn, logout } = useContext(UserContext);

	const { t } = useTranslation();

	const navigate = useNavigate();

	const onClick = useCallback(() => {
		if (isLoggedIn) {
			logout();
		} else {
			navigate('/login');
		}
	}, [isLoggedIn, logout, navigate]);

	const loginLogoutText = useMemo(() => (isLoggedIn ? t('User.Logout') : t('User.Login')), [isLoggedIn, t]);

	return (
		<CurrentUserContainer>
			{isLoggedIn && user ? <CurrentUserName>{user.name}</CurrentUserName> : null}
			<LoginLogoutButton onEnterPress={onClick}>{loginLogoutText}</LoginLogoutButton>
		</CurrentUserContainer>
	);
};
