import type { Content } from './Content';
import { ModuleType } from './ModuleType';
import { ModuleTemplate } from './ModuleTemplate';

export class Collection {
	id?: number;
	title?: string;
	color?: string;
	moduleType?: ModuleType;
	moduleTemplate?: ModuleTemplate;
	content?: Content[];

	constructor(data: object) {
		Object.assign(this, data);
	}
}
