import { useMemo } from 'react';
import { Episode, Series } from '../models';
import { usePlayable } from './usePlayable';

export const useNextEpisode = (series: Series, episodes: Episode[]) => {
	const nextEpisode = useMemo(() => episodes?.find((ep) => ep.id === series?.nextEpisode?.id) ?? episodes[0], [
		series,
		episodes,
	]);

	const { isWatchable } = usePlayable(nextEpisode);

	// Make sure TVOD episodes are not returned as nextEpisode unless they have an active rental.
	return !isWatchable && nextEpisode?.isTVOD() ? null : nextEpisode;
};
