import { useEffect } from 'react';
import { usePrevious } from './usePrevious';

type WhyDidYouUpdatePropsType = { [key: string]: any };

export function useWhyDidYouUpdate(name: string, props: WhyDidYouUpdatePropsType) {
	const previousProps: WhyDidYouUpdatePropsType | null = usePrevious(props);

	useEffect(() => {
		if (previousProps) {
			// Get all keys from previous and current props
			const allKeys = Object.keys({ ...previousProps, ...props });
			// Use this object to keep track of changed props
			const changesObj = {};
			// Iterate through keys
			allKeys.forEach(key => {
				// If previous is different from current
				if (previousProps[key] !== props[key]) {
					// Add to changesObj
					// @ts-ignore
					changesObj[key] = {
						from: previousProps[key],
						to: props[key],
					};
				}
			});

			// If changesObj not empty then output to console
			if (Object.keys(changesObj).length) {
				// @ts-ignore
				const logFunction = console.tron ? console.tron.display : console.log;
				logFunction({
					name: 'WHY DID YOU UPDATE',
					preview: name,
					value: changesObj,
				});
			}
		}
	}, [name, previousProps, props]);
}
