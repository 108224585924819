import type { Recording } from './Recording';
import type { Channel } from './Channel';
import type { Image } from './Image';
import { generateImagePath, ImagePathParams } from '../utils';

export class Event {
	id?: number;
	title?: string;
	originalTitle?: string;
	description?: string;
	startTime?: Date;
	endTime?: Date;
	minimumAge?: number;
	episodeNumber?: number;
	seasonNumber?: number;
	totalEpisodes?: number;
	rerun?: boolean;
	recording?: Recording;
	channel?: Channel;
	backdropImage?: Image;

	constructor(data: any) {
		Object.assign(this, data);

		const { startTime, endTime } = data;

		if (startTime) this.startTime = new Date(startTime * 1000);
		if (endTime) this.endTime = new Date(endTime * 1000);
	}

	getDuration() {
		if (this.endTime && this.startTime) {
			const timeDiff = (this.endTime.getTime() - this.startTime.getTime()) / 1000;
			return Math.round(timeDiff);
		}
		return 0;
	}

	getAgeRating() {
		return this.minimumAge || 0;
	}

	getDurationHMS() {
		let duration = this.getDuration();

		const durationSeconds = duration % 60;
		duration = (duration - durationSeconds) / 60; // duration now remaining minutes

		const durationMinutes = duration % 60;
		const durationHours = (duration - durationMinutes) / 60; // duration now remaining Hours

		return {
			seconds: durationSeconds,
			minutes: durationMinutes,
			hours: durationHours,
		};
	}

	getProgress() {
		const { startTime, endTime } = this;

		const now = new Date();

		if (!startTime || !endTime) return 0;
		if (startTime.getTime() >= now.getTime()) return 0;
		if (endTime.getTime() <= now.getTime()) return 1;

		const progress = (now.getTime() - startTime.getTime()) / 1000;

		return progress / this.getDuration();
	}

	isCurrent(buffer = 0) {
		if (!this.startTime || !this.endTime) return false;

		const now = new Date();

		return (
			this.startTime.getTime() - buffer * 1000 < now.getTime() &&
			this.endTime.getTime() + buffer * 1000 > now.getTime()
		);
	}

	isFinished() {
		if (!this.endTime) return false;

		const now = new Date();

		return this.endTime.getTime() < now.getTime();
	}

	getBackdropImageUrl(params: ImagePathParams = {}) {
		if (this.backdropImage) {
			return generateImagePath({
				hash: this.backdropImage.hash,
				...params,
			});
		}

		return this.channel?.getBackdropImageUrl(params);
	}
}
