export enum DRM_DEVICE_TYPE {
	BROWSER = 'Browser',
	HANDHELD = 'Handheld',
}

export enum DRM_CLIENT_TYPE {
	WIDEVINE_HTML5 = 'Widevine-HTML5',
	FAIRPLAY_IOS = 'FairPlay-iOS',
	WIDEVINE_ANDROID = 'Widevine-Android',
}

type ConaxCustomData = {
	authenticationDataToken: string;
	deviceType: DRM_DEVICE_TYPE;
	drmClientType: DRM_CLIENT_TYPE;
	deviceId: string;
};

export class Conax {
	/**
	 * @deprecated use DRM_DEVICE_TYPE instead
	 */
	static DEVICE_TYPES = DRM_DEVICE_TYPE;

	/**
	 * @deprecated use DRM_CLIENT_TYPE instead
	 */
	static DRM_CLIENT_TYPES = DRM_CLIENT_TYPE;

	static generateCustomData({ authenticationDataToken, deviceType, drmClientType, deviceId }: ConaxCustomData) {
		return JSON.stringify({
			Version: '1.0.0',
			CxAuthenticationDataToken: authenticationDataToken,
			CxClientInfo: {
				DeviceType: deviceType,
				DrmClientType: drmClientType,
				DrmClientVersion: '1.0.0',
				CxDeviceId: deviceId,
			},
		});
	}
}
