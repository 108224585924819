import { Client } from '@vodafoneis/sjonvarpskjarni-js-lib';

const API_HOST = process.env.REACT_APP_PUBLIC_API_HOST as string;
const API_VERSION = process.env.REACT_APP_PUBLIC_API_VERSION as string;

export default new Client({
	baseUrl: `${API_HOST.replace(/\/*$/, '')}/`,
	defaultHeaders: {
		'Content-Type': 'application/json',
		'Accept-Version': API_VERSION,
	},
});
