import { TagType } from './TagType';
import { TagInterface } from './TagInterface';

export class Tag implements TagInterface {
	id?: number;
	externalId?: string;
	title?: string;
	type?: TagType;

	constructor(data: any) {
		Object.assign(this, data);
	}

	isOfType(type: TagType): boolean {
		return this.type === type;
	}
}
