import { Content } from './Content';

export class SearchResults {
	id?: number;
	content?: Content;
	seasons?: number[];
	seasonCount?: number;
	episodeCount?: number;

	constructor(data: object) {
		Object.assign(this, data);
	}
}
