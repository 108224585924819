import type { ProviderType } from './ProviderType';

export class Provider {
	id?: number;
	title?: string;
	type?: ProviderType;
	allowsExternalPlayback?: boolean;

	constructor(data: object) {
		Object.assign(this, data);
	}
}
