// @ts-ignore
import uuid from 'uuid/v3';

const NAMESPACE = '64582017-26c6-49af-95ce-51583824b76d';

export class UUID {
	static fromString(string: string) {
		return uuid(string, NAMESPACE);
	}
}
