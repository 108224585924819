export enum ImagePreset {
	BACKDROP = '720p',
	BACKDROP_TINY = 'backdrop-tiny',
	BACKDROP_SMALL = 'backdrop-small',
	BACKDROP_MEDIUM = 'backdrop-medium',
	BACKDROP_LARGE = 'backdrop-large',
	BACKDROP_OVERLAY = 'w-backdrop-overlay-teal',
	STILL_SMALL = 'still-small',
	STILL_MEDIUM = 'still-medium',
	STILL_LARGE = 'still-large',
	STILL_OVERLAY = 'w-still-overlay',
	POSTER_TINY = 'poster-tiny',
	POSTER_SMALL = 'poster-small',
	POSTER_MEDIUM = 'poster-medium',
	AD_TINY = 'ad-tiny',
	AD_SMALL = 'ad-small',
	AD_MEDIUM = 'ad-medium',
	SERVICE_TILE_SMALL = 'service-tile-small',
	SERVICE_TILE_MEDIUM = 'service-tile-medium',
	CATEGORY_BANNER_LARGE = 'category-banner-large',
	MODULE_M2 = 'module-m2',
	MODULE_M3 = 'module-m3',
	MODULE_M4 = 'module-m4',
	PRODUCT_ICON_TINY = 'product-icon-tiny',
	PRODUCT_ICON_SMALL = 'product-icon-small',
	CREDIT_SMALL = 'credit-small',
}

export enum ImageType {
	POSTER = 'POSTER',
	FANART = 'FANART',
	STILL = 'STILL',
	PRODUCT_ICON = 'PRODUCT_ICON',
	AD = 'AD',
	BACKDROP = 'BACKDROP',
	CHANNEL_ICON = 'CHANNEL_ICON',
	HOPSTER = 'HOPSTER',
	HOPSTER_FOCUS = 'HOPSTER_FOCUS',
	CREDIT = 'CREDIT',
}

type ImageParams = {
	hash?: string;
};

export type ImagePathParams = {
	presets?: ImagePreset[];
	ext?: 'jpg' | 'png';
	dpr?: number;
};

export function generateImagePath({ presets, ext = 'jpg', hash, dpr }: ImageParams & ImagePathParams) {
	let url = `/images/${hash}.${ext}`;

	const params = [];

	if (presets && presets.length > 0) {
		params.push(`p=${presets.join(',')}`);

		if (dpr > 1) {
			params.push(`dpr=${dpr}`);
		}
	}

	if (url && params.length > 0) {
		url += `?${params.join('&')}`;
	}

	return url;
}
