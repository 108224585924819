import type { ApolloError, QueryHookOptions } from '@apollo/client';
import { useMemo } from 'react';
import { factory } from '../models';
import type { Channel } from '../models';
import { CHANNEL_QUERY } from '../queries';
import { useAuthenticatedQuery } from './useAuthenticatedQuery';

type UseChannelReturn = {
	channel: Channel;
	loadingChannel: boolean;
	channelError: ApolloError;
};

export const useChannel = (channelId: number, queryOptions: QueryHookOptions<any, any> = {}): UseChannelReturn => {
	const options = useMemo(
		() => ({
			variables: { channelId },
			skip: !channelId,
			...queryOptions,
		}),
		[channelId, queryOptions]
	);

	const { data, loading, error } = useAuthenticatedQuery(CHANNEL_QUERY, options);

	const channel = useMemo(() => (data?.channel ? factory(data.channel) : null), [data]);

	return { channel, loadingChannel: loading, channelError: error };
};
