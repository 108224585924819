
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CategoryFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Category"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"title"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"type"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"moduleType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"moduleTemplate"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"viewTemplate"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"color"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"adRegionId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"product"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ProductFragment"},"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"ProductImagesFragment"},"directives":[]}]}}]}}],"loc":{"start":0,"end":261}};
    doc.loc.source = {"body":"#import \"./ProductFragment.graphql\"\n#import \"./ProductImagesFragment.graphql\"\n\nfragment CategoryFragment on Category {\n\tid\n\ttitle\n\ttype\n\tmoduleType\n\tmoduleTemplate\n\tviewTemplate\n\tcolor\n\tadRegionId\n\tproduct {\n\t\t...ProductFragment\n\t\t...ProductImagesFragment\n\t}\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("./ProductFragment.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("./ProductImagesFragment.graphql").definitions));


      module.exports = doc;
    
