export class Property {
	name?: string;
	value?: string;

	constructor(data: object) {
		Object.assign(this, data);
	}

	getJsonValue() {
		try {
			return JSON.parse(this.value);
		} catch (exeption) {}

		return null;
	}
}
