import { Exception } from '../exceptions';

export class GraphQLQuery {
	itemName: string = null;
	properties: any = null;
	variables: any = null;
	transformer = (data: any) => data;
	alias: string = null;

	constructor(itemName: string, { properties, variables, transformer, alias }: any) {
		if (!itemName) throw new Exception('Query needs to be initialized with an item name');

		Object.assign(this, {
			itemName,
			properties,
			variables,
			transformer: transformer || this.transformer,
			alias,
		});
	}

	getItemKey() {
		return this.alias || this.itemName;
	}

	_generateVariablesString() {
		if (!this.variables) return '';

		const variableKeys = Object.keys(this.variables);
		if (variableKeys.length <= 0) return '';

		const variableParts = variableKeys.map(key => `${key}:${this.variables[key]}`);

		return `(${variableParts.join(',')})`;
	}

	_getPropertyValue(property: any) {
		if (property instanceof GraphQLQuery) {
			return property.generateQuery();
		}
		return property;
	}

	_generatePropertyParts() {
		if (!this.properties || this.properties.length <= 0) return null;
		const propertyParts: any[] = [];
		this.properties.forEach((property: any) => {
			propertyParts.push(this._getPropertyValue(property));
		});
		return propertyParts.join(',');
	}

	_generateAliasPart() {
		if (this.alias) {
			return `${this.alias}:`;
		}
		return '';
	}

	generateQuery() {
		const variablesString = this._generateVariablesString();
		const propertiesString = this._generatePropertyParts();

		const query = [this._generateAliasPart() + this.itemName + variablesString + (propertiesString ? '{' : '')];

		if (propertiesString) {
			query.push(propertiesString);
		}

		if (propertiesString) query.push('}');
		return query.join('');
	}

	static wrapWithQuery({ queries, variables }: any) {
		return new GraphQLQuery('query', {
			properties: queries,
			variables,
		});
	}

	wrapWithQuery(variables: any) {
		return GraphQLQuery.wrapWithQuery({
			queries: [this],
			variables,
		});
	}

	getSubqueries() {
		// @ts-ignore
		return this.properties ? this.properties.filter(property => property instanceof GraphQLQuery) : [];
	}

	transform(data: any) {
		// eslint-disable-next-line no-prototype-builtins
		if (data && data.hasOwnProperty(this.getItemKey())) {
			// @ts-ignore
			const currentData = data[this.getItemKey()];

			if (currentData) {
				// @ts-ignore
				this.getSubqueries().forEach(subQuery => {
					if (Array.isArray(currentData)) {
						currentData.forEach(nextData => {
							// eslint-disable-next-line no-param-reassign
							nextData[subQuery.getItemKey()] = subQuery.transform(nextData);
						});
					} else {
						currentData[subQuery.getItemKey()] = subQuery.transform(currentData);
					}
				});
			}

			return this.transformer(currentData);
		}

		if (data) {
			this.getSubqueries().forEach((subQuery: any) => {
				// eslint-disable-next-line no-param-reassign
				data[subQuery.getItemKey()] = subQuery.transform(data);
			});
		}

		return data;
	}
}
