export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string;
	String: string;
	Boolean: boolean;
	Int: number;
	Float: number;
	/**
	 * The `Long` scalar type represents non-fractional signed whole numeric
	 * values. Long can represent values between -(2^53) and 2^53 - 1.
	 */
	Long: any;
};

export type Query = {
	__typename?: 'Query';
	/** Returns a list of TV and Radio channels. */
	channels?: Maybe<Array<Maybe<Channel>>>;
	channel?: Maybe<Channel>;
	/** Retrieves meta data for events between start and end time on the specified station. startHour is hours in Unix time and delta is f.ex. 24. */
	events?: Maybe<Array<Maybe<Event>>>;
	/** Retrieves meta data for a specific EPG event. */
	event?: Maybe<Event>;
	/** Retrieves meta data for upcoming live events. */
	live?: Maybe<Array<Maybe<Event>>>;
	/** Fetch category. */
	category?: Maybe<Category>;
	/** Fetch movie. */
	movie?: Maybe<Movie>;
	/** Get content by ID. */
	content?: Maybe<Content>;
	/** Get content by external ID. */
	contentByExternalId?: Maybe<Array<Maybe<Content>>>;
	/** Get series information. */
	series?: Maybe<Series>;
	/**
	 * Retrieves the list of VoD movie rentals for the authenticating customer account.
	 * @deprecated Use "watched" instead to get watch history.
	 */
	rentals?: Maybe<Array<Maybe<Rental>>>;
	/** Watch history. */
	watched?: Maybe<Array<Maybe<Content>>>;
	favorites?: Maybe<Array<Maybe<Content>>>;
	/** List ads for region. */
	ads?: Maybe<Array<Maybe<Ad>>>;
	/** Details for one ad. */
	ad?: Maybe<Ad>;
	/** Search for movies and series. */
	search?: Maybe<Array<Maybe<SearchResults>>>;
	/** Collection */
	collection?: Maybe<Collection>;
	/** Products */
	products?: Maybe<Array<Maybe<Product>>>;
	/** Product */
	product?: Maybe<Product>;
	/** Purchases */
	purchases?: Maybe<Array<Maybe<Purchase>>>;
	/** File */
	file?: Maybe<File>;
	/** Info about current logged in user. */
	user?: Maybe<User>;
};

export type QueryChannelsArgs = {
	type?: Maybe<ChannelType>;
	streamFormat: StreamFormat;
	limit?: Maybe<Scalars['Int']>;
	skip?: Maybe<Scalars['Int']>;
};

export type QueryChannelArgs = {
	id: Scalars['Int'];
};

export type QueryEventsArgs = {
	stationId: Scalars['Int'];
	startHour: Scalars['Int'];
	deltaHours: Scalars['Int'];
};

export type QueryEventArgs = {
	id: Scalars['Int'];
};

export type QueryLiveArgs = {
	channelIds?: Maybe<Array<Maybe<Scalars['String']>>>;
	filter?: Maybe<Array<Maybe<Scalars['String']>>>;
	limit?: Maybe<Scalars['Int']>;
	skip?: Maybe<Scalars['Int']>;
};

export type QueryCategoryArgs = {
	id: Scalars['Int'];
};

export type QueryMovieArgs = {
	id: Scalars['Int'];
};

export type QueryContentArgs = {
	id: Scalars['Int'];
};

export type QueryContentByExternalIdArgs = {
	externalId: Scalars['String'];
};

export type QuerySeriesArgs = {
	id: Scalars['Int'];
};

export type QueryRentalsArgs = {
	movieId?: Maybe<Scalars['Int']>;
	active?: Maybe<Scalars['Boolean']>;
	streamFormat?: Maybe<StreamFormat>;
	limit?: Maybe<Scalars['Int']>;
	skip?: Maybe<Scalars['Int']>;
};

export type QueryWatchedArgs = {
	limit?: Maybe<Scalars['Int']>;
	skip?: Maybe<Scalars['Int']>;
};

export type QueryFavoritesArgs = {
	limit?: Maybe<Scalars['Int']>;
	skip?: Maybe<Scalars['Int']>;
};

export type QueryAdsArgs = {
	regionId: Scalars['Int'];
	limit?: Maybe<Scalars['Int']>;
	skip?: Maybe<Scalars['Int']>;
};

export type QueryAdArgs = {
	id: Scalars['Int'];
};

export type QuerySearchArgs = {
	q: Scalars['String'];
	limit?: Maybe<Scalars['Int']>;
	skip?: Maybe<Scalars['Int']>;
};

export type QueryCollectionArgs = {
	id: Scalars['Int'];
};

export type QueryProductsArgs = {
	type: ProductType;
};

export type QueryProductArgs = {
	id: Scalars['Int'];
};

export type QueryPurchasesArgs = {
	type: ProductType;
	limit?: Maybe<Scalars['Int']>;
	skip?: Maybe<Scalars['Int']>;
};

export type QueryFileArgs = {
	id: Scalars['Int'];
};

/** Narrow list to only include channels of the specified type. */
export enum ChannelType {
	/** Return all channels. */
	All = 'ALL',
	/** Return TV channels. */
	Tv = 'TV',
	/** Return radio channels. */
	Radio = 'RADIO',
}

export enum StreamFormat {
	Rtsp = 'RTSP',
	Hls = 'HLS',
	Dash = 'DASH',
}

export type Channel = {
	__typename?: 'Channel';
	id?: Maybe<Scalars['Int']>;
	epgStationId?: Maybe<Scalars['Int']>;
	operatorRef?: Maybe<Scalars['String']>;
	channelType?: Maybe<Scalars['String']>;
	channelTypeInfo?: Maybe<Scalars['String']>;
	title?: Maybe<Scalars['String']>;
	parentalLock?: Maybe<Scalars['Boolean']>;
	fallbackAgeRating?: Maybe<Scalars['Int']>;
	properties?: Maybe<Array<Maybe<Property>>>;
	stream?: Maybe<Scalars['String']>;
	now?: Maybe<Event>;
	next?: Maybe<Event>;
	subscribed?: Maybe<Scalars['Boolean']>;
	image?: Maybe<Image>;
};

export type ChannelStreamArgs = {
	streamType?: Maybe<StreamFormat>;
	streamFormat?: Maybe<StreamFormat>;
};

export type ChannelImageArgs = {
	type: ImageType;
};

export type Property = {
	__typename?: 'Property';
	name?: Maybe<Scalars['String']>;
	value?: Maybe<Scalars['String']>;
};

export type Event = {
	__typename?: 'Event';
	id?: Maybe<Scalars['Long']>;
	startTime?: Maybe<Scalars['Int']>;
	endTime?: Maybe<Scalars['Int']>;
	title?: Maybe<Scalars['String']>;
	originalTitle?: Maybe<Scalars['String']>;
	description?: Maybe<Scalars['String']>;
	/** @deprecated Use "description" instead */
	longText?: Maybe<Scalars['String']>;
	minimumAge?: Maybe<Scalars['Int']>;
	episodeNumber?: Maybe<Scalars['Int']>;
	seasonNumber?: Maybe<Scalars['Int']>;
	totalEpisodes?: Maybe<Scalars['Int']>;
	rerun?: Maybe<Scalars['Boolean']>;
	recording?: Maybe<Recording>;
	channel?: Maybe<Channel>;
	image?: Maybe<Image>;
};

export type EventImageArgs = {
	type: ImageType;
};

export type Recording = {
	__typename?: 'Recording';
	id?: Maybe<Scalars['Long']>;
	url?: Maybe<Scalars['String']>;
};

export type RecordingUrlArgs = {
	streamFormat: StreamFormat;
};

export enum ImageType {
	Poster = 'POSTER',
	Fanart = 'FANART',
	Backdrop = 'BACKDROP',
	Still = 'STILL',
	Hopster = 'HOPSTER',
	HopsterFocus = 'HOPSTER_FOCUS',
	ProductModule = 'PRODUCT_MODULE',
	ProductBackdrop = 'PRODUCT_BACKDROP',
	ProductIcon = 'PRODUCT_ICON',
	ChannelIcon = 'CHANNEL_ICON',
	Credit = 'CREDIT',
	Ad = 'AD',
	Promo = 'PROMO',
}

export type Image = {
	__typename?: 'Image';
	id?: Maybe<Scalars['Int']>;
	hash?: Maybe<Scalars['String']>;
};

export type Category = {
	__typename?: 'Category';
	id?: Maybe<Scalars['Int']>;
	parentId?: Maybe<Scalars['Int']>;
	adRegionId?: Maybe<Scalars['Int']>;
	title?: Maybe<Scalars['String']>;
	subtitle?: Maybe<Scalars['String']>;
	description?: Maybe<Scalars['String']>;
	type?: Maybe<CategoryType>;
	color?: Maybe<Scalars['String']>;
	viewTemplate?: Maybe<Scalars['String']>;
	moduleType?: Maybe<ModuleType>;
	moduleTemplate?: Maybe<ModuleTemplate>;
	/** Subcategories in category. */
	categories?: Maybe<Array<Maybe<Category>>>;
	/** Content in category. */
	content?: Maybe<Array<Maybe<Content>>>;
	/**
	 * Movies in category.
	 * @deprecated Use "content" instead.
	 */
	movies?: Maybe<Array<Maybe<Movie>>>;
	/**
	 * Series in category.
	 * @deprecated Use "content" instead.
	 */
	series?: Maybe<Array<Maybe<Series>>>;
	root?: Maybe<Category>;
	parent?: Maybe<Category>;
	image?: Maybe<Image>;
	product?: Maybe<Product>;
	/** List ads for category. */
	ads?: Maybe<Array<Maybe<Ad>>>;
};

export type CategoryCategoriesArgs = {
	limit?: Maybe<Scalars['Int']>;
	skip?: Maybe<Scalars['Int']>;
};

export type CategoryContentArgs = {
	limit?: Maybe<Scalars['Int']>;
	skip?: Maybe<Scalars['Int']>;
};

export type CategoryMoviesArgs = {
	limit?: Maybe<Scalars['Int']>;
	skip?: Maybe<Scalars['Int']>;
};

export type CategorySeriesArgs = {
	limit?: Maybe<Scalars['Int']>;
	skip?: Maybe<Scalars['Int']>;
};

export type CategoryImageArgs = {
	type: ImageType;
};

export type CategoryAdsArgs = {
	limit?: Maybe<Scalars['Int']>;
	skip?: Maybe<Scalars['Int']>;
};

/** Type of category */
export enum CategoryType {
	Movies = 'MOVIES',
	Tv = 'TV',
	Catchup = 'CATCHUP',
}

/** Type of module to render */
export enum ModuleType {
	Poster = 'POSTER',
	PosterLarge = 'POSTER_LARGE',
	M2 = 'M2',
	M3 = 'M3',
	M4 = 'M4',
	Hopster = 'HOPSTER',
}

/** The module template to render */
export enum ModuleTemplate {
	OverlayIcon = 'OVERLAY_ICON',
	Overlay = 'OVERLAY',
	Promo = 'PROMO',
}

export type Content = {
	id?: Maybe<Scalars['Int']>;
	externalId?: Maybe<Scalars['String']>;
	title?: Maybe<Scalars['String']>;
	originalTitle?: Maybe<Scalars['String']>;
	shortDescription?: Maybe<Scalars['String']>;
	description?: Maybe<Scalars['String']>;
	directors?: Maybe<Array<Maybe<Director>>>;
	actors?: Maybe<Array<Maybe<Actor>>>;
	ageRating?: Maybe<Scalars['Int']>;
	imdbId?: Maybe<Scalars['String']>;
	imdbRating?: Maybe<Scalars['Float']>;
	files?: Maybe<Array<Maybe<File>>>;
	subscribed?: Maybe<Scalars['Boolean']>;
	image?: Maybe<Image>;
	product?: Maybe<Product>;
	tags?: Maybe<Array<Maybe<Tag>>>;
	similar?: Maybe<Array<Maybe<Content>>>;
	favorite?: Maybe<Scalars['Boolean']>;
};

export type ContentDirectorsArgs = {
	limit?: Maybe<Scalars['Int']>;
	skip?: Maybe<Scalars['Int']>;
};

export type ContentActorsArgs = {
	limit?: Maybe<Scalars['Int']>;
	skip?: Maybe<Scalars['Int']>;
};

export type ContentImageArgs = {
	type: ImageType;
};

export type ContentTagsArgs = {
	type?: Maybe<TagType>;
};

export type ContentSimilarArgs = {
	limit?: Maybe<Scalars['Int']>;
	skip?: Maybe<Scalars['Int']>;
};

export type Director = {
	__typename?: 'Director';
	id?: Maybe<Scalars['Int']>;
	name?: Maybe<Scalars['String']>;
	image?: Maybe<Image>;
};

export type DirectorImageArgs = {
	type: ImageType;
};

export type Actor = {
	__typename?: 'Actor';
	id?: Maybe<Scalars['Int']>;
	name?: Maybe<Scalars['String']>;
	character?: Maybe<Scalars['String']>;
	image?: Maybe<Image>;
};

export type ActorImageArgs = {
	type: ImageType;
};

export type File = {
	__typename?: 'File';
	id?: Maybe<Scalars['Int']>;
	type?: Maybe<FileType>;
	duration?: Maybe<Scalars['Int']>;
	filename?: Maybe<Scalars['String']>;
	url?: Maybe<Scalars['String']>;
};

export type FileUrlArgs = {
	streamFormat: StreamFormat;
};

export enum FileType {
	Sd = 'SD',
	Hd = 'HD',
	Tr = 'TR',
	Dash = 'DASH',
	Dashtr = 'DASHTR',
}

export type Product = {
	__typename?: 'Product';
	id?: Maybe<Scalars['Int']>;
	subscriptionProduct?: Maybe<Product>;
	title?: Maybe<Scalars['String']>;
	subtitle?: Maybe<Scalars['String']>;
	description?: Maybe<Scalars['String']>;
	terms?: Maybe<Scalars['String']>;
	leadText?: Maybe<Scalars['String']>;
	successText?: Maybe<Scalars['String']>;
	price?: Maybe<Scalars['Int']>;
	type?: Maybe<ProductType>;
	color?: Maybe<Scalars['String']>;
	startTime?: Maybe<Scalars['Long']>;
	endTime?: Maybe<Scalars['Long']>;
	subscribed?: Maybe<Scalars['Boolean']>;
	image?: Maybe<Image>;
};

export type ProductImageArgs = {
	type: ImageType;
};

export enum ProductType {
	Ppv = 'PPV',
	Subscription = 'SUBSCRIPTION',
	Est = 'EST',
}

export enum TagType {
	Generic = 'GENERIC',
	Genre = 'GENRE',
	Keyword = 'KEYWORD',
	Ad = 'AD',
}

export type Tag = {
	__typename?: 'Tag';
	id?: Maybe<Scalars['Int']>;
	externalId?: Maybe<Scalars['String']>;
	title?: Maybe<Scalars['String']>;
	type?: Maybe<TagType>;
};

export type Movie = Content &
	Playable & {
		__typename?: 'Movie';
		id?: Maybe<Scalars['Int']>;
		externalId?: Maybe<Scalars['String']>;
		title?: Maybe<Scalars['String']>;
		originalTitle?: Maybe<Scalars['String']>;
		shortDescription?: Maybe<Scalars['String']>;
		description?: Maybe<Scalars['String']>;
		directors?: Maybe<Array<Maybe<Director>>>;
		actors?: Maybe<Array<Maybe<Actor>>>;
		imdbId?: Maybe<Scalars['String']>;
		imdbRating?: Maybe<Scalars['Float']>;
		files?: Maybe<Array<Maybe<File>>>;
		subscribed?: Maybe<Scalars['Boolean']>;
		image?: Maybe<Image>;
		product?: Maybe<Product>;
		ageRating?: Maybe<Scalars['Int']>;
		similar?: Maybe<Array<Maybe<Content>>>;
		favorite?: Maybe<Scalars['Boolean']>;
		duration?: Maybe<Scalars['Int']>;
		maxViewingDuration?: Maybe<Scalars['Int']>;
		position?: Maybe<Position>;
		timing?: Maybe<Timing>;
		licenses?: Maybe<Array<Maybe<License>>>;
		year?: Maybe<Scalars['Int']>;
		categories?: Maybe<Array<Maybe<Category>>>;
		genres?: Maybe<Array<Maybe<Genre>>>;
		tags?: Maybe<Array<Maybe<Tag>>>;
		vast?: Maybe<Vast>;
		trailers?: Maybe<Array<Maybe<Scalars['String']>>>;
		/** @deprecated Use "content" instead to get episode info. */
		parentId?: Maybe<Scalars['Int']>;
		/** @deprecated Use "content" instead to get series info. */
		parent?: Maybe<Movie>;
		type?: Maybe<MovieType>;
		/** @deprecated Use "content" instead to get episode info. */
		episodeSeason?: Maybe<Scalars['Int']>;
		/** @deprecated Use "content" instead to get episode info. */
		episodeNumber?: Maybe<Scalars['Int']>;
		/** @deprecated Use "licenses" instead. */
		licenseStart?: Maybe<Scalars['Long']>;
		/** @deprecated Use "licenses" instead. */
		licenseEnd?: Maybe<Scalars['Long']>;
		/** @deprecated Use "licenses" instead. */
		price?: Maybe<Scalars['Int']>;
		/** @deprecated Use "licenses" instead. */
		provider?: Maybe<Provider>;
		/** @deprecated Use "licenses" instead. */
		rental?: Maybe<Rental>;
		/** @deprecated Use "files" instead. */
		stream?: Maybe<Scalars['String']>;
	};

export type MovieDirectorsArgs = {
	limit?: Maybe<Scalars['Int']>;
	skip?: Maybe<Scalars['Int']>;
};

export type MovieActorsArgs = {
	limit?: Maybe<Scalars['Int']>;
	skip?: Maybe<Scalars['Int']>;
};

export type MovieImageArgs = {
	type: ImageType;
};

export type MovieSimilarArgs = {
	limit?: Maybe<Scalars['Int']>;
	skip?: Maybe<Scalars['Int']>;
};

export type MovieTimingArgs = {
	type: TimingType;
};

export type MovieTagsArgs = {
	type?: Maybe<TagType>;
};

export type MovieStreamArgs = {
	streamType: MovieStream;
};

export type Playable = {
	duration?: Maybe<Scalars['Int']>;
	maxViewingDuration?: Maybe<Scalars['Int']>;
	position?: Maybe<Position>;
	timing?: Maybe<Timing>;
	licenses?: Maybe<Array<Maybe<License>>>;
};

export type PlayableTimingArgs = {
	type: TimingType;
};

export type Position = {
	__typename?: 'Position';
	id?: Maybe<Scalars['Int']>;
	value?: Maybe<Scalars['Int']>;
	timestamp?: Maybe<Scalars['Int']>;
};

export enum TimingType {
	Intro = 'INTRO',
	Credits = 'CREDITS',
	Recap = 'RECAP',
}

export type Timing = {
	__typename?: 'Timing';
	id?: Maybe<Scalars['Int']>;
	start?: Maybe<Scalars['Int']>;
	end?: Maybe<Scalars['Int']>;
	type?: Maybe<TimingType>;
};

export type License = {
	__typename?: 'License';
	id?: Maybe<Scalars['Int']>;
	title?: Maybe<Scalars['String']>;
	type?: Maybe<LicenseType>;
	validFrom?: Maybe<Scalars['Long']>;
	validTo?: Maybe<Scalars['Long']>;
	subscribed?: Maybe<Scalars['Boolean']>;
	provider?: Maybe<Provider>;
	vast?: Maybe<Vast>;
	files?: Maybe<Array<Maybe<File>>>;
	rental?: Maybe<Rental>;
	price?: Maybe<Price>;
	product?: Maybe<Product>;
	purchase?: Maybe<Purchase>;
};

export enum LicenseType {
	Tvod = 'TVOD',
	Svod = 'SVOD',
	Catchup = 'CATCHUP',
	Est = 'EST',
}

export type Provider = {
	__typename?: 'Provider';
	id?: Maybe<Scalars['Int']>;
	title?: Maybe<Scalars['String']>;
	type?: Maybe<ProviderType>;
	allowsExternalPlayback?: Maybe<Scalars['Boolean']>;
};

/** Type of provider */
export enum ProviderType {
	/** TVOD */
	Tvod = 'TVOD',
	/** SVOD */
	Svod = 'SVOD',
	/** CATCHUP */
	Catchup = 'CATCHUP',
}

export type Vast = {
	__typename?: 'Vast';
	regionId?: Maybe<Scalars['String']>;
};

export type Rental = {
	__typename?: 'Rental';
	id?: Maybe<Scalars['Int']>;
	movieId?: Maybe<Scalars['Int']>;
	validFrom?: Maybe<Scalars['Int']>;
	validUntil?: Maybe<Scalars['Int']>;
	ready?: Maybe<Scalars['Boolean']>;
	movie?: Maybe<Movie>;
	content?: Maybe<Content>;
	license?: Maybe<License>;
};

export type Price = {
	__typename?: 'Price';
	value?: Maybe<Scalars['Float']>;
};

export type Purchase = {
	__typename?: 'Purchase';
	id?: Maybe<Scalars['Int']>;
	purchasedAt?: Maybe<Scalars['Long']>;
	product?: Maybe<Product>;
	license?: Maybe<License>;
	movie?: Maybe<Movie>;
};

export type Genre = {
	__typename?: 'Genre';
	id?: Maybe<Scalars['Int']>;
	title?: Maybe<Scalars['String']>;
};

/** Type of movie */
export enum MovieType {
	/** Movie */
	Movie = 'MOVIE',
	/** TV. */
	Tv = 'TV',
}

/** Type of stream to return. */
export enum MovieStream {
	/** RTSP SD stream. */
	Rtspsd = 'RTSPSD',
	/** RTSP HD stream. */
	Rtsphd = 'RTSPHD',
	/** HLS stream. */
	Hls = 'HLS',
	/** DASH stream. */
	Dash = 'DASH',
	/** RTSP trailer stream. */
	Rtsptr = 'RTSPTR',
	/** HLS trailer stream. */
	Hlstr = 'HLSTR',
	/** DASH tailer stream. */
	Dashtr = 'DASHTR',
}

export type Series = Content & {
	__typename?: 'Series';
	id?: Maybe<Scalars['Int']>;
	externalId?: Maybe<Scalars['String']>;
	title?: Maybe<Scalars['String']>;
	originalTitle?: Maybe<Scalars['String']>;
	shortDescription?: Maybe<Scalars['String']>;
	description?: Maybe<Scalars['String']>;
	ageRating?: Maybe<Scalars['Int']>;
	subscribed?: Maybe<Scalars['Boolean']>;
	product?: Maybe<Product>;
	genres?: Maybe<Array<Maybe<Genre>>>;
	tags?: Maybe<Array<Maybe<Tag>>>;
	files?: Maybe<Array<Maybe<File>>>;
	directors?: Maybe<Array<Maybe<Director>>>;
	actors?: Maybe<Array<Maybe<Actor>>>;
	imdbId?: Maybe<Scalars['String']>;
	imdbRating?: Maybe<Scalars['Float']>;
	image?: Maybe<Image>;
	similar?: Maybe<Array<Maybe<Content>>>;
	favorite?: Maybe<Scalars['Boolean']>;
	vast?: Maybe<Vast>;
	seasons?: Maybe<Array<Maybe<Season>>>;
	episodeCount?: Maybe<Scalars['Int']>;
	/** Episodes in series. */
	episodes?: Maybe<Array<Maybe<Episode>>>;
	/** @deprecated Use "nextEpisode" instead. */
	lastWatched?: Maybe<Episode>;
	nextEpisode?: Maybe<Episode>;
	/** @deprecated Use "licenses" instead. */
	provider?: Maybe<Provider>;
};

export type SeriesTagsArgs = {
	type?: Maybe<TagType>;
};

export type SeriesDirectorsArgs = {
	limit?: Maybe<Scalars['Int']>;
	skip?: Maybe<Scalars['Int']>;
};

export type SeriesActorsArgs = {
	limit?: Maybe<Scalars['Int']>;
	skip?: Maybe<Scalars['Int']>;
};

export type SeriesImageArgs = {
	type: ImageType;
};

export type SeriesSimilarArgs = {
	limit?: Maybe<Scalars['Int']>;
	skip?: Maybe<Scalars['Int']>;
};

export type SeriesEpisodesArgs = {
	limit?: Maybe<Scalars['Int']>;
	skip?: Maybe<Scalars['Int']>;
	season?: Maybe<Scalars['Int']>;
};

export type Season = {
	__typename?: 'Season';
	number?: Maybe<Scalars['Int']>;
	episodeCount?: Maybe<Scalars['Int']>;
	year?: Maybe<Scalars['Int']>;
};

export type Episode = Content &
	Playable & {
		__typename?: 'Episode';
		id?: Maybe<Scalars['Int']>;
		externalId?: Maybe<Scalars['String']>;
		title?: Maybe<Scalars['String']>;
		originalTitle?: Maybe<Scalars['String']>;
		shortDescription?: Maybe<Scalars['String']>;
		description?: Maybe<Scalars['String']>;
		directors?: Maybe<Array<Maybe<Director>>>;
		actors?: Maybe<Array<Maybe<Actor>>>;
		tags?: Maybe<Array<Maybe<Tag>>>;
		imdbId?: Maybe<Scalars['String']>;
		imdbRating?: Maybe<Scalars['Float']>;
		subscribed?: Maybe<Scalars['Boolean']>;
		image?: Maybe<Image>;
		files?: Maybe<Array<Maybe<File>>>;
		product?: Maybe<Product>;
		ageRating?: Maybe<Scalars['Int']>;
		similar?: Maybe<Array<Maybe<Content>>>;
		favorite?: Maybe<Scalars['Boolean']>;
		duration?: Maybe<Scalars['Int']>;
		maxViewingDuration?: Maybe<Scalars['Int']>;
		position?: Maybe<Position>;
		timing?: Maybe<Timing>;
		licenses?: Maybe<Array<Maybe<License>>>;
		series?: Maybe<Series>;
		/** @deprecated Use "series" instead. */
		parent?: Maybe<Series>;
		type?: Maybe<MovieType>;
		episodeSeason?: Maybe<Scalars['Int']>;
		episodeNumber?: Maybe<Scalars['Int']>;
		/** @deprecated Use "series.seasons.year" instead */
		year?: Maybe<Scalars['Int']>;
		/** @deprecated Use "licenses" instead. */
		licenseStart?: Maybe<Scalars['Long']>;
		/** @deprecated Use "licenses" instead. */
		licenseEnd?: Maybe<Scalars['Long']>;
		/** @deprecated Use "licenses" instead. */
		price?: Maybe<Scalars['Int']>;
		/** @deprecated Use "licenses" instead. */
		provider?: Maybe<Provider>;
	};

export type EpisodeDirectorsArgs = {
	limit?: Maybe<Scalars['Int']>;
	skip?: Maybe<Scalars['Int']>;
};

export type EpisodeActorsArgs = {
	limit?: Maybe<Scalars['Int']>;
	skip?: Maybe<Scalars['Int']>;
};

export type EpisodeTagsArgs = {
	type?: Maybe<TagType>;
};

export type EpisodeImageArgs = {
	type: ImageType;
};

export type EpisodeSimilarArgs = {
	limit?: Maybe<Scalars['Int']>;
	skip?: Maybe<Scalars['Int']>;
};

export type EpisodeTimingArgs = {
	type: TimingType;
};

export type Ad = {
	__typename?: 'Ad';
	id?: Maybe<Scalars['Int']>;
	regionId?: Maybe<Scalars['Int']>;
	movieId?: Maybe<Scalars['Int']>;
	seriesId?: Maybe<Scalars['Int']>;
	categoryId?: Maybe<Scalars['Int']>;
	collectionId?: Maybe<Scalars['Int']>;
	categoryIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
	productId?: Maybe<Scalars['Int']>;
	title?: Maybe<Scalars['String']>;
	subtitle?: Maybe<Scalars['String']>;
	type?: Maybe<Scalars['String']>;
	url?: Maybe<Scalars['String']>;
	image?: Maybe<Image>;
	/** @deprecated Use "content" instead to get movie info. */
	movie?: Maybe<Movie>;
	/** @deprecated Use "content" instead to get movie info. */
	series?: Maybe<Series>;
	content?: Maybe<Content>;
	category?: Maybe<Category>;
	collection?: Maybe<Collection>;
	product?: Maybe<Product>;
};

export type AdImageArgs = {
	type: ImageType;
};

export type Collection = {
	__typename?: 'Collection';
	id?: Maybe<Scalars['Int']>;
	title?: Maybe<Scalars['String']>;
	color?: Maybe<Scalars['String']>;
	moduleType?: Maybe<ModuleType>;
	moduleTemplate?: Maybe<ModuleTemplate>;
	/** Content in collection. */
	content?: Maybe<Array<Maybe<Content>>>;
	/**
	 * Items in collection.
	 * @deprecated Use "content" instead.
	 */
	items?: Maybe<Array<Maybe<CollectionItem>>>;
};

export type CollectionContentArgs = {
	limit?: Maybe<Scalars['Int']>;
	skip?: Maybe<Scalars['Int']>;
};

export type CollectionItemsArgs = {
	limit?: Maybe<Scalars['Int']>;
	skip?: Maybe<Scalars['Int']>;
};

export type CollectionItem = {
	__typename?: 'CollectionItem';
	id?: Maybe<Scalars['Int']>;
	title?: Maybe<Scalars['String']>;
	movie?: Maybe<Movie>;
	series?: Maybe<Series>;
	episode?: Maybe<Movie>;
};

export type SearchResults = {
	__typename?: 'SearchResults';
	id?: Maybe<Scalars['Int']>;
	/** @deprecated Use "licenses" instead to get provider info. */
	provider?: Maybe<Provider>;
	content?: Maybe<Content>;
	/** @deprecated Use "content" instead to get movie info. */
	movie?: Maybe<Movie>;
	seasons?: Maybe<Array<Maybe<Scalars['Int']>>>;
	seasonCount?: Maybe<Scalars['Int']>;
	episodeCount?: Maybe<Scalars['Int']>;
};

export type User = {
	__typename?: 'User';
	id?: Maybe<Scalars['String']>;
	name?: Maybe<Scalars['String']>;
	settings?: Maybe<UserSettings>;
};

export type UserSettings = {
	__typename?: 'UserSettings';
	/** Language of UI. */
	language?: Maybe<UserSettingsLanguage>;
	/** Maximum amount that can be purchased in each month. */
	purchaseLimit?: Maybe<Scalars['Int']>;
	/** List of recently searched items. */
	recentlySearched?: Maybe<Array<Maybe<Scalars['String']>>>;
	/** Configures ageLevel for maturity locking content playback. */
	ageLevel?: Maybe<UserSettingsAgeLevel>;
	/** Configures if and how PIN is required for VOD content. */
	pinPlayContent?: Maybe<UserSettingsPinPlayContent>;
	/** Configures if PIN is required for channels. */
	pinOnChannels?: Maybe<UserSettingsPinChannels>;
	/** Configures if PIN is required for timeshift. */
	pinOnTimeshift?: Maybe<UserSettingsPinTimeshift>;
	/** Configures if allowed to purchase subscriptions. */
	allowSubscriptions?: Maybe<UserSettingsAllowSubscriptions>;
	/** Configures if allowed to purchase PPV events. */
	allowPPV?: Maybe<UserSettingsAllowPpv>;
};

export enum UserSettingsLanguage {
	/** Icelandic */
	Is = 'IS',
	/** English */
	En = 'EN',
}

export enum UserSettingsAgeLevel {
	/** Content is rated for all viewers. */
	AgeLevel_0 = 'AGE_LEVEL_0',
	/** Content is rated for viewers 6 years or older. */
	AgeLevel_6 = 'AGE_LEVEL_6',
	/** Content is rated for viewers 9 years or older. */
	AgeLevel_9 = 'AGE_LEVEL_9',
	/** Content is rated for viewers 12 years or older. */
	AgeLevel_12 = 'AGE_LEVEL_12',
	/** Content is rated for viewers 16 years or older. */
	AgeLevel_16 = 'AGE_LEVEL_16',
	/** Content is rated for viewers 18 years or older. */
	AgeLevel_18 = 'AGE_LEVEL_18',
}

export enum UserSettingsPinPlayContent {
	/** Always require PIN for playing VOD content. */
	Always = 'ALWAYS',
	/** Only require PIN for non-free VOD content. */
	NotFree = 'NOT_FREE',
	/** Require PIN if content is rated same or higher than configured ageLevel. */
	AgeLevel = 'AGE_LEVEL',
}

export enum UserSettingsPinChannels {
	/** Require PIN for channels when event is rated same or higher than configured ageLevel. */
	AgeLevel = 'AGE_LEVEL',
	/** Never require PIN for channels. */
	No = 'NO',
}

export enum UserSettingsPinTimeshift {
	/** Require PIN for timeshift event that are rated same or higher than configured ageLevel. */
	AgeLevel = 'AGE_LEVEL',
	/** Never require PIN for timeshift events. */
	No = 'NO',
}

export enum UserSettingsAllowSubscriptions {
	/** Allow subscription purchases with PIN. */
	YesWithPin = 'YES_WITH_PIN',
	/** Allow subscription purchases. */
	Yes = 'YES',
	/** Disallow subscription purchases. */
	No = 'NO',
}

export enum UserSettingsAllowPpv {
	/** Allow PPV event purchases with PIN. */
	YesWithPin = 'YES_WITH_PIN',
	/** Allow PPV event purchases. */
	Yes = 'YES',
	/** Disallow PPV event purchases. */
	No = 'NO',
}

export type Mutation = {
	__typename?: 'Mutation';
	updateUser?: Maybe<User>;
};

export type MutationUpdateUserArgs = {
	userSettings: UserSettingsInput;
};

export type UserSettingsInput = {
	language?: Maybe<UserSettingsLanguage>;
	recentlySearched?: Maybe<Array<Maybe<Scalars['String']>>>;
	ageLevel?: Maybe<UserSettingsAgeLevel>;
	pinPlayContent?: Maybe<UserSettingsPinPlayContent>;
	pinOnChannels?: Maybe<UserSettingsPinChannels>;
	pinOnTimeshift?: Maybe<UserSettingsPinTimeshift>;
	allowSubscriptions?: Maybe<UserSettingsAllowSubscriptions>;
	allowPPV?: Maybe<UserSettingsAllowPpv>;
};
