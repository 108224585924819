import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { PlaybackEvents } from '../hooks/usePlaybackEvents';
import { PlaybackControls } from './PlaybackControls';
import { usePlayer } from '../contexts/PlayerContext';
import {
	AnalyticsEvent,
	Episode,
	generateBasicContentInfo,
	Movie,
	Playable,
	AnalyticsSeverity,
} from '@vodafoneis/sjonvarpskjarni-js-lib';
import { useNavigate } from 'react-router-dom';
import { PlaybackState, usePlayback } from '../contexts/PlaybackContext';
import { useSavePosition } from '../hooks/useSavePosition';
import { useOnChangeCallback } from '../hooks/useOnChangeCallback';
import { useOnBack } from '../hooks/useOnBack';
import { track } from '../utils/analytics';
import { PlaybackError } from '../components/PlaybackError';
import { useEntitlement } from '../contexts/EntitlementContext';
import { useContentUrl } from '../hooks/useContentUrl';

const PlayerWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
`;

const PlayerView = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
`;

type PlayerProps = {
	content: Playable;
	initialPosition?: number;
} & PlaybackEvents;

const { Initialized, Stopped, Error } = PlaybackState;
const { VOD_START, VOD_STOP, VOD_ERROR } = AnalyticsEvent;
const { CRITICAL, MINOR } = AnalyticsSeverity;

export const Player: React.FC<PlayerProps> = (props) => {
	const { initialPosition, content } = props;

	const { setSources, seek, stop, setPlayerView } = usePlayer();
	const { state, position, error } = usePlayback();
	const url = useContentUrl(content);
	const { token } = useEntitlement();

	const readyForPlay = useMemo(() => !!(url && token), [token, url]);

	const navigate = useNavigate();

	const license = useMemo(() => content.getFirstLicense(), [content]);

	const navigateBack = useCallback(() => {
		if (content instanceof Episode && content.series?.id) {
			navigate(`/content/${content.series.id}`, { replace: true });
		} else if (content instanceof Movie) {
			navigate(`/content/${content.id}`, { replace: true });
		}
	}, [content, navigate]);

	const handleBack = useCallback(() => {
		stop();

		return true;
	}, [stop]);

	useOnBack(handleBack);

	useSavePosition(content);

	const onStateChanged = useCallback(() => {
		if (state === Initialized) {
			if (initialPosition && initialPosition > 0) {
				seek(initialPosition);
			}

			if (license) {
				track(VOD_START, {
					playbackSessionId: null,
					startPosition: initialPosition || 0,
					...generateBasicContentInfo(content, license),
				});
			}
		} else if (state === Error && error) {
			const errorSeverity = [
				'AccountBlockingException',
				'GeoBlockingException',
				'EntitlementExpiredException',
				'EntitlementLimitReachedException',
				'AuthorizationException',
			].includes(error.name)
				? MINOR
				: CRITICAL;

			if (license) {
				track(VOD_ERROR, {
					playbackSessionId: null,
					errorName: error.name,
					errorMessage: error.message,
					errorSeverity,
					errorPosition: position,
					...generateBasicContentInfo(content, license),
				});
			}
		} else if (state === Stopped) {
			if (license) {
				track(VOD_STOP, {
					playbackSessionId: null,
					stopPosition: position,
					...generateBasicContentInfo(content, license),
				});
			}
			navigateBack();
		}
	}, [state, error, initialPosition, license, seek, content, position, navigateBack]);

	useOnChangeCallback(onStateChanged, state);

	const startPlayback = useCallback(() => {
		if (readyForPlay) {
			setSources([
				{
					src: url as string,
					type: 'application/dash+xml',
					contentProtection: {
						widevine: {
							licenseAcquisitionURL: 'https://widevine.tv.c.is/license',
							headers: {
								// @ts-ignore
								PreAuthorization: token,
							},
						},
					},
				},
			]);
		}
	}, [readyForPlay, setSources, token, url]);

	useOnChangeCallback(startPlayback, readyForPlay);

	const onPlayerViewReady = useCallback(
		(playerView: HTMLDivElement) => {
			setPlayerView(playerView);
		},
		[setPlayerView]
	);

	return (
		<PlayerWrapper>
			<PlayerView ref={onPlayerViewReady} role={'presentation'} className={'video-js'} />
			{error ? <PlaybackError content={content} /> : <PlaybackControls />}
		</PlayerWrapper>
	);
};
