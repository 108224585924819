export enum IMAGE_TYPES {
	POSTER = 'poster',
	BACKDROP = 'fanart',
	STILL = 'still',
	BANNER = 'banner',
	HOPSTER = 'hopster',
}

export enum IMAGE_PRESET {
	BACKDROP = '720p',
	BACKDROP_MEDIUM = 'backdrop-medium',
	BACKDROP_LARGE = 'backdrop-large',
	BACKDROP_OVERLAY = 'w-backdrop-overlay',
	STILL_SMALL = 'still-small',
	STILL_MEDIUM = 'still-medium',
	STILL_LARGE = 'still-large',
	STILL_OVERLAY = 'w-still-overlay',
	POSTER_TINY = 'poster-tiny',
	POSTER_SMALL = 'poster-small',
	POSTER_MEDIUM = 'poster-medium',
	CATEGORY_BANNER_LARGE = 'category-banner-large',
}

export class ALIImageUtils {
	/**
	 * @deprecated use IMAGE_TYPES instead
	 */
	static IMAGE_TYPES = IMAGE_TYPES;

	/**
	 * @deprecated use IMAGE_PRESET instead
	 */
	static PRESETS = IMAGE_PRESET;

	static generateDPRPath(dpr?: number) {
		if (dpr && dpr > 1) {
			return `dpr=${dpr}`;
		}
		return null;
	}

	static generatePresetPath(presets: IMAGE_PRESET | IMAGE_PRESET[]) {
		if (presets && presets instanceof Array && presets.length > 0) {
			return `p=${presets.join(',')}`;
		}

		if (presets) {
			return `p=${presets}`;
		}
		return null;
	}

	static generatePostfix(presets: IMAGE_PRESET | IMAGE_PRESET[]) {
		const pathParts = [];
		const dpr = ALIImageUtils.generateDPRPath();
		const presetPath = ALIImageUtils.generatePresetPath(presets);
		if (presetPath) pathParts.push(presetPath);
		if (dpr) pathParts.push(dpr);
		if (pathParts.length > 0) {
			return `?${pathParts.join('&')}`;
		}
		return '';
	}

	static generateImagePath({ imageType, presets, imageBase, ext, basePath }: any) {
		const extension = ext || '.jpg';
		return `${(basePath || '') + (imageBase || '')}/${imageType}${extension}${ALIImageUtils.generatePostfix(
			presets
		)}`;
	}
}
