import React, { ComponentPropsWithoutRef, forwardRef, PropsWithChildren, SyntheticEvent, useCallback } from 'react';

type FormProps = PropsWithChildren<
	ComponentPropsWithoutRef<'form'> & {
		onSubmit?: (event: SyntheticEvent) => void;
	}
>;

export const Form = forwardRef<HTMLFormElement, FormProps>(({ children, onSubmit, className }, ref) => {
	const onSubmitCallback = useCallback(
		event => {
			event.preventDefault();
			if (onSubmit) onSubmit(event);
		},
		[onSubmit]
	);

	return (
		<form ref={ref} className={className} onSubmit={onSubmitCallback}>
			{children}
		</form>
	);
});
