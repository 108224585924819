import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { FetchPolicy } from '@apollo/client';
import { Content, Movie, Series, useContent } from '@vodafoneis/sjonvarpskjarni-js-lib';
import { ACTORS_LIMIT, SIMILAR_CONTENT_LIMIT } from '../config/constants';
import ContentInfo from '../components/ContentInfo/ContentInfo';
import { SeriesContext, SeriesContextProvider } from '../contexts/SeriesContext';
import styled from 'styled-components';
import { SeriesEpisodes } from '../components/SeriesEpisodes';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { page } from '../utils/analytics';
import { useOnBack } from '../hooks/useOnBack';
import { ContentBackgroundImage } from '../components/ContentBackgroundImage';
import { SeasonPicker } from '../components/SeasonPicker';

const queryOptions = {
	fetchPolicy: 'network-only' as FetchPolicy,
	nextFetchPolicy: 'cache-first' as FetchPolicy,
};

type ContentSubscreenProps = {
	content: Content;
};

const SeriesWrapper = styled.div`
	display: flex;
	flex-direction: column;
	transition: transform 100ms ease-in-out;
`;

const EpisodesWrapper = styled.div`
	flex-direction: column;
	border-top: 1px solid #777;
	margin-top: 20px;
	padding-top: 40px;
`;

const ContentScreenWrapper = styled.div`
	width: 100%;
	margin: 117px 175px 30px 175px;
`;

const ContentScreenItemList: React.FC<ContentSubscreenProps> = ({ content }) => {
	const seriesWrapperRef = useRef<HTMLDivElement>();
	const episodesWrapperRef = useRef<HTMLDivElement>();

	const { nextEpisode, episodes, seasonPickerVisible } = useContext(SeriesContext);

	const onBackPressed = useCallback(() => seasonPickerVisible, [seasonPickerVisible]);

	useOnBack(onBackPressed);

	const onContentInfoBlur = useCallback(() => {
		if (seriesWrapperRef.current && episodesWrapperRef.current) {
			const { top } = episodesWrapperRef.current?.getBoundingClientRect();
			seriesWrapperRef.current.style.transform = `translate3d(0, -${top + 10}px, 0)`;
		}
	}, []);

	const onContentInfoFocus = useCallback(() => {
		if (seriesWrapperRef.current) {
			seriesWrapperRef.current.style.transform = `translate3d(0, 0, 0)`;
		}
	}, []);

	if (content instanceof Series) {
		return (
			// @ts-ignore
			<SeriesWrapper ref={seriesWrapperRef}>
				<ContentInfo content={content} playable={nextEpisode} onBlur={onContentInfoBlur} onFocus={onContentInfoFocus} />
				{episodes && episodes.length > 0 ? (
					// @ts-ignore
					<EpisodesWrapper ref={episodesWrapperRef} className={'container'}>
						<SeasonPicker />
						<SeriesEpisodes />
					</EpisodesWrapper>
				) : null}
			</SeriesWrapper>
		);
	}

	return <ContentInfo content={content} playable={content as Movie} />;
};

export const ContentScreen = withFocusable({ trackChildren: true })(() => {
	const params = useParams();

	const { content } = useContent(params.contentId as string, SIMILAR_CONTENT_LIMIT, ACTORS_LIMIT, queryOptions);

	useEffect(() => {
		if (content) {
			page('Content', content.title, {
				contentId: content.id,
				contentType: content.__typename,
			});
		}
	}, [content]);

	const contentScreenContent = useMemo(() => {
		if (content instanceof Series) {
			return (
				<SeriesContextProvider series={content}>
					<ContentScreenItemList content={content} />
				</SeriesContextProvider>
			);
		}

		return <ContentScreenItemList content={content} />;
	}, [content]);

	return (
		<ContentBackgroundImage content={content}>
			<ContentScreenWrapper>{contentScreenContent}</ContentScreenWrapper>
		</ContentBackgroundImage>
	);
});
