import { createGlobalStyle } from 'styled-components';
import VodafoneRegular from './fonts/VodafoneRg.ttf';
import VodafoneRgBd from './fonts/VodafoneRgBd.ttf';
import VodafoneExB from './fonts/VodafoneExB.ttf';
import VodafoneLt from './fonts/VodafoneLt.ttf';

export const GlobalStyles = createGlobalStyle`
	@font-face {
		font-family: Vodafone;
		src: url(${VodafoneRegular}) format("truetype");
		font-weight: normal;
		font-style: normal;
	}

	@font-face {
		font-family: Vodafone;
		src: url(${VodafoneRgBd}) format("truetype");
		font-weight: bold;
		font-style: normal;
	}

	@font-face {
		font-family: Vodafone;
		src: url(${VodafoneExB}) format("truetype");
		font-weight: 900;
		font-style: normal;
	}

	@font-face {
		font-family: Vodafone;
		src: url(${VodafoneLt}) format("truetype");
		font-weight: 300;
		font-style: normal;
	}
	
	* {
		box-sizing: border-box;
	}
	
	html, body {
		height: 100%;
		background-color: #000;
	}
	
	body {
		font-family: Vodafone, 'Helvetica Neue', sans-serif;
		margin: 0;
		padding: 0;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		-ms-overflow-style: none;
		scroll-behavior: smooth;
		color: #fff;
		background-size: 100% 100%;
	}
	
	#root {
		overflow: hidden;
		position: relative;
		height: 1080px;
		max-height: 1080px;
		width: 1920px;
		max-width: 1920px;
		
		&.blur {
			-webkit-filter: saturate(180%) blur(10px);
			// noinspection CssUnknownProperty
			-moz-filter: saturate(180%) blur(10px);
			// noinspection CssUnknownProperty
			-o-filter: saturate(180%) blur(10px);
			-ms-filter: saturate(180%) blur(10px);
			filter: saturate(180%) blur(10px);
		}
    }
`;
