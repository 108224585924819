import styled from 'styled-components';
import { Content } from '@vodafoneis/sjonvarpskjarni-js-lib';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentInfoDataItem } from './ContentInfoDataItem';

export const ContentInfoSubtitleContainer = styled.div`
	overflow: hidden;
	margin-top: 20px;
	font-size: 26px;
	font-weight: bold;
	line-height: 1.2;
`;

type ContentInfoSubtitleProps = {
	content: Content;
};

export const ContentInfoSubtitle: React.FC<ContentInfoSubtitleProps> = ({ content }) => {
	const { t } = useTranslation();

	const genresComponent = useMemo(() => {
		if (!content) return null;

		const { genres } = content;
		if (genres && genres.length > 0) {
			const genrePart = genres
				.slice(0, 3)
				.map((genre) => genre.title)
				.join(' / ');
			return <ContentInfoDataItem title={genrePart} />;
		}

		return null;
	}, [content]);

	const imdbRatingComponent = useMemo(() => {
		if (!content) return null;

		const { imdbRating } = content;
		if (imdbRating && imdbRating > 0) {
			return <ContentInfoDataItem title={t('ContentInfo.IMDb')} value={String(imdbRating)} />;
		}

		return null;
	}, [content, t]);

	if (genresComponent || imdbRatingComponent) {
		return (
			<ContentInfoSubtitleContainer>
				{genresComponent}
				{imdbRatingComponent}
			</ContentInfoSubtitleContainer>
		);
	}

	return null;
};
