import { useMemo } from 'react';
import { Playable, LicenseType } from '../models';
import type { Content } from '../models';

const ONE_DAY_MS = 1000 * 86400;
const { CATCHUP } = LicenseType;

export const useAvailableUntilLicense = (content: Content, availableUntilDays: number) =>
	useMemo(() => {
		if (!(content instanceof Playable) || !availableUntilDays) return null;

		const availableUntilThreshold = availableUntilDays * ONE_DAY_MS;

		return content.getLicensesSorted().find(({ type, validTo }) => {
			// Always show available until for catchup
			if (type === CATCHUP) {
				return true;
			}

			// Show available until if less than availableUntilDays until content expires.
			return validTo.getTime() < new Date().getTime() + availableUntilThreshold;
		});
	}, [availableUntilDays, content]);
