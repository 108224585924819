import React, { useCallback, useContext } from 'react';
import { Episode, Series } from '@vodafoneis/sjonvarpskjarni-js-lib';
import { SeriesContext } from '../contexts/SeriesContext';
import { EpisodeComponent } from './EpisodeComponent';
import { Grid, RenderItem } from './Grid';
import styled from 'styled-components';

const EpisodesGrid = styled(Grid)`
	height: 860px;
`;

const EpisodesScrollContainer = styled.div`
	overflow: hidden;
	margin: 0 -20px 0 -20px;
`;

export const SeriesEpisodes: React.FC = () => {
	const { series, episodes, playEpisode } = useContext(SeriesContext);

	const onItemSelected = useCallback(
		({ item }) => {
			playEpisode?.(item);
		},
		[playEpisode]
	);

	const renderEpisode: RenderItem<Episode> = useCallback(
		({ item }) => <EpisodeComponent key={item.id} series={series as Series} episode={item} />,
		[series]
	);

	return (
		<EpisodesScrollContainer>
			<EpisodesGrid items={episodes || []} renderItem={renderEpisode} columns={3} onItemSelected={onItemSelected} />
		</EpisodesScrollContainer>
	);
};
