import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
	Content,
	CouldNotCreateEntitlementException,
	Entitlement,
	Exception,
	Playable,
} from '@vodafoneis/sjonvarpskjarni-js-lib';
import { PlaybackContext, PlaybackState } from './PlaybackContext';
import { UserContext } from './UserContext';
import { createEntitlement, deleteEntitlement, updateEntitlement } from '../api/entitlement';
import { useOnChangeCallback } from '../hooks/useOnChangeCallback';
import { ENTITLEMENT_UPDATE_INTERVAL } from '../config/constants';
import { useEffectOnce } from 'react-use';

type EntitlementContextProps = {
	entitlement: Entitlement | null;
	token: string | null;
	createEntitlement: (force: boolean) => void;
};

export const EntitlementContext = createContext<EntitlementContextProps>({
	entitlement: null,
	token: null,
	createEntitlement: () => {},
});

export const useEntitlement = () => useContext(EntitlementContext);

type EntitlementContextProviderProps = {
	content?: Content;
};

const { Stopped, Paused, Buffering, Initialized, Initializing, Error, Playing } = PlaybackState;

export const EntitlementContextProvider: React.FC<EntitlementContextProviderProps> = ({ content, children }) => {
	const entitlementCancelled = useRef(false);

	const { state, onPlaybackError } = useContext(PlaybackContext);

	const [entitlement, setEntitlement] = useState<Entitlement | null>(null);
	const [shouldUpdateEntitlement, setUpdateEntitlement] = useState(false);

	const { device } = useContext(UserContext);

	const generateEntitlement = useCallback(
		async (force = false) => {
			try {
				entitlementCancelled.current = false;

				if (!device) {
					// noinspection ExceptionCaughtLocallyJS
					throw new CouldNotCreateEntitlementException('Missing device while creating entitlement');
				}

				if (content instanceof Playable) {
					const license = content.getFirstLicense();

					const entitlement = await createEntitlement({
						deviceId: device.id,
						licenseId: license.id,
						movieId: content.id,
						force,
					});

					if (entitlementCancelled.current) {
						deleteEntitlement(entitlement.id as number);
					} else {
						setEntitlement(entitlement);
					}
				} else {
					// noinspection ExceptionCaughtLocallyJS
					throw new CouldNotCreateEntitlementException('Content is not playable');
				}
			} catch (exception: any) {
				if (exception instanceof Exception) {
					onPlaybackError(exception);
				} else {
					onPlaybackError(new CouldNotCreateEntitlementException(exception?.message));
				}
			}
		},
		[content, device, onPlaybackError]
	);

	useEffectOnce(() => {
		generateEntitlement();
	});

	const clearEntitlement = useCallback(() => {
		if (entitlement?.id) {
			deleteEntitlement(entitlement.id);
			setEntitlement(null);
			setUpdateEntitlement(false);
		}
	}, [entitlement]);

	const onPlayerStateChanged = useCallback(() => {
		switch (state) {
			case Initializing:
			case Initialized:
			case Buffering:
			case Playing:
				setUpdateEntitlement(true);
				break;
			case Paused:
				setUpdateEntitlement(false);
				break;
			case Stopped:
			case Error:
				clearEntitlement();
		}
	}, [clearEntitlement, state]);

	useOnChangeCallback(onPlayerStateChanged, state);

	useEffect(() => {
		if (shouldUpdateEntitlement && entitlement) {
			const interval = setInterval(async () => {
				try {
					// Entitlement is still valid, update it
					if (entitlement.expires && entitlement.expires * 1000 > new Date().getTime()) {
						const updatedEntitlement = await updateEntitlement(entitlement.id as number);
						setEntitlement(updatedEntitlement);
					} else {
						// Entitlement has expired, probably while paused, try to create a new one
						await generateEntitlement();
					}
				} catch (exception: any) {
					onPlaybackError(exception);
				}
			}, ENTITLEMENT_UPDATE_INTERVAL);

			return () => {
				clearInterval(interval);
			};
		}
	}, [entitlement, generateEntitlement, onPlaybackError, shouldUpdateEntitlement]);

	const value = useMemo(
		() => ({ createEntitlement: generateEntitlement, entitlement, token: entitlement?.token ?? null }),
		[entitlement, generateEntitlement]
	);

	return <EntitlementContext.Provider value={value}>{children}</EntitlementContext.Provider>;
};
