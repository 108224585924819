import type { Event } from './Event';
import type { Image } from './Image';
import type { Property } from './Property';
import { generateImagePath, ImagePathParams } from '../utils/ImageUtils';

export class Channel {
	id?: number;
	epgStationId?: number;
	operatorRef?: string;
	channelType?: string;
	title?: string;
	subscribed?: boolean;
	now?: Event;
	properties?: Property[];
	channelIconImage: Image;
	backdropImage: Image;
	dashStream: string;
	hlsStream: string;

	constructor(data: object) {
		Object.assign(this, data);
	}

	getIconImageUrl(params: ImagePathParams = {}) {
		if (this.channelIconImage) {
			return generateImagePath({
				hash: this.channelIconImage.hash,
				ext: 'png',
				...params,
			});
		}

		return null;
	}

	getBackdropImageUrl(params: ImagePathParams = {}) {
		if (this.backdropImage) {
			return generateImagePath({
				hash: this.backdropImage.hash,
				...params,
			});
		}

		return null;
	}

	getProperty(propertyName: string) {
		return this.properties?.find((property) => property.name === propertyName)?.value ?? null;
	}
}
