import { LicenseType } from './LicenseType';
import type { Rental } from './Rental';
import type { Product } from './Product';
import type { Purchase } from './Purchase';
import type { Provider } from './Provider';
import type { VAST } from './VAST';
import type { Price } from './Price';

const { TVOD, SVOD, CATCHUP, EST } = LicenseType;

export class License {
	id?: number;
	rental?: Rental;
	type?: LicenseType;
	price?: Price;
	subscribed?: boolean;
	provider?: Provider;
	product?: Product;
	purchase?: Purchase;
	vast?: VAST;
	validFrom?: Date;
	validTo?: Date;

	constructor(data: any) {
		Object.assign(this, data);

		const { validFrom, validTo } = data;

		if (validFrom) this.validFrom = new Date(validFrom * 1000);

		if (validTo) this.validTo = new Date(validTo * 1000);
	}

	static getLicensePriority() {
		return [SVOD, CATCHUP, TVOD, EST];
	}

	isTVOD() {
		return this.type === TVOD;
	}

	isSVOD() {
		return this.type === SVOD;
	}

	isCatchup() {
		return this.type === CATCHUP;
	}

	isEST() {
		return this.type === EST;
	}

	isType(types: LicenseType[]) {
		return types.includes(this.type);
	}

	isFree() {
		return this.price && this.price.value <= 0;
	}

	isSubscribed() {
		return this.subscribed === true;
	}

	isRented() {
		return !!this.rental;
	}

	isPurchased() {
		return !!this.purchase;
	}

	getProvider() {
		if (this.provider) {
			return this.provider;
		}

		return null;
	}

	getProduct() {
		if (this.product) {
			return this.product;
		}

		return null;
	}

	getPurchaseId() {
		if (this.purchase) {
			return this.purchase.id;
		}

		return null;
	}
}
