// @ts-nocheck

import THEOPlayerInitializationException from '../exceptions/THEOPlayerInitializationException';
import { ChromelessPlayer } from 'theoplayer';
import { THEO_LICENSE } from '../config/API';

export enum PLAYER_EVENTS {
	CAN_PLAY = 'canplay',
	CAN_PLAY_THROUGH = 'canplaythrough',
	CONTENT_PROTECTION_ERROR = 'contentprotectionerror',
	CONTENT_PROTECTION_SUCCESS = 'contentprotectionsuccess',
	DURATION_CHANGE = 'durationchange',
	ENCRYPTED = 'encrypted',
	ERROR = 'error',
	ENDED = 'ended',
	LOADED_DATA = 'error',
	LOADED_METADATA = 'loadedmetadata',
	PAUSE = 'pause',
	PLAY = 'play',
	PLAYING = 'playing',
	PRESENTATION_MODE_CHANGED = 'presentationmodechange',
	PROGRESS = 'progress',
	RATE_CHANGE = 'ratechange',
	READY_STATE_CHANGE = 'readystatechange',
	REPRESENTATION_CHANGE = 'representationchange',
	SEEKED = 'seeked',
	SEEKING = 'seeking',
	SEGMENTNOTFOUND = 'segmentnotfound',
	SOURCECHANGE = 'sourechange',
	TIME_UPDATE = 'timeupdate',
	VOLUME_CHANGE = 'volumechange',
	WAITING = 'waiting',
	DESTROY = 'destroy'
}

export const CHROMECAST_EVENTS = {
	STATE_CHANGE: 'statechange',
	ERROR: 'error',
};

const VOLUME_INTERVAL = 0.1;

export default class THEOPlayer {
	player: ChromelessPlayer = null;

	playerElement = null;

	constructor(playerElement) {
		if (!playerElement) {
			throw new THEOPlayerInitializationException();
		}

		this.playerElement = playerElement;

		this.player = new ChromelessPlayer(this.playerElement, {
			license: THEO_LICENSE,
			// mutedAutoplay: 'all',
		});

		// this.player.abr.strategy = 'performance';
		// this.player.abr.targetBuffer = 10;
		this.player.preload = 'auto';

		if (!this.player) {
			throw new THEOPlayerInitializationException();
		}
	}

	setAutoplay(shouldAutoplay) {
		this.player.autoplay = shouldAutoplay;
	}

	togglePausePlay = () => {
		if (this.player.paused) {
			this.play();
		} else {
			this.pause();
		}
	};

	play() {
		this.player.play();
	}

	pause() {
		this.player.pause();
	}

	isPaused() {
		return this.player.paused;
	}

	stop() {
		this.player.stop();
		this.player.destroy();
	}

	setVolume(volume) {
		this.setMuted(false);
		this.player.volume = volume;
	}

	getVolume() {
		return this.player.volume;
	}

	increaseVolume() {
		let newVolume = this.getVolume() + VOLUME_INTERVAL;
		if (this.isMuted()) {
			newVolume = VOLUME_INTERVAL;
		}
		this.setMuted(false);
		this.setVolume(newVolume);
	}

	decreaseVolume() {
		this.setMuted(false);
		this.setVolume(this.getVolume() - VOLUME_INTERVAL);
	}

	getVideoElement() {
		const videoElements = this.playerElement.getElementsByTagName('video');
		if (videoElements.length > 0) {
			return videoElements[0];
		}
		return null;
	}

	supportsFullscreen() {
		return (
			document.fullscreenEnabled ||
			document.webkitFullscreenEnabled ||
			document.mozFullScreenEnabled ||
			document.msFullscreenEnabled ||
			(this.getVideoElement() && this.getVideoElement().webkitSupportsFullscreen)
		);
	}

	isFullscreen() {
		return (
			document.fullscreen ||
			document.webkitIsFullScreen ||
			document.mozFullScreen ||
			document.msFullscreenElement ||
			(this.getVideoElement() && this.getVideoElement().webkitDisplayingFullscreen)
		);
	}

	toggleFullscreen() {
		if (this.isFullscreen()) {
			this.disableFullscreen();
		} else {
			this.enableFullscreen();
		}
	}

	enableFullscreen() {
		if (this.playerElement.requestFullscreen) {
			this.playerElement.requestFullscreen();
		} else if (this.playerElement.mozRequestFullScreen) {
			// Firefox
			this.playerElement.mozRequestFullScreen();
		} else if (this.playerElement.webkitRequestFullscreen) {
			// Chrome, Safari and Opera
			this.playerElement.webkitRequestFullscreen();
		} else if (this.playerElement.msRequestFullscreen) {
			// IE and Edge
			this.playerElement.msRequestFullscreen();
		} else if (this.getVideoElement().webkitEnterFullScreen) {
			// Mobile Safari
			this.getVideoElement().webkitEnterFullScreen();
		}
	}

	disableFullscreen() {
		if (document.exitFullscreen) {
			document.exitFullscreen();
		} else if (document.mozCancelFullScreen) {
			/* Chrome, Safari and Opera */
			document.mozCancelFullScreen();
		} else if (document.webkitExitFullscreen) {
			/* Firefox */
			document.webkitExitFullscreen();
		} else if (document.msExitFullscreen) {
			/* IE/Edge */
			document.msExitFullscreen();
		} else if (this.getVideoElement().webkitExitFullScreen) {
			// Mobile Safari
			this.getVideoElement().webkitExitFullScreen();
		}
	}

	supportsPIP() {
		const videoElement = this.getVideoElement();
		if (videoElement) {
			return (
				document.pictureInPictureEnabled ||
				(videoElement.webkitSupportsPresentationMode && typeof videoElement.webkitSetPresentationMode === 'function')
			);
		}
		return false;
	}

	isPIP() {
		const videoElement = this.getVideoElement();

		if (document.pictureInPictureElement) return true;

		if (videoElement) {
			return videoElement.webkitPresentationMode === 'picture-in-picture';
		}

		return false;
	}

	togglePIP() {
		if (!this.supportsPIP()) return;
		if (this.isPIP()) {
			this.disablePIP();
		} else {
			this.enablePIP();
		}
	}

	async enablePIP() {
		try {
			const videoElement = this.getVideoElement();
			if (!videoElement || !this.supportsPIP()) return;
			if (videoElement.requestPictureInPicture) {
				await videoElement.requestPictureInPicture();
			} else if (videoElement.webkitSetPresentationMode) {
				videoElement.webkitSetPresentationMode('picture-in-picture');
			}
		} catch (exception) {}
	}

	async disablePIP() {
		try {
			if (!this.supportsPIP()) return;
			const videoElement = this.getVideoElement();
			if (document.exitPictureInPicture) {
				await document.exitPictureInPicture();
			} else if (videoElement) {
				videoElement.webkitSetPresentationMode('inline');
			}
		} catch (exception) {}
	}

	isMuted() {
		return this.player.muted;
	}

	setMuted(muted) {
		this.player.muted = muted;
	}

	toggleMuted() {
		this.player.muted = !this.player.muted;
	}

	addEventListener(event, listener) {
		this.player.addEventListener(event, listener);
	}

	removeEventListener(event, listener) {
		try {
			this.player.removeEventListener(event, listener);
		} catch (exception) {}
	}

	isAdPlayback() {
		return !!this.player.ads.currentAdBreak;
	}

	skipAd() {
		if (this.player.ads) {
			return this.player.ads.skip();
		}

		return false;
	}

	setPosition(position) {
		this.player.currentTime = position;
	}

	seekToPosition(position) {
		try {
			this.setPosition(this.player.seekable.start(0) + position);
		} catch (error) {
			this.setPosition(position);
		}
	}

	seekToOffset(offset) {
		const duration = this.getDuration();
		const base = this.getPosition();

		const newPositionToSeekTo = Math.min(Math.max(0, base + offset), duration);

		this.seekToPosition(newPositionToSeekTo);
	}

	setSources(sources, ads = []) {
		this.player.source = {
			sources,
			ads,
		};
	}

	getPosition() {
		try {
			return Math.floor(this.player.currentTime - this.player.seekable.start(0));
		} catch (error) {
			return this.player.currentTime;
		}
	}

	getDuration() {
		try {
			return Math.floor(this.player.seekable.end(0) - this.player.seekable.start(0));
		} catch (error) {
			return this.player.duration || 0;
		}
	}

	getCurrentProgramDateTime() {
		return this.player.currentProgramDateTime || null;
	}

	isLive() {
		return this.player.duration === Infinity;
	}

	hasVideoTracks() {
		try {
			return this.player.videoTracks && this.player.videoTracks.length > 0;
		} catch (error) {}
		return false;
	}

	getVideoTrack() {
		try {
			if (this.hasVideoTracks()) {
				return this.player.videoTracks[0];
			}
		} catch (error) {}
		return null;
	}

	addVideoTracksListener(listener) {
		this.player.videoTracks.addEventListener('addtrack', listener);
		this.player.videoTracks.addEventListener('removetrack', listener);
		this.player.videoTracks.addEventListener('change', listener);
	}

	removeVideoTracksListener(listener) {
		try {
			this.player.videoTracks.removeEventListener('addtrack', listener);
			this.player.videoTracks.removeEventListener('removetrack', listener);
			this.player.videoTracks.removeEventListener('change', listener);
		} catch (exception) {}
	}

	getSubtitleTracks() {
		try {
			if (this.player.textTracks.length > 0) {
				return this.player.textTracks.filter((track) => track.kind === 'subtitles');
			}
		} catch (error) {}

		return [];
	}

	addTextTracksListener(listener) {
		this.player.textTracks.addEventListener('addtrack', listener);
		this.player.textTracks.addEventListener('removetrack', listener);
		this.player.textTracks.addEventListener('change', listener);
	}

	removeTextTracksListener(listener: any) {
		try {
			this.player.textTracks.removeEventListener('addtrack', listener);
			this.player.textTracks.removeEventListener('removetrack', listener);
			this.player.textTracks.removeEventListener('change', listener);
		} catch (exception) {}
	}

	getAudioTracks() {
		try {
			if (this.player.audioTracks.length > 0) {
				return this.player.audioTracks;
			}
		} catch (error) {}

		return [];
	}

	addAudioTracksListener(listener: any) {
		this.player.audioTracks.addEventListener('addtrack', listener);
		this.player.audioTracks.addEventListener('removetrack', listener);
		this.player.audioTracks.addEventListener('change', listener);
	}

	removeAudioTracksListener(listener: any) {
		try {
			this.player.audioTracks.removeEventListener('addtrack', listener);
			this.player.audioTracks.removeEventListener('removetrack', listener);
			this.player.audioTracks.removeEventListener('change', listener);
		} catch (exception) {}
	}

	addNetworkResponseInterceptor(interceptor: any) {
		this.player.network.addResponseInterceptor(interceptor);
	}

	async supportsContentProtectionRequirements() {
		const videoTrack = this.getVideoTrack();

		// @ts-ignore
		if (navigator.requestMediaKeySystemAccess && videoTrack && videoTrack.qualities) {
			try {
				await Promise.all(
					// @ts-ignore
					[...new Set(videoTrack.qualities)].map(({ codecs }) => {
						return navigator.requestMediaKeySystemAccess('com.widevine.alpha', [
							{
								videoCapabilities: [
									{
										contentType: `video/mp4;codecs="${codecs}"`,
										robustness: 'HW_SECURE_ALL',
									},
								],
							},
						]);
					})
				);

				return true;
			} catch (exception) {
				return false;
			}
		}

		return true;
	}

	destroy() {
		this.player.destroy();
	}
}
