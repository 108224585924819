import { useRef, useEffect } from 'react';

export function usePrevious<TValue>(value: TValue) {
	const ref = useRef<TValue | null>(null);

	useEffect(() => {
		ref.current = value;
	});

	return ref.current;
}
