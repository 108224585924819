export const ROOT_CATEGORY_ID = 14247;
export const AVAILABLE_UNTIL_DAYS = 14;
export const SIMILAR_CONTENT_LIMIT = 6;
export const ACTORS_LIMIT = 14;
export const POSITION_UPDATE_INTERVAL = 30000; // 30 seconds
export const SEEK_DELAY = 500; // 300 ms
export const PLAYBACK_CONTROLS_HIDE_TIMEOUT = 5000;
export const CONTENT_LIMIT = 24;
export const CATEGORY_LIMIT = 12;
export const ENTITLEMENT_UPDATE_INTERVAL = 60 * 1000; // Every minute
export const ERROR_TIMEOUT = 5 * 1000; // 5 seconds
