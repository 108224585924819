import { Request } from '../api';
import { Exception } from '../exceptions';

export class BearerAuthInterceptor {
	token: string;

	constructor(token: string) {
		if (!token) throw new Exception('BearerAuthInterceptor requires auth token');
		this.token = token;
	}

	async request(request: Request) {
		request.addHeaders({
			Authorization: `Bearer ${this.token}`,
		});
	}
}
