import { useCallback, useContext, useEffect, useMemo } from 'react';
import { ApolloError } from '@apollo/client';
import { USER_QUERY } from '../queries';
import { usePrevious } from './usePrevious';
import { factory, UserSettings } from '../models';
import { QueryContext } from '../contexts';
import { useAuthenticatedQuery } from './useAuthenticatedQuery';

type UseUserSettingsReturn = {
	settings: UserSettings | null;
	refetchSettings: () => Promise<void>;
	loadingSettings: boolean;
	settingsError: ApolloError;
};

export const useUserSettings = (pollInterval?: number): UseUserSettingsReturn => {
	const { accessToken } = useContext(QueryContext);

	const prevAccessToken = usePrevious(accessToken);

	const { data, loading, refetch, error } = useAuthenticatedQuery(USER_QUERY, {
		skip: !accessToken,
		pollInterval,
	});

	const settings = useMemo(() => {
		if (accessToken && data?.user?.settings) {
			return factory(data?.user?.settings);
		}

		return null;
	}, [accessToken, data]);

	const refetchSettings = useCallback(async () => {
		if (accessToken) {
			try {
				await refetch();
			} catch (exception) {}
		}
	}, [accessToken, refetch]);

	useEffect(() => {
		if (accessToken && prevAccessToken && accessToken !== prevAccessToken) {
			refetch();
		}
	}, [accessToken, prevAccessToken, refetch]);

	return { settings, refetchSettings, loadingSettings: loading, settingsError: error };
};
