import { Request } from './Request';
import { ClientResponse } from './ClientResponse';
import { ClientOptions } from '.';
import { ClientInterceptor, ClientInterceptorType } from '../interceptors';
import { PromiseSerial } from '../utils';

export const GET = 'GET';
export const PUT = 'PUT';
export const POST = 'POST';
export const DELETE = 'DELETE';

export class Client {
	interceptors: ClientInterceptor[];
	defaultHeaders: { [key: string]: any };
	baseUrl: string | null;

	constructor(options: ClientOptions = {}) {
		this.interceptors = [];
		this.defaultHeaders = options.defaultHeaders || {};
		this.baseUrl = options.baseUrl || null;
	}

	addInterceptor(interceptor: ClientInterceptor) {
		this.interceptors.push(interceptor);
		return this;
	}

	removeInterceptor(interceptor: ClientInterceptor) {
		this.interceptors.splice(this.interceptors.indexOf(interceptor));
		return this;
	}

	async serializeInterceptors<Tdata = any>(
		interceptors: ClientInterceptor[],
		param: Request | ClientResponse<Tdata>,
		interceptorType: ClientInterceptorType
	) {
		const interceptorRunners: ClientInterceptor[] = [];
		interceptors.forEach(interceptor => {
			if (interceptor[interceptorType]) {
				// @ts-ignore
				interceptorRunners.push(async data => interceptor[interceptorType](data));
			}
		});
		if (interceptorRunners.length > 0) {
			await PromiseSerial(interceptorRunners, param);
		}
	}

	async processRequestInterceptors(request: Request) {
		return this.serializeInterceptors([...this.interceptors, ...request.interceptors], request, 'request');
	}

	async processResponseInterceptors<TData>(request: Request, response: ClientResponse<TData>) {
		return this.serializeInterceptors([...this.interceptors, ...request.interceptors], response, 'response');
	}

	async beforeRequest(request: Request) {}

	async processUrl(request: Request) {
		let url = request.getFullUrl();
		if (this.baseUrl && !url.match(/^http/)) {
			url = this.baseUrl + url;
		}
		return url;
	}

	async performRequest(url: string, request: Request) {
		return fetch(url, {
			method: request.method,
			headers: { ...this.defaultHeaders, ...request.headers },
			body: request.body ? JSON.stringify(request.body) : null,
		});
	}

	async createResponse(request: Request, baseResponse: { [key: string]: any }) {
		const { status, headers } = baseResponse;

		const response = new ClientResponse().withStatusCode(status).withHeaders(headers);

		if (status !== 204) {
			try {
				const result = await baseResponse.json();
				response.withData(result);
			} catch (error) {}
		}

		await this.processResponseInterceptors(request, response);

		return response;
	}

	async request(request: Request) {
		await this.processRequestInterceptors(request);
		const url = await this.processUrl(request);
		try {
			const response = await this.performRequest(url, request);
			return this.createResponse(request, response);
		} catch (exception) {
			return this.createResponse(request, {
				status: 503,
				headers: new Headers(),
				ok: false,
			});
		}
	}

	async get(request: Request) {
		request.method = GET;
		return this.request(request);
	}

	async post(request: Request) {
		request.method = POST;
		return this.request(request);
	}

	async put(request: Request) {
		request.method = PUT;
		return this.request(request);
	}

	async del(request: Request) {
		request.method = DELETE;
		return this.request(request);
	}
}
