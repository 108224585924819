import { FILE_QUERY, FileType, Playable } from '@vodafoneis/sjonvarpskjarni-js-lib';
import { useCallback, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { useApolloClient } from '@apollo/client';
import { usePlayback } from '../contexts/PlaybackContext';
import { PLAYBACK_URL_PREFIX } from '../config/API';
import PlaybackFailedException from '../exceptions/PlaybackFailedException';

export const useContentUrl = (content: Playable) => {
	const { onPlaybackError } = usePlayback();
	const [url, setUrl] = useState<string | null>(null);

	const client = useApolloClient();

	const getUrl = useCallback(async () => {
		try {
			const { data } = await client.query({
				query: FILE_QUERY,
				variables: { fileId: content.getFile(FileType.DASH).id },
			});

			if (data?.file?.dashUrl) {
				setUrl(PLAYBACK_URL_PREFIX + data.file.dashUrl);
			} else {
				onPlaybackError(new PlaybackFailedException('Could not get url for playback'));
			}
		} catch (exception: any) {
			onPlaybackError(
				new PlaybackFailedException(
					`Could not get url for playback: ${
						exception?.message ? exception.message : 'failed to retrieve url from server'
					}`
				)
			);
		}
	}, [client, content, onPlaybackError]);

	useEffectOnce(() => {
		getUrl();
	});

	return url;
};
