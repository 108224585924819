import { Client } from './Client';
import { Request } from './Request';
import { Exception } from '../exceptions';

// GraphQL client will be removed shortly so we will not have any significant effort rewriting it.
type GraphQLRequest = {
	query: any;
	variables: any;
	transformer?: (data: any) => any;
};

const defaultTransformer = (data: any) => data;

export class GraphQLClient extends Client {
	static createRequest({ query, variables, transformer }: GraphQLRequest) {
		return new Request('', {
			queryParams: {
				query: query.generateQuery ? query.generateQuery() : query,
				// @ts-ignore
				variables: variables ? JSON.stringify(variables) : null,
			},
		}).addInterceptor({
			// @ts-ignore
			response: response => {
				if (query.transform) {
					response.data = query.transform(response.data);
				} else if (transformer) {
					response.data = transformer(response.data);
				}
			},
		});
	}

	// @deprecated
	static createQuery() {
		throw new Exception('createQuery is not supported anymore, use createRequest instead.');
	}

	constructor(options: any) {
		super(options);

		this.addInterceptor({
			response: async response => {
				if (response.data && response.data.data) {
					response.data = response.data.data;
				}
			},
		});
	}

	send(request: Request) {
		return this.get(request);
	}

	createRequest(params: any) {
		return GraphQLClient.createRequest(params);
	}

	createQuery() {
		return GraphQLClient.createQuery();
	}
}
