import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';

const IMAGES_HOST = process.env.REACT_APP_PUBLIC_IMAGES_HOST;

export const processImageUrl = (url: string) => {
	return `${IMAGES_HOST}${url}`;
};

type ImageComponentProps = {
	images: string[];
	className?: string;
	onLoaded?: () => void;
	onError?: () => void;
};

const StyledImage = styled.div<{ loaded: boolean; error: boolean; imageUrl: string | null }>`
	opacity: ${({ loaded }) => (loaded ? 1 : 0)};
	@include transition(opacity 0.4 s ease-in-out);
	background-image: ${({ imageUrl }) => (imageUrl ? `url(${imageUrl})` : 'none')};
`;

export const ImageComponentContainer = styled.div`
	background: rgba(255, 255, 255, 0.1);
`;

export const ImageComponent: React.FC<ImageComponentProps> = (props) => {
	const { images: urls, className, onLoaded, onError, children } = props;

	const [loaded, setLoaded] = useState(false);
	const [error, setError] = useState(false);
	const [imageIndex, setImageIndex] = useState(0);
	const [imageUrl, setImageUrl] = useState<string | null>(null);
	const images = useMemo(() => urls?.filter((url) => url) ?? [], [urls]);

	useEffect(() => {
		const image = new Image();
		const currentImageUrl = images[imageIndex] ? processImageUrl(images[imageIndex]) : null;

		const onImageLoad = () => {
			setLoaded(true);
			setError(false);
			setImageUrl(currentImageUrl);

			if (onLoaded) onLoaded();
		};

		const onImageError = () => {
			setLoaded(false);
			setError(true);
			setImageIndex(imageIndex + 1);
			setImageUrl(null);

			if (onError) onError();
		};

		image.addEventListener('load', onImageLoad);
		image.addEventListener('error', onImageError);

		if (currentImageUrl) {
			image.src = currentImageUrl;
		}

		return () => {
			image.removeEventListener('load', onImageLoad);
			image.removeEventListener('error', onImageError);
		};
	}, [imageIndex, images, onError, onLoaded]);

	return (
		<StyledImage className={className} error={error} loaded={loaded} imageUrl={imageUrl}>
			{children}
		</StyledImage>
	);
};
