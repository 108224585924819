export const PRIMARY = '#e18008';
export const PRIMARY_ACTIVE = '#E69C00';
export const SECONDARY = '#1f5359';
export const SECONDARY_DARKENED = '#063A40';
export const FONT_PRIMARY = '#eee';
export const FONT_SECONDARY = '#a197a1';
export const FADE_WHITE = 'rgba(255, 255, 255, 0.1)';
export const FADE_WHITE_FOCUS = 'rgba(255, 255, 255, 0.2)';
export const FADE_BLACK = 'rgba(126, 126, 126, 0.2)';
export const FADE_BLACK_FOCUS = 'rgba(126, 126, 126, 0.4)';
export const LINK_SECONDARY_INACTIVE = '#726672';
export const LINK_SECONDARY_ACTIVE = '#fff';
export const SUCCESS = '#28a745';
export const WARNING = '#ffc107';
export const ERROR = '#dc3545';
export const PROGRESS_FOREGROUND = '#fff';
export const PROGRESS_BACKGROUND = '#777';
export const BUTTON_BACKGROUND = '#999';
export const BUTTON_BACKGROUND_ACTIVE = '#bbb';
