import { Content, Request } from '@vodafoneis/sjonvarpskjarni-js-lib';
import { useCallback, useEffect, useRef } from 'react';
import APIClient from '../api/APIClient';
import { POSITION_UPDATE_INTERVAL } from '../config/constants';
import { useInterval, usePrevious } from 'react-use';
import { PlaybackState, usePlayback } from '../contexts/PlaybackContext';
import { usePlayer } from '../contexts/PlayerContext';

const savePositionOnServer = async (position: number, movieId: number) => {
	try {
		if (position >= 0 && movieId) {
			const request = new Request('position/$$movieId$$', {
				pathVariables: {
					movieId,
				},
				body: {
					position: Math.round(position),
				},
			});

			await APIClient.put(request);
		}
	} catch (exception) {
		console.error('Failed to save on server');
		// Ignore
	}
};

const { Buffering, Playing, Error, Stopped } = PlaybackState;

export const useSavePosition = (content: Content) => {
	const lastPosition = useRef<number>();

	const { getPosition } = usePlayer();

	const { state, position } = usePlayback();

	const prevState = usePrevious(state);

	const onPositionSaveInterval = useCallback(() => {
		if (lastPosition.current && content?.id && state === Playing) {
			savePositionOnServer(lastPosition.current, content.id);
		}
	}, [content, state]);

	useInterval(onPositionSaveInterval, POSITION_UPDATE_INTERVAL);

	const savePosition = useCallback(
		(position: number, immediate = false) => {
			if (!content?.id) return;

			lastPosition.current = position;

			if (immediate) {
				savePositionOnServer(position, content.id);
			}
		},
		[content]
	);

	const saveLastKnownPosition = useCallback(() => {
		if (lastPosition.current) {
			savePosition(lastPosition.current, true);
		}
	}, [savePosition]);

	// Save position when seeking is finished
	useEffect(() => {
		if (prevState === Buffering && state === Playing) {
			savePosition(getPosition(), true);
		}
	}, [getPosition, prevState, savePosition, state]);

	// On error or stop, save the last known position
	useEffect(() => {
		if (prevState === state) return;

		if ([Error, Stopped].includes(state)) {
			saveLastKnownPosition();
		}
	}, [prevState, saveLastKnownPosition, state]);

	// Save position when it changes
	useEffect(() => {
		savePosition(position);
	}, [position, savePosition]);
};
