import type { TimingType } from './TimingType';

export class Timing {
	id?: number;
	start?: number;
	end?: number;
	type?: TimingType;

	constructor(data: any) {
		Object.assign(this, data);
	}
}
