export class Exception extends Error {
	constructor(message: string = '', name: string = 'Exception') {
		super(message);
		this.message = message || '';
		this.name = name;

		Object.setPrototypeOf(this, Exception.prototype);
	}

	toString() {
		return this.message ? `${this.name}: ${this.message}` : this.name;
	}
}
