import styled from 'styled-components';
import { Children, cloneElement, isValidElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { useClassNames, UUID } from '@vodafoneis/sjonvarpskjarni-js-lib';

export enum Direction {
	Vertical = 'vertical',
	Horizontal = 'horizontal',
}

export type ItemProps<T = any> = {
	item: T;
	index: number;
	columnIndex: number;
	rowIndex: number;
};

export type RenderItem<T = any> = (itemProps: ItemProps<T>) => JSX.Element | null;

export type GridProps = {
	items: any[];
	className?: string;
	hasInitialFocus?: boolean;
	direction?: Direction;
	gutter?: number;
	columns?: number;
	endReachedThreshold?: number;
	initialIndex?: number;
	renderItem: RenderItem;
	keyExtractor?: <T>(itemProps: ItemProps<T>) => string;
	onItemSelected?: <T>(itemProps: ItemProps<T>) => void;
	onItemFocused?: <T>(itemProps: ItemProps<T>) => void;
	onEndReached?: () => void;
};

const { Horizontal, Vertical } = Direction;

const GridContainer = styled.div<{ direction: Direction }>`
	display: flex;
	flex-shrink: 0;
	flex-direction: ${({ direction }) => (direction === Horizontal ? 'row' : 'column')};
`;

const GridContent = styled.div<{ direction: Direction }>`
	display: flex;
	flex-shrink: 0;
	flex-direction: ${({ direction }) => (direction === Horizontal ? 'row' : 'column')};
	transition: transform 100ms ease-in-out;
`;

const GridRow = styled.div<{ direction: Direction; gutter: number }>`
	flex-shrink: 0;
	display: flex;
	flex-wrap: nowrap;
	margin-bottom: ${({ gutter }) => `${gutter}px`};

	:last-child {
		margin-bottom: 0;
	}
`;

type GridItemProps = { direction: Direction; gutter: number; columns: number; hasInitialFocus: boolean };

const GridItemBase = styled.div<Omit<GridItemProps, 'hasInitialFocus'>>`
	flex-shrink: 0;
	margin-right: ${({ gutter }) => `${gutter}px`};
	max-width: ${({ direction, columns }) => (direction === Vertical ? `${(1 / columns) * 100}%` : 'none')};
	max-height: ${({ direction, columns }) => (direction === Horizontal ? `${(1 / columns) * 100}%` : 'none')};

	:last-child {
		margin-right: 0;
	}
`;

const GridItem = withFocusable<GridItemProps>()((props) => {
	const { columns, gutter, direction, children, focused, hasInitialFocus, stealFocus } = props;

	useEffect(() => {
		if (hasInitialFocus) {
			stealFocus();
		}
	}, [hasInitialFocus, stealFocus]);

	const childrenWithProps = useMemo(
		() =>
			Children.map(children, (child) => {
				if (isValidElement(child)) {
					return cloneElement(child, { focused });
				}

				return child;
			}),
		[children, focused]
	);

	return (
		<GridItemBase columns={columns} gutter={gutter} direction={direction} className={'grid-item'}>
			{childrenWithProps}
		</GridItemBase>
	);
});

export const Grid = withFocusable<GridProps>({ trackChildren: true })((props) => {
	const gridId = useRef(UUID.fromString((Math.random() + 1).toString(36)));
	const lastEndReachedSize = useRef(0);

	const {
		className,
		direction,
		columns,
		items,
		gutter,
		hasInitialFocus,
		initialIndex,
		endReachedThreshold,
		renderItem,
		onItemFocused,
		onItemSelected,
		keyExtractor,
		onEndReached,
	} = props as Required<GridProps>;

	const gridRef = useRef<HTMLDivElement>();
	const gridContentRef = useRef<HTMLDivElement>();

	const [hasInitialized, setHasInitialized] = useState(!initialIndex);

	const rows = useMemo(() => {
		if (direction === Vertical) {
			return items.reduce((prevValue, currValue, index) => {
				const rowIndex = Math.floor(index / columns);
				if (rowIndex in prevValue) {
					prevValue[rowIndex].push(currValue);
				} else {
					prevValue[rowIndex] = [currValue];
				}
				return prevValue;
			}, []);
		}

		if (columns > 1) {
			console.warn('Columns > 1 not supported in horizontal mode, will fallback to columns = 1');
		}

		return [items];
	}, [columns, direction, items]);

	const scrollTo = useCallback(({ x, y }: { x: number; y: number }) => {
		if (gridContentRef.current) {
			gridContentRef.current.style.transform = `translate3d(${x}px, ${y}px, 0)`;
		}
	}, []);

	const scrollVertically = useCallback(
		(layoutObject: LayoutObject, gridBoundingRect: DOMRect, gridContentBoundingRect: DOMRect) => {
			// If the content is smaller than the grid, there is never any need to scroll
			if (gridContentBoundingRect.height < gridBoundingRect.height) return;

			const itemBoundingRect = layoutObject.node.getBoundingClientRect();
			const centerPos = gridBoundingRect.top + gridBoundingRect.height / 2;
			const itemCenterPos = itemBoundingRect.top + itemBoundingRect.height / 2 - gridContentBoundingRect.top;
			const maxItemPosition = gridContentBoundingRect.height - gridBoundingRect.height / 2;
			const maxScrollOffset = gridContentBoundingRect.height - gridBoundingRect.height;

			if (itemCenterPos < centerPos) {
				scrollTo({ x: 0, y: 0 });
			} else if (itemCenterPos > maxItemPosition) {
				scrollTo({ x: 0, y: -maxScrollOffset });
			} else if (maxScrollOffset > 0) {
				scrollTo({ x: 0, y: centerPos - itemCenterPos });
			}
		},
		[scrollTo]
	);

	const scrollHorizontally = useCallback(
		(layoutObject: LayoutObject, gridBoundingRect: DOMRect, gridContentBoundingRect: DOMRect) => {
			// If the content is smaller than the grid, there is never any need to scroll
			if (gridContentBoundingRect.width < gridBoundingRect.width) return;

			const itemBoundingRect = layoutObject.node.getBoundingClientRect();

			const centerPos = gridBoundingRect.left + gridBoundingRect.width / 2;
			const itemCenterPos = itemBoundingRect.left + itemBoundingRect.width / 2 - gridContentBoundingRect.left;
			const maxItemPosition = gridContentBoundingRect.width - gridBoundingRect.width / 2;
			const maxScrollOffset = gridContentBoundingRect.width - gridBoundingRect.width;

			if (itemCenterPos < centerPos) {
				scrollTo({ x: 0, y: 0 });
			} else if (itemCenterPos > maxItemPosition) {
				scrollTo({ x: -maxScrollOffset, y: 0 });
			} else {
				scrollTo({ x: centerPos - itemCenterPos, y: 0 });
			}
		},
		[scrollTo]
	);

	const checkEndReached = useCallback(
		({ index, rowIndex }) => {
			const effectiveIndex = direction === Vertical ? rowIndex : index;
			const numberOfRows = Math.floor((items.length - 1) / columns);
			const effectiveSize = direction === Vertical ? numberOfRows : items.length - 1;

			if (lastEndReachedSize.current < effectiveSize && effectiveSize - effectiveIndex < endReachedThreshold) {
				lastEndReachedSize.current = effectiveSize;
				onEndReached?.();
			}
		},
		[columns, direction, endReachedThreshold, items.length, onEndReached]
	);

	const onBecameFocused = useCallback(
		(itemProps: ItemProps, layoutObject: LayoutObject) => {
			setHasInitialized(true);
			onItemFocused?.(itemProps);

			const gridBoundingRect = gridRef.current?.getBoundingClientRect();
			const gridContentBoundingRect = gridContentRef.current?.getBoundingClientRect();
			if (!gridBoundingRect || !gridContentBoundingRect) return;

			if (direction === Vertical) {
				scrollVertically(layoutObject, gridBoundingRect, gridContentBoundingRect);
			} else {
				scrollHorizontally(layoutObject, gridBoundingRect, gridContentBoundingRect);
			}

			checkEndReached(itemProps);
		},
		[checkEndReached, direction, onItemFocused, scrollHorizontally, scrollVertically]
	);

	const createItem = useCallback(
		({ item, columnIndex, rowIndex }) => {
			const index = rowIndex * columns + columnIndex;
			const itemProps = { item, index, rowIndex, columnIndex };

			return (
				<GridItem
					key={keyExtractor(itemProps)}
					focusable={hasInitialized || index === initialIndex}
					hasInitialFocus={hasInitialFocus && index === initialIndex}
					gutter={gutter}
					direction={direction}
					columns={columns}
					onEnterPress={() => {
						onItemSelected?.(itemProps);
					}}
					onBecameFocused={(layoutObject) => onBecameFocused?.(itemProps, layoutObject)}
				>
					{renderItem(itemProps)}
				</GridItem>
			);
		},
		[
			columns,
			direction,
			gutter,
			hasInitialFocus,
			hasInitialized,
			initialIndex,
			keyExtractor,
			onBecameFocused,
			onItemSelected,
			renderItem,
		]
	);

	const classNames = useClassNames('grid', { [className]: !!className });

	return (
		// @ts-ignore
		<GridContainer ref={gridRef} className={classNames} direction={direction}>
			<GridContent
				className={'grid-content'}
				direction={direction}
				// @ts-ignore
				ref={gridContentRef}
			>
				{rows.map((rowItems: any[], rowIndex: number) => (
					<GridRow
						key={`${gridId.current}-row-${rowIndex}`}
						direction={direction}
						gutter={gutter}
						className={'grid-row'}
					>
						{rowItems.map((item: any, columnIndex: number) => createItem({ item, columnIndex, rowIndex }))}
					</GridRow>
				))}
			</GridContent>
		</GridContainer>
	);
});

Grid.defaultProps = {
	direction: Direction.Vertical,
	gutter: 20,
	columns: 1,
	initialIndex: 0,
	hasInitialFocus: false,
	endReachedThreshold: 3,
	keyExtractor: ({ item }: { item: any }) => item.key || item.id,
};
