import type { QueryHookOptions } from '@apollo/client';
import { useMemo } from 'react';
import { ApolloError } from '@apollo/client';
import type { Collection } from '../models';
import { useFetchMoreQuery } from './useFetchMoreQuery';
import { COLLECTION_QUERY } from '../queries';
import { factory } from '../models';

const itemCountExtractor = (data: any) => data?.collection?.content?.length ?? null;

type UseCollectionReturn = {
	collection: Collection;
	fetchMoreContent: any;
	loadingCollection: boolean;
	collectionError: ApolloError;
};

export const useCollection = (
	collectionId: number,
	limit: number,
	queryOptions: QueryHookOptions<any, any> = {}
): UseCollectionReturn => {
	const options = useMemo(
		() => ({
			variables: {
				collectionId,
				limit,
			},
			...queryOptions,
		}),
		[collectionId, limit, queryOptions]
	);

	const { data, fetchMore, loading, error } = useFetchMoreQuery(COLLECTION_QUERY, itemCountExtractor, options);

	const collection = useMemo(() => (data?.collection ? factory(data.collection) : null), [data]);

	return { collection, fetchMoreContent: fetchMore, loadingCollection: loading, collectionError: error };
};
