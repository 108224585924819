import styled from 'styled-components';
import { Content, ImagePreset, ModuleType } from '@vodafoneis/sjonvarpskjarni-js-lib';
import { getModuleSize } from '../config/layout';
import React from 'react';

const Container = styled.div<{ moduleType?: ModuleType }>`
	position: relative;
	flex-shrink: 0;
	background-repeat: no-repeat;
	background-size: 100% auto;
	border-radius: 6px;
	margin-right: 20px;
	width: ${({ moduleType }) => `${getModuleSize(moduleType).width}px`};
	height: ${({ moduleType }) => `${getModuleSize(moduleType).height}px`};

	&:last-child {
		margin-right: 0;
	}
`;

const Highlight = styled.div<{ focused: boolean }>`
	opacity: ${({ focused }) => (focused ? 1 : 0)};
	display: block;
	position: absolute;
	top: -6px;
	bottom: -6px;
	left: -6px;
	right: -6px;
	border: 4px solid rgb(225, 128, 8);
	transition: opacity 0.2s ease-in-out 0s;
	border-radius: 12px;
`;

type ContentComponentProps = {
	content: Content;
	moduleType?: ModuleType;
	focused?: boolean;
};

export const ContentComponent: React.FC<ContentComponentProps> = (props) => {
	const { content, moduleType, focused } = props;

	const backgroundUrl = ['POSTER', 'POSTER_LARGE'].includes(moduleType ?? 'null')
		? content.getPosterImageUrl({ presets: [ImagePreset.POSTER_SMALL] })
		: content.getPromoImageUrl({ presets: [ImagePreset.STILL_LARGE] }) ??
		  content.getBackdropImageUrl({ presets: [ImagePreset.STILL_LARGE] });

	return (
		<Container
			moduleType={moduleType}
			style={backgroundUrl ? { backgroundImage: `url(https://varnish.tv.c.is${backgroundUrl})` } : {}}
		>
			<Highlight focused={focused || false} />
		</Container>
	);
};
