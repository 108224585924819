export const VAST_TRACKING_EVENTS = {
	FIRST_QUARTILE: 'firstQuartile',
	MIDPOINT: 'midpoint',
	THIRD_QUARTILE: 'thirdQuartile',
	COMPLETE: 'complete',
	SKIP: 'skip',
};

export class VASTParser {
	data: string;
	xml: any = null;
	adElement: any = null;
	impressionUrl: string | null = null;
	mediaUrl: string | null = null;
	mediaId: string | null = null;
	skipOffset: number | null = null;
	trackingEvents: any = null;

	constructor(data: string) {
		this.data = data;
	}

	async getXml() {
		if (this.xml === null) {
			const parser = new DOMParser();
			this.xml = parser.parseFromString(this.data, 'text/xml');
		}

		return this.xml;
	}

	async getAdElement() {
		if (this.adElement === null) {
			const xml = await this.getXml();
			// eslint-disable-next-line prefer-destructuring
			this.adElement = xml.getElementsByTagName('Ad')[0].getElementsByTagName('InLine')[0];
		}

		return this.adElement;
	}

	async getImpressionUrl() {
		if (this.impressionUrl === null) {
			const ad = await this.getAdElement();
			this.impressionUrl = ad.getElementsByTagName('Impression')[0].childNodes[0].nodeValue;
		}

		return this.impressionUrl;
	}

	async getMediaUrl() {
		if (this.mediaUrl === null) {
			const ad = await this.getAdElement();
			// eslint-disable-next-line
			for (let mediaFile of ad.getElementsByTagName('MediaFile')) {
				const mediaUrl = mediaFile.childNodes[1].nodeValue;
				if (mediaUrl) {
					this.mediaUrl = mediaUrl;
					break;
				}
			}
		}

		return this.mediaUrl;
	}

	async getMediaId() {
		if (this.mediaId === null) {
			const ad = await this.getAdElement();
			// eslint-disable-next-line
			for (let mediaFile of ad.getElementsByTagName('MediaFile')) {
				const mediaUrl = mediaFile.childNodes[1].nodeValue;
				const mediaIdMatches = mediaUrl.match(/A[0-9]+/);
				if (mediaIdMatches) {
					// eslint-disable-next-line prefer-destructuring
					this.mediaId = mediaIdMatches[0];
					break;
				}
			}
		}

		return this.mediaId;
	}

	async getSkipOffset() {
		if (this.skipOffset === null) {
			const ad = await this.getAdElement();
			const [hours, minutes, seconds] = ad
				.getElementsByTagName('Linear')[0]
				// eslint-disable-next-line
				.attributes['skipoffset'].nodeValue.split(':')
				.map((val: any) => Number(val));
			this.skipOffset = hours * 3600 + minutes * 60 + seconds;
		}

		return this.skipOffset;
	}

	async getTrackingEvents() {
		if (this.trackingEvents === null) {
			const trackingEvents = {};

			const ad = await this.getAdElement();

			// eslint-disable-next-line
			for (let tracking of ad.getElementsByTagName('Tracking')) {
				// @ts-ignore
				// eslint-disable-next-line
				trackingEvents[tracking.attributes['event'].nodeValue] = tracking.childNodes[0].nodeValue;
			}

			this.trackingEvents = trackingEvents;
		}

		return this.trackingEvents;
	}
}
