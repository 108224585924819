import styled from 'styled-components';
import background from '../images/default-bg@1.5x.png';

export const BackgroundImage = styled.div`
	position: absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	background: url(${background}) no-repeat;
	background-size: 100% 100%;
`;
