import { ApolloClient, HttpLink, InMemoryCache, serializeFetchParameter, PossibleTypesMap } from '@apollo/client';
import { stripIgnoredCharacters } from 'graphql';
import { skipLimitPagination } from './skipLimitPagination';

export type CreateApolloClientParams = {
	host: string;
	apiVersion: number | string;
	possibleTypes: PossibleTypesMap;
};

export const createApolloClient = ({ host, apiVersion, possibleTypes }: CreateApolloClientParams) => {
	const customFetch = (uri: string, { body, ...options }: { body: any }) => {
		const { query, variables, operationName } = JSON.parse(body);
		const queryParams = [`query=${encodeURIComponent(stripIgnoredCharacters(query))}`];

		if (variables) {
			queryParams.push(`variables=${encodeURIComponent(serializeFetchParameter(variables, 'variables'))}`);
		}

		if (operationName) {
			queryParams.push(`operationName=${encodeURIComponent(operationName)}`);
		}

		// @ts-ignore
		options.headers['Accept-Version'] = apiVersion; // eslint-disable-line no-param-reassign

		return fetch(`${uri}?${queryParams.join('&')}`, {
			...options,
			method: 'GET',
		});
	};

	const httpLink = new HttpLink({ uri: `${host?.replace(/\/*$/, '')}/graphql`, fetch: customFetch });

	return new ApolloClient({
		link: httpLink,
		cache: new InMemoryCache({
			possibleTypes,
			typePolicies: {
				Query: {
					fields: {
						category: {
							read(_, { args, toReference }) {
								return toReference({
									__typename: 'Category',
									id: args.id,
								});
							},
						},
						content: {
							merge: true,
						},
						ads: skipLimitPagination(['regionId']),
						watched: skipLimitPagination(),
						products: skipLimitPagination(),
						purchases: skipLimitPagination(),
						search: skipLimitPagination(['q']),
						live: skipLimitPagination(['filter', 'channelIds']),
						favorites: skipLimitPagination(),
						section: {
							read(_, { args, toReference }) {
								return toReference({
									__typename: 'Section',
									id: args.id,
								});
							},
						},
					},
				},
				Category: {
					fields: {
						categories: skipLimitPagination(),
						content: skipLimitPagination(),
					},
				},
				Collection: {
					fields: {
						content: skipLimitPagination(),
					},
				},
				Section: {
					fields: {
						content: skipLimitPagination(),
					},
				},
			},
		}),
	});
};
