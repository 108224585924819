import { useMemo } from 'react';
import type { QueryHookOptions } from '@apollo/client';
import { ApolloError } from '@apollo/client';
import { useFetchMoreQuery } from './useFetchMoreQuery';
import { CATEGORY_QUERY } from '../queries';
import { factory } from '../models';
import type { Category } from '../models';
import type { FetchMoreCallback } from './useFetchMore';

const itemCountExtractor = (data: any) => data?.category?.content?.length ?? null;

type UseCategoryReturn = {
	category: Category | null;
	fetchMoreContent: FetchMoreCallback;
	loadingCategory: boolean;
	categoryError: ApolloError;
};

export const useCategory = (
	categoryId: number,
	limit: number,
	queryOptions: QueryHookOptions<any, any> = {}
): UseCategoryReturn => {
	const options = useMemo(
		() => ({
			variables: {
				categoryId,
				limit,
			},
			...queryOptions,
		}),
		[categoryId, limit, queryOptions]
	);

	const { data, fetchMore, loading, error } = useFetchMoreQuery(CATEGORY_QUERY, itemCountExtractor, options);

	const category = useMemo(() => (data?.category ? factory(data.category) : null), [data]);

	return { category, fetchMoreContent: fetchMore, loadingCategory: loading, categoryError: error };
};
