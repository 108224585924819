import styled from 'styled-components';
import React, { useMemo } from 'react';
import moment from 'moment';
import { PRIMARY_ACTIVE, PROGRESS_BACKGROUND, PROGRESS_FOREGROUND } from '../styles/colors';

const progressBarWidth = 1350;

const ProgressBarContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const ProgressText = styled.div`
	font-weight: bold;
	font-size: 34px;
`;

const ProgressContainer = styled.div`
	position: relative;
	width: ${progressBarWidth}px;
	height: 15px;
	margin: 0 30px;
`;

const ProgressWrapper = styled.div`
	height: 100%;
	width: 100%;
	border-radius: 10px;
	background-color: ${PROGRESS_BACKGROUND};
	overflow: hidden;
	-webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
`;

const Progress = styled.div`
	width: 100%;
	height: 100%;
	background-color: ${PROGRESS_FOREGROUND};
`;

const ProgressIndicator = styled.div`
	position: absolute;
	left: -15px;
	top: -7.5px;
	bottom: -7.5px;
	right: 1920px;
	height: 30px;
	width: 30px;
	border-radius: 15px;
	background-color: ${PRIMARY_ACTIVE};
`;

type ProgressBarProps = {
	position: number;
	duration: number;
};

export const ProgressBar: React.FC<ProgressBarProps> = (props) => {
	const { position, duration } = props;

	const progress = useMemo(() => {
		if (duration > 0 && position > 0) {
			return position / duration;
		}
		return 0;
	}, [duration, position]);

	const positionText = useMemo(() => moment.utc(position * 1000).format('HH:mm:ss'), [position]);
	const durationText = useMemo(() => moment.utc(duration * 1000).format('HH:mm:ss'), [duration]);

	return (
		<ProgressBarContainer>
			<ProgressText>{positionText}</ProgressText>
			<ProgressContainer>
				<ProgressWrapper>
					<Progress
						// Inline styles seems to perform better than adding a prop to styled components
						// Adding a prop to styled components changes the class name for each prop change
						style={{ transform: `translate3d(${(progress - 1) * progressBarWidth}px, 0, 0)` }} />
				</ProgressWrapper>
				<ProgressIndicator
					style={{
						transform: `translate3d(${progress * progressBarWidth}px, 0, 0) scale(${!!position ? 1.2 : 1})`,
					}}
				/>
			</ProgressContainer>
			<ProgressText>{durationText}</ProgressText>
		</ProgressBarContainer>
	);
};
