import { RetrierChecksExceededException, RetrierCancelledException } from '../exceptions';
import { VFIUtils } from './VFIUtils';

export class Retrier {
	data: any;
	verifier: any;
	action: any;
	interval: any;
	retries: number;
	onCancel: () => void;
	hasStarted: boolean;
	cancelled: boolean;

	constructor({ data, verifier, action, interval, retries, onCancel }: any) {
		this.data = data;
		this.verifier = verifier;
		this.action = action;
		this.interval = interval;
		this.retries = retries;
		this.onCancel = onCancel;
		this.hasStarted = false;
		this.cancelled = false;
	}

	async start() {
		if (!this.hasStarted) {
			this.hasStarted = true;

			while (this.retries >= 0 && !this.cancelled) {
				try {
					const success = await this.verifier(this.data);

					if (success) {
						return this.data;
					}

					this.retries -= 1;

					if (!this.cancelled) {
						await this.wait();
					}

					if (!this.cancelled) {
						this.data = await this.action();
					}
				} catch (exception) {
					if (!this.cancelled) {
						throw exception;
					}
				}
			}

			if (this.cancelled) {
				throw new RetrierCancelledException();
			} else {
				throw new RetrierChecksExceededException();
			}
		}

		return this.data;
	}

	async wait() {
		await VFIUtils.wait(this.interval);
	}

	stop() {
		this.retries = 0;
		this.cancelled = true;
		this.onCancel && this.onCancel();
	}
}
