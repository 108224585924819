export enum ERROR_CODES {
	ERROR_MISSING_PARAMS = 4,
	ERROR_NEED_LOGIN = 15,
	ERROR_NOT_SUBSCRIBED = 16,
	ERROR_DEVICE_LIMIT_REACHED = 17,
	ERROR_GEO_BLOCKING = 18,
	ERROR_ACCOUNT_BLOCKING = 19,
	ERROR_ENTITLEMENT_LIMIT_REACHED = 20,
	ERROR_PURCHASE_LIMIT_REACHED = 21,
	ERROR_PHONE_NUMBER_INVALID = 510,
	ERROR_PHONE_NUMBER_IN_USE = 511,
	ERROR_SSN_INVALID = 512,
	ERROR_SSN_NOT_INDIVIDUAL = 513,
	ERROR_SSN_NOT_OVER_18 = 514,
	ERROR_SSN_NOT_IN_NATIONAL_REGISTRY = 515,
	ERROR_EMAIL_INVALID = 516,
	ERROR_PAYMENT_CHECK_NOT_OK = 517,
	ERROR_MAX_TRIES_EXCEEDED = 518,
	ERROR_SESSION_EXPIRED = 519,
	ERROR_INVALID_SMS_CODE = 520,
	ERROR_PASSWORD_INVALID = 521,
	ERROR_CREATE_ACCOUNT_FAILED = 522,
	ERROR_DELETE_ACCOUNT_FAILED = 524,
	ERROR_USERNAME_NOT_FOUND = 525,
	ERROR_UPDATE_PASSWORD_FAILED = 526,
}

export class ClientResponse<TData = any> {
	data: TData | null = null;
	statusCode: number = 0;
	headers?: Headers;

	withData(data: TData) {
		this.data = data;
		return this;
	}

	withStatusCode(statusCode: number) {
		this.statusCode = statusCode;
		return this;
	}

	withHeaders(headers: Headers) {
		this.headers = headers;
		return this;
	}

	isSuccess() {
		return this.statusCode >= 200 && this.statusCode < 400;
	}

	isError() {
		return this.statusCode >= 400;
	}

	getErrorCode() {
		const errorCodeHeader = this.headers?.get('X-Status-Code');

		if (errorCodeHeader) {
			return Number(errorCodeHeader);
		}

		return 0;
	}

	getErrorReason() {
		const errorReasonHeader = this.headers?.get('X-Status-Reason');

		if (errorReasonHeader) {
			return errorReasonHeader;
		}

		return null;
	}
}
