import type { Image } from './Image';
import { generateImagePath, ImagePathParams } from '../utils/ImageUtils';
import { ModelType } from './ModelType';

export class Actor {
	__typename?: ModelType;
	id?: number;
	name?: string;
	character?: string;
	image?: Image;

	constructor(data: object) {
		Object.assign(this, data);
	}

	getImageUrl(params: ImagePathParams = {}) {
		if (this.image) {
			return generateImagePath({
				hash: this.image.hash,
				...params,
			});
		}

		return null;
	}
}
