import { License } from '../models/License';
import { Playable } from '../models/Playable';
import { Episode } from '../models/Episode';
import { Series } from '../models/Series';
import { Provider } from '../models/Provider';
import { Channel } from '../models/Channel';
import { MovieType } from '../models/MovieType';

const { MOVIE, TV } = MovieType;

export function generateBasicContentInfo(content: Playable, license?: License) {
	const { id, title } = content;

	const provider = license?.provider;

	const basicInfo: { [key: string]: any } = {
		itemId: id,
		itemTitle: title,
		itemType: MOVIE,
		itemDuration: content.duration * 60,
		licenseId: license?.id,
		creditsPosition: content?.creditTiming?.start ?? null,
	};

	let episodeInfo: { [key: string]: any } = {};

	if (content instanceof Episode) {
		const { episodeNumber, episodeSeason, series } = content;
		const { id: seriesId, title: seriesTitle }: Series = series;

		basicInfo.itemType = TV;

		episodeInfo = {
			seriesId,
			seriesTitle,
			episodeNumber,
			episodeSeason,
		};
	}

	let providerInfo = {};

	if (provider) {
		const { id: providerId, title: providerTitle, type: providerType }: Provider = provider;

		providerInfo = {
			providerId,
			providerTitle,
			providerType,
		};
	}

	return {
		...basicInfo,
		...episodeInfo,
		...providerInfo,
	};
}

export function generateBasicChannelInfo(channel: Channel) {
	const { operatorRef, title } = channel;

	return {
		itemId: operatorRef,
		itemTitle: title,
		// @ts-ignore
		itemType: channel.channelType,
	};
}
