import { generateImagePath, ImagePathParams } from '../utils/ImageUtils';
import { ContentType } from './ContentType';
import type { Image } from './Image';
import type { Product } from './Product';
import type { Director } from './Director';
import type { Actor } from './Actor';
import type { Genre } from './Genre';
import type { TagInterface } from './TagInterface';

const { MOVIE, EPISODE, SERIES } = ContentType;

export class Content {
	__typename?: ContentType;
	id?: number;
	title?: string;
	description?: string;
	year?: number;
	posterImage?: Image;
	backdropImage?: Image;
	promoImage?: Image;
	product?: Product;
	genres?: Genre[];
	directors?: Director[];
	actors?: Actor[];
	imdbRating?: number;
	adTags?: TagInterface[];
	similar?: Content[];
	favorite?: boolean;

	constructor(data: object) {
		Object.assign(this, data);
	}

	getPosterImageUrl(params: ImagePathParams = {}) {
		if (this.posterImage) {
			return generateImagePath({
				hash: this.posterImage.hash,
				...params,
			});
		}

		return null;
	}

	getBackdropImageUrl(params: ImagePathParams = {}) {
		if (this.backdropImage) {
			return generateImagePath({
				hash: this.backdropImage.hash,
				...params,
			});
		}

		return null;
	}

	getPromoImageUrl(params: ImagePathParams = {}) {
		if (this.promoImage) {
			return generateImagePath({
				hash: this.promoImage.hash,
				...params,
			});
		}

		return null;
	}

	getProduct() {
		if (this.product) {
			return this.product;
		}

		return null;
	}

	isEpisode() {
		return this.__typename === EPISODE;
	}

	isMovie() {
		return this.__typename === MOVIE;
	}

	isSeries() {
		return this.__typename === SERIES;
	}
}
