import React, { useMemo } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import type { Episode, Series } from '@vodafoneis/sjonvarpskjarni-js-lib';
import { ImagePreset, LicenseType, useProgress } from '@vodafoneis/sjonvarpskjarni-js-lib';
import { ImageComponent, ImageComponentContainer } from './ImageComponent';
import { FONT_SECONDARY, PROGRESS_BACKGROUND, PROGRESS_FOREGROUND } from '../styles/colors';
import { useAvailableUntil } from '../hooks/useAvailableUntil';
import { ExpandableText } from './ExpandableText';
import { VStack } from './VStack';

const EpisodeWrapper = styled(VStack)<{ focused: boolean }>`
	padding: 20px;
	width: 483px;
	background-color: ${({ focused }) => (focused ? 'rgba(255,255,255,.2)' : 'transparent')};
	border-radius: 6px;
`;

const EpisodeContainer = styled.div`
	display: flex;
	margin-bottom: 10px;
`;

const EpisodeImageContainer = styled(ImageComponentContainer)`
	width: 190px;
	min-width: 190px;
	height: 107px;
	border-radius: 6px;
	overflow: hidden;
	margin-right: 20px;
	position: relative;
	display: flex;
	justify-content: center;
	flex-direction: column;
	cursor: pointer;
`;

const EpisodeImage = styled(ImageComponent)`
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
`;

const EpisodeTitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-right: 20px;
`;

const EpisodeTitle = styled.div`
	font-weight: bold;
	line-height: 1.3;
	font-size: 26px;
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	white-space: normal;
`;

const EpisodeSubtitle = styled.div`
	color: ${FONT_SECONDARY};
	line-height: 1.3;
	font-size: 22px;
`;

const EpisodeDescription = styled.div`
	line-height: 1.3;
	font-size: 22px;
	padding-right: 20px;
`;

const EpisodeProgressContainer = styled.div`
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	background: ${PROGRESS_BACKGROUND};
`;

type EpisodeProgressProps = {
	progress: number;
};

const EpisodeProgress = styled.div<EpisodeProgressProps>`
	width: ${(props) => Math.round(props.progress * 100)}%;
	height: 4px;
	background: ${PROGRESS_FOREGROUND};
`;

type EpisodeComponentProps = {
	series: Series;
	episode: Episode;
	focused?: boolean;
};

export const EpisodeComponent: React.FC<EpisodeComponentProps> = (props) => {
	const { series, episode, focused } = props as Required<EpisodeComponentProps>;
	const { t } = useTranslation();

	const availableUntil = useAvailableUntil(episode);
	const { progress } = useProgress(episode);

	const subtitle = useMemo(() => {
		if (episode.isTVOD()) {
			const license = episode.getLicense(LicenseType.TVOD);

			if (license?.rental?.validUntil) {
				return t('ContentInfo.RentedUntil.WithDate', {
					date: moment(license?.rental?.validUntil).format('D. MMM'),
				});
			}

			return t('ContentInfo.Price', {
				// @ts-ignore
				price: license.price.value,
			});
		}

		return availableUntil ? `${t('ContentInfo.AvailableUntil.Title')} ${availableUntil}` : `${episode.duration} mín.`;
	}, [availableUntil, episode, t]);

	return (
		<EpisodeWrapper focused={focused}>
			<EpisodeContainer>
				<EpisodeImageContainer>
					<EpisodeImage
						images={[
							episode.getStillImageUrl({ presets: [ImagePreset.STILL_MEDIUM] }),
							episode.getBackdropImageUrl({ presets: [ImagePreset.STILL_MEDIUM] }),
						]}
					/>
					<EpisodeProgressContainer>
						<EpisodeProgress progress={progress} />
					</EpisodeProgressContainer>
				</EpisodeImageContainer>
				<EpisodeTitleContainer>
					<EpisodeTitle>{`${episode.episodeNumber}. ${episode.title}`}</EpisodeTitle>
					<EpisodeSubtitle>{subtitle}</EpisodeSubtitle>
				</EpisodeTitleContainer>
			</EpisodeContainer>
			<EpisodeDescription>
				<ExpandableText numberOfLines={3}>
					{episode.description && episode.description !== series.description && episode.description}
				</ExpandableText>
			</EpisodeDescription>
		</EpisodeWrapper>
	);
};
