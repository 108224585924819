import React from 'react';
import { Content, Playable } from '@vodafoneis/sjonvarpskjarni-js-lib';
import { ContentInfoWrapper } from './ContentInfoWrapper';
import { ContentInfoSubtitle } from './ContentInfoSubtitle';
import { ContentInfoTitle } from './ContentInfoTitle';
import { ContentInfoDescription } from './ContentInfoDescription';
import { ContentButtons } from '../ContentButtons';
import { ContentInfoDetails } from './ContentInfoDetails';

type ContentInfoProps = {
	content: Content;
	playable?: Playable;
	onBlur?: () => void;
	onFocus?: () => void;
};

const ContentInfo: React.FC<ContentInfoProps> = ({ content, playable, onBlur, onFocus }) => {
	if (!content) return null;

	return (
		<>
			<ContentInfoWrapper>
				<ContentInfoTitle content={content} />
				<ContentInfoSubtitle content={content} />
				<ContentInfoDescription description={content.description} />
				<ContentInfoDetails content={content} />
			</ContentInfoWrapper>
			{!!playable && <ContentButtons playable={playable} onBlur={onBlur} onFocus={onFocus} />}
		</>
	);
};

export default ContentInfo;
