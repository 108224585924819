import { Request } from '../api';
import { Exception } from '../exceptions';

export class BasicAuthInterceptor {
	authToken: string;

	constructor(authToken: string) {
		if (!authToken) throw new Exception('BasicAuthInterceptor requires auth token');
		this.authToken = authToken;
	}

	async request(request: Request) {
		request.addHeaders({
			Authorization: `Basic ${this.authToken}`,
		});
	}
}
