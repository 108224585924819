export class Position {
	value?: number;
	timestamp?: Date;

	constructor(data: any) {
		Object.assign(this, data);

		if (data.timestamp) {
			this.timestamp = new Date(data.timestamp * 1000);
		}
	}
}
