import type { FileType } from './FileType';

export class File {
	id?: number;
	type: FileType;
	duration?: number;
	filename?: string;
	dashUrl?: string;
	hlsUrl?: string;

	constructor(data: object) {
		Object.assign(this, data);
	}

	isOfType(type: FileType) {
		return type === this.type;
	}
}
