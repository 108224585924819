import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Content, Movie, Series } from '@vodafoneis/sjonvarpskjarni-js-lib';
import { ContentInfoDataItem } from './ContentInfoDataItem';

type ContentInfoYearProps = {
	content: Content;
};

export const ContentInfoYear: React.FC<ContentInfoYearProps> = ({ content }) => {
	const { t } = useTranslation();

	const yearsString = useMemo(() => {
		if (!content) return null;

		if (content instanceof Movie) {
			const { year } = content;
			if (year) {
				return year;
			}
		}

		if (content instanceof Series) {
			const seasons = content?.seasons ?? [];

			const years = seasons
				.map((season) => season.year)
				.filter((year) => year && year > 0)
				.filter((year, index, self) => self.indexOf(year) === index);

			if (years.length > 0) {
				return years.length > 1 ? `${years[0]}-${years[years.length - 1]}` : years[0];
			}

			return null;
		}

		return null;
	}, [content]);

	if (!yearsString) return null;

	return <ContentInfoDataItem title={t('ContentInfo.Year')} value={String(yearsString)} />;
};
