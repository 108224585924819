import { useMemo } from 'react';
import type { DocumentNode } from 'graphql';
import type { OperationVariables, QueryResult, QueryHookOptions, FetchMoreOptions } from '@apollo/client';
import { ItemCountExtractor, useFetchMore } from './useFetchMore';
import { useAuthenticatedQuery } from './useAuthenticatedQuery';

interface FetchMoreQueryResult<TData, TVariables> extends QueryResult {
	resetSkip: () => void;
	fetchMore: any;
}

type FetchMoreQueryOptions<TData, TVariables> = QueryHookOptions<TData, TVariables & { limit: number }> &
	FetchMoreOptions<TData, TVariables> & {
		fetchMoreVariables?: OperationVariables;
	};

export function useFetchMoreQuery<TData = any, TVariables = OperationVariables>(
	query: DocumentNode,
	itemCountExtractor: ItemCountExtractor<TData>,
	options: FetchMoreQueryOptions<TData, TVariables>
): FetchMoreQueryResult<TData, TVariables> {
	const { variables, fetchMoreVariables, ...restOptions } = options;

	const limit = useMemo<number>(() => variables?.limit ?? 0, [variables]);

	const queryOptions: any = useMemo(
		() => ({
			variables,
			...restOptions,
		}),
		[restOptions, variables]
	);

	const { data, fetchMore: fetchMoreRaw, loading, ...restQuery } = useAuthenticatedQuery<TData>(query, queryOptions);

	const skip = useMemo(() => itemCountExtractor(data), [data, itemCountExtractor]);

	const fetchMoreOptions = useMemo(
		() => ({
			...options,
			itemCountExtractor,
			fetchMore: fetchMoreRaw,
			skipQuery: queryOptions?.skip ?? false,
			skip,
			limit,
			variables: fetchMoreVariables,
			loading,
		}),
		[options, itemCountExtractor, fetchMoreRaw, queryOptions, skip, limit, fetchMoreVariables, loading]
	);

	const { fetchMore, resetSkip } = useFetchMore<TData, TVariables>(fetchMoreOptions);

	return { data, fetchMore, resetSkip, loading, ...restQuery };
}
