import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import moment from 'moment';
import { Episode, License, Movie, Playable, usePlayable, useProgress } from '@vodafoneis/sjonvarpskjarni-js-lib';
import { SeriesContext } from '../contexts/SeriesContext';
import { ProgressButton } from './ProgressButton';
import { UserContext } from '../contexts/UserContext';
import { LicenseButton } from './LicenseButton';
import { useNavigate } from 'react-router-dom';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import PlayIcon from '../images/play-solid.svg';
import StepBackwardsIcon from '../images/backward-step-solid.svg';
import LockIcon from '../images/lock-solid.svg';

const ContentButtonsContainer = withFocusable({ trackChildren: true })(styled.div`
	margin: 40px 0;

	button {
		margin-right: 20px;
		float: left;
	}
`);

const ContentButtonsMessage = styled.div`
	font-size: 20px;
	font-weight: bold;
	line-height: 1.2;
`;

const ProgressButtons: React.FC<{
	playable: Playable;
	playMovie: (params?: { license?: License; position?: number }) => void;
}> = ({ playable, playMovie }) => {
	const { t } = useTranslation();
	const { progress, position, hasProgress } = useProgress(playable);
	const { playEpisode } = useContext(SeriesContext);

	const playFromPosition = useCallback(() => {
		if (playable instanceof Movie || !playEpisode) {
			playMovie({ position });
		}

		if (playable instanceof Episode && playEpisode) {
			playEpisode(playable, position);
		}
	}, [playEpisode, playMovie, playable, position]);

	const playFromBeginning = useCallback(() => {
		if (playable instanceof Movie || !playEpisode) {
			playMovie();
		}

		if (playable instanceof Episode && playEpisode) {
			playEpisode(playable, 0);
		}
	}, [playEpisode, playMovie, playable]);

	const primarySubtitle = useMemo(() => {
		if (playable instanceof Episode) {
			// @ts-ignore
			return playable.episodeSeason > 0
				? `S${playable.episodeSeason} E${playable.episodeNumber}`
				: `E${playable.episodeNumber}`;
		}

		if (playable instanceof Movie && hasProgress) {
			return t('ContentButtons.FromPosition', {
				position: moment.utc(position * 1000).format('HH:mm:ss'),
			});
		}

		return null;
	}, [hasProgress, playable, position, t]);

	const secondarySubtitle = useMemo(() => {
		if (playable instanceof Episode) {
			return primarySubtitle;
		}

		if (playable instanceof Movie && hasProgress) {
			return t('ContentButtons.FromBeginningSubtitle');
		}

		return null;
	}, [hasProgress, playable, primarySubtitle, t]);

	if (!playable) return null;

	if (hasProgress) {
		return (
			<>
				<ProgressButton
					hasInitialFocus
					title={t('ContentButtons.ContinueWatching')}
					subtitle={primarySubtitle}
					progress={progress}
					icon={PlayIcon}
					onClick={playFromPosition}
				/>
				<ProgressButton
					title={playable.isMovie() ? t('ContentButtons.Watch') : t('ContentButtons.FromBeginning')}
					subtitle={secondarySubtitle}
					icon={StepBackwardsIcon}
					onClick={playFromBeginning}
				/>
			</>
		);
	}

	return (
		<>
			<ProgressButton
				hasInitialFocus
				title={t('ContentButtons.Watch')}
				subtitle={primarySubtitle}
				icon={PlayIcon}
				onClick={playFromBeginning}
			/>
		</>
	);
};

const ContentButtonsContent = withFocusable<{ playable: Playable }>({ trackChildren: true })(({ playable }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { isLoggedIn } = useContext(UserContext);
	const { licenses, isWatchable, isPlayable } = usePlayable(playable);

	const playMovie = useCallback(
		(params?: { license?: License; position?: number }) => {
			const { position } = params || {};
			if (position && position > 0) {
				navigate(`/content/${playable.id}/play/${position}`);
			} else {
				navigate(`/content/${playable.id}/play`);
			}
		},
		[navigate, playable]
	);

	return useMemo(() => {
		if (!playable || licenses === null || isWatchable === null || isPlayable === null) return null;

		// If user can watch the content.
		if (isWatchable === true) {
			return (
				<>
					<ProgressButtons playable={playable} playMovie={playMovie} />
				</>
			);
		}

		// If the content is not watchable for anonymous users.
		if (isWatchable === false && !isLoggedIn) {
			return <ProgressButton title={t('ContentButtons.Login')} icon={LockIcon} />;
		}

		// If content is not subscribed.
		if (isWatchable === false && isLoggedIn && playable.isSubscription()) {
			return <ContentButtonsMessage>{t('Movie.NotSubscribed')}</ContentButtonsMessage>;
		}

		// If content is not playable.
		if (isPlayable === false) {
			return <ContentButtonsMessage>{t('Movie.NotPlayable')}</ContentButtonsMessage>;
		}

		// If content is not available
		if (licenses.length === 0) {
			return <ContentButtonsMessage>{t('Movie.NotAvailable')}</ContentButtonsMessage>;
		}

		// If TVOD/EST without rental.
		return (
			<>
				{licenses.map((license, idx) => (
					<LicenseButton key={license.id} license={license} onClick={playMovie} hasInitialFocus={idx === 0} />
				))}
			</>
		);
	}, [playable, licenses, isWatchable, isPlayable, isLoggedIn, playMovie, t]);
});

type ContentButtonsProps = {
	playable: Playable;
	onBlur?: FocusedCallback;
	onFocus?: FocusedCallback;
};

export const ContentButtons: React.FC<ContentButtonsProps> = ({ playable, onBlur, onFocus }) => {
	return (
		<ContentButtonsContainer onBecameBlurred={onBlur} onBecameFocused={onFocus}>
			<ContentButtonsContent playable={playable} />
		</ContentButtonsContainer>
	);
};
