import { FieldPolicy, Reference } from '@apollo/client';

type KeyArgs = FieldPolicy<any>['keyArgs'];

export function skipLimitPagination<T = Reference>(keyArgs: KeyArgs = false): FieldPolicy<T[]> {
	return {
		keyArgs,
		merge(existing, incoming, { args }) {
			const merged = existing ? existing.slice() : [];
			if (args) {
				// Assume an offset of 0 if args.offset omitted.
				const { skip = 0 } = args;
				for (let i = 0; i < incoming.length; ++i) {
					merged[skip + i] = incoming[i];
				}
			} else {
				// It's unusual (probably a mistake) for a paginated field not
				// to receive any arguments, so you might prefer to throw an
				// exception here, instead of recovering by appending incoming
				// onto the existing array.
				merged.push.apply(merged, incoming.slice()); // eslint-disable-line prefer-spread
			}
			return merged;
		},
	};
}
