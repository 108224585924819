
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"UserFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"settings"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"language"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"recentlySearched"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"ageLevel"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"pinPlayContent"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"allowSubscriptions"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":138}};
    doc.loc.source = {"body":"fragment UserFragment on User {\n\tid\n\tname\n\tsettings {\n\t\tlanguage\n\t\trecentlySearched\n\t\tageLevel\n\t\tpinPlayContent\n\t\tallowSubscriptions\n\t}\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
