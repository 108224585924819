import { useMemo } from 'react';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { ApolloError, QueryHookOptions } from '@apollo/client';
import { factory } from '../models';
import type { Movie, Series, Content } from '../models';
import { useAuthenticatedQuery } from './useAuthenticatedQuery';
import { CONTENT_QUERY } from '../queries';

export type UseContentReturn = {
	loading: boolean;
	content: Movie | Series;
	refetch: () => Promise<ApolloQueryResult<Content>>;
	contentError: ApolloError;
};

export const useContent = (
	contentId: number | string,
	similarContentLimit: number,
	actorsLimit: number,
	queryOptions: QueryHookOptions<any, any> = {}
): UseContentReturn => {
	const { data, refetch, error } = useAuthenticatedQuery(CONTENT_QUERY, {
		variables: {
			contentId,
			similarContentLimit,
			actorsLimit,
		},
		...queryOptions,
	});

	const loading = useMemo(() => !!(!data || !data.content), [data]);

	const content = useMemo(() => {
		if (!data || !data.content) return null;

		return factory(data.content);
	}, [data]);

	return { loading, content, refetch, contentError: error };
};
