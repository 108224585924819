import React, { useCallback, forwardRef, PropsWithChildren, SyntheticEvent, ComponentPropsWithoutRef } from 'react';

type ButtonProps = PropsWithChildren<
	ComponentPropsWithoutRef<'button'> & {
		onClick?: (event: SyntheticEvent) => void;
		suppressHydrationWarning?: boolean;
	}
>;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	({ children, className, type, onClick, suppressHydrationWarning }, ref) => {
		const onClickCallback = useCallback(
			(event) => {
				if (type === 'button') {
					event.preventDefault();
				}

				if (onClick) {
					onClick(event);
				}
			},
			[onClick, type]
		);

		const stopPropagation = useCallback((event) => event.stopPropagation(), []);

		return (
			<button
				ref={ref}
				type={type}
				className={className}
				onClick={onClickCallback}
				onTouchEnd={stopPropagation}
				onTouchStart={stopPropagation}
				suppressHydrationWarning={suppressHydrationWarning}
			>
				{children}
			</button>
		);
	}
);

Button.defaultProps = {
	type: 'button',
	suppressHydrationWarning: false,
};
