import styled from 'styled-components';
import React from 'react';

const DataItemContainer = styled.div`
	float: left;
	margin-right: 20px;
	margin-bottom: 0;
	padding-right: 20px;

	border-right: 1px solid #666;

	&:last-child {
		border-right: none;
	}

	&:last-child {
		margin-bottom: 0;
	}
`;

const DataItemTitle = styled.span`
	margin-right: 10px;
`;

const DataItemData = styled.span`
	color: #a197a1;
`;

type ContentInfoDataItemProps = {
	title: string;
	value?: string;
	className?: string;
};

export const ContentInfoDataItem: React.FC<ContentInfoDataItemProps> = ({ title, value }) => {
	return (
		<DataItemContainer>
			<DataItemTitle>{title}</DataItemTitle>
			{value ? <DataItemData>{value}</DataItemData> : null}
		</DataItemContainer>
	);
};
