export class Entitlement {
	id?: number;
	accountId?: string;
	deviceId?: string;
	token?: string;
	created?: number;
	expires?: number;

	constructor(data: object) {
		Object.assign(this, data);
	}
}
