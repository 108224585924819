import { Content } from './Content';
import { License } from './License';
import { LicenseType } from './LicenseType';
import type { Provider } from './Provider';
import type { File } from './File';
import type { Position } from './Position';
import type { Timing } from './Timing';
import type { FileType } from './FileType';
import { MovieType } from './MovieType';

const { SVOD, CATCHUP } = LicenseType;

export class Playable extends Content {
	type?: MovieType;
	duration?: number;
	maxViewingDuration?: number;
	licenses?: License[];
	files?: File[];
	provider?: Provider;
	position?: Position;
	introTiming?: Timing;
	recapTiming?: Timing;
	creditTiming?: Timing;

	getLicensesSorted() {
		const licensePriority = License.getLicensePriority();
		const sorter = (a: License, b: License) => licensePriority.indexOf(a.type) - licensePriority.indexOf(b.type);

		if (this.licenses) {
			return this.licenses.sort(sorter);
		}

		return [];
	}

	getWatchableLicense() {
		const [firstLicense] = this.getLicensesSorted().filter(
			(license) =>
				license.isRented() ||
				(license.isType([SVOD, CATCHUP]) && license.isSubscribed()) ||
				(license.isTVOD() && license.isFree()) ||
				(license.isEST() && license.isPurchased())
		);

		return firstLicense ?? null;
	}

	getFirstLicense() {
		const [firstLicense] = this.getLicensesSorted();

		return firstLicense;
	}

	getLicense(type: LicenseType): License | null {
		return this.licenses?.find((license) => license.type === type) ?? null;
	}

	isTVOD() {
		return this.licenses?.reduce((acc, license) => acc || license.isTVOD(), false) ?? false;
	}

	isSubscription() {
		return this.licenses?.reduce((acc, license) => acc || license.isCatchup() || license.isSVOD(), false) ?? false;
	}

	isCatchup() {
		return this.licenses?.reduce((acc, license) => acc || license.isCatchup(), false) ?? false;
	}

	getProvider() {
		if (this.provider) return this.provider;

		const [firstLicense] = this.getLicensesSorted().filter((license) => license.provider);

		return firstLicense ? firstLicense.provider : null;
	}

	getProduct() {
		const [firstLicense] = this.getLicensesSorted().filter((license) => license.product);

		return firstLicense ? firstLicense.product : super.getProduct();
	}

	getFile(type: FileType) {
		return this.files?.filter((file) => file.isOfType(type))[0] ?? null;
	}

	getPosition() {
		return this.position?.value ?? 0;
	}

	getViewingProgress() {
		const position = this.getPosition();

		if (position < 30) return 0;

		if (this.isConsideredFinished()) return 1;

		const duration = this.duration * 60; // in seconds
		return position / duration;
	}

	isConsideredFinished() {
		const position = this.getPosition();

		if (position === 0 || !this.duration) return false;

		if (this.creditTiming?.start > 0) {
			return position >= this.creditTiming.start;
		}

		const isConsideredFinishedEndOffset = this.isEpisode() ? 60 : 5 * 60;

		return position >= this.duration * 60 - isConsideredFinishedEndOffset;
	}

	getStatisticsData() {
		const provider = this.getProvider();

		return {
			itemId: this.id,
			itemTitle: this.title,
			itemType: this.type,
			itemDuration: this.duration * 60,
			...(provider
				? {
						providerId: provider.id,
						providerTitle: provider.title,
						providerType: provider.type,
				  }
				: {}),
			creditsPosition: this.creditTiming?.start ?? null,
		};
	}
}
