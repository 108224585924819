import { useMemo } from 'react';
import { ApolloError, QueryHookOptions } from '@apollo/client';
import { UPCOMING_LIVE } from '../queries';
import { useFetchMoreQuery } from './useFetchMoreQuery';
import { Event, factory } from '../models';
import { FetchMoreCallback } from './useFetchMore';

const itemCountExtractor = (data: any) => data?.live?.length ?? null;

type UseUpcomingLiveProps = {
	limit: number;
	channelIds?: string[];
	filter?: string[];
	queryOptions?: QueryHookOptions<any, any>;
};

type UseUpcomingLiveReturn = {
	events: Event[];
	fetchMoreEvents: FetchMoreCallback;
	loadingEvents: boolean;
	eventsError: ApolloError;
};

export const useUpcomingLive = ({
	limit,
	channelIds,
	filter,
	queryOptions,
}: UseUpcomingLiveProps): UseUpcomingLiveReturn => {
	const options = useMemo(
		() => ({
			variables: {
				skip: 0,
				limit,
				channelIds,
				filter,
			},
			...(queryOptions ?? {}),
		}),
		[channelIds, filter, limit, queryOptions]
	);

	const { data, fetchMore, loading, error } = useFetchMoreQuery(UPCOMING_LIVE, itemCountExtractor, options);

	const events = useMemo(() => (data?.live ? data?.live.map((event: any) => factory(event)) : null), [data]);

	return { events, fetchMoreEvents: fetchMore, loadingEvents: loading, eventsError: error };
};
