import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { FetchPolicy } from '@apollo/client';
import { Episode, Series, useNextEpisode, useEpisodes } from '@vodafoneis/sjonvarpskjarni-js-lib';
import { useNavigate, useParams } from 'react-router-dom';

export type SeriesSceneContextProps = {
	series?: Series;
	episodes: Episode[];
	nextEpisode?: Episode;
	selectedSeason?: number;
	seasonPickerVisible: boolean;
	setSelectedSeason: (selectedSeasonNumber: number) => void;
	playEpisode: (episode: Episode, position?: number) => void;
	showSeasonPicker: () => void;
	hideSeasonPicker: () => void;
};

const episodesQueryOptions = {
	fetchPolicy: 'cache-and-network' as FetchPolicy,
};

export const SeriesContext = createContext<SeriesSceneContextProps>({
	series: undefined,
	episodes: [],
	nextEpisode: undefined,
	selectedSeason: undefined,
	seasonPickerVisible: false,
	setSelectedSeason: () => {},
	playEpisode: () => {},
	showSeasonPicker: () => {},
	hideSeasonPicker: () => {},
});

type SeriesContextProviderProps = {
	series: Series;
};

const getValidSeason = (series: Series, preferredSeasonNumber?: number | string): number => {
	const seasons = series?.seasons ?? [];

	// Return unknown season if there are no seasons
	if (seasons.length === 0) return 0;

	// If the season number is specified from params return it if a corresponding season exists
	if (preferredSeasonNumber !== null && preferredSeasonNumber !== undefined) {
		const seasonExists = seasons.find(({ number }) => number === Number(preferredSeasonNumber));
		if (seasonExists) {
			return Number(preferredSeasonNumber);
		}
	}

	// If there is next episode specified, return its season
	if (series.nextEpisode) return series.nextEpisode.episodeSeason as number;

	// Return the first season
	return seasons[0].number as number;
};

export const SeriesContextProvider: React.FC<SeriesContextProviderProps> = ({ series, children }) => {
	const params = useParams();

	const [selectedSeason, setSelectedSeason] = useState<number>(getValidSeason(series, params?.seasonNumber));
	const [seasonPickerVisible, setSeasonPickerVisible] = useState(false);
	const navigate = useNavigate();

	const showSeasonPicker = useCallback(() => setSeasonPickerVisible(true), []);
	const hideSeasonPicker = useCallback(() => setSeasonPickerVisible(false), []);

	useEffect(() => {
		if (params.seasonNumber) {
			setSelectedSeason(getValidSeason(series, params?.seasonNumber));
		}
	}, [params.seasonNumber, series]);

	const { episodes } = useEpisodes(series.id as number, selectedSeason, episodesQueryOptions);

	const nextEpisode = useNextEpisode(series, episodes);

	const playEpisode = useCallback(
		(episode: Episode, position?: number) => {
			if (episode.isTVOD() || (episode.isSubscription() && !episode.getWatchableLicense())) {
				navigate(`/content/${episode.id}`);
			} else if (position && position > 0) {
				navigate(`/content/${episode.id}/play/${position}`);
			} else {
				navigate(`/content/${episode.id}/play`);
			}
		},
		[navigate]
	);

	const value = useMemo(() => {
		return {
			series,
			episodes,
			nextEpisode,
			selectedSeason,
			seasonPickerVisible,
			setSelectedSeason,
			playEpisode,
			showSeasonPicker,
			hideSeasonPicker,
		};
	}, [
		series,
		episodes,
		nextEpisode,
		seasonPickerVisible,
		selectedSeason,
		playEpisode,
		showSeasonPicker,
		hideSeasonPicker,
	]);

	return <SeriesContext.Provider value={value}>{children}</SeriesContext.Provider>;
};
