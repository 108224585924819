import { ApolloError, QueryHookOptions } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { Channel, factory } from '../models';
import { useAuthenticatedQuery } from './useAuthenticatedQuery';
import { CHANNELS_QUERY } from '../queries';

type UseChannelsReturn = {
	channels: Channel[];
	channelsLoading: boolean;
	refetchChannels: any;
	channelsError: ApolloError;
};

export const useChannels = (queryOptions: QueryHookOptions<any, any> = {}): UseChannelsReturn => {
	const options: QueryHookOptions = useMemo(() => queryOptions, [queryOptions]);

	const { data, loading, refetch, error } = useAuthenticatedQuery(CHANNELS_QUERY, options);

	const channels = useMemo(() => data?.channels?.map((channel: any) => factory(channel)) ?? [], [data]);

	const refetchChannels = useCallback(async () => {
		try {
			await refetch();
		} catch (exception) {
			// Do nothing
		}
	}, [refetch]);

	return { channels, channelsLoading: loading, refetchChannels, channelsError: error };
};
