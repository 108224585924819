import { THEOplayerError } from 'theoplayer';
import { PLAYER_EVENTS } from '../player/THEOPlayer';
import { useCallback, useEffect, useRef } from 'react';
import { ProgressInfo } from '../contexts/PlaybackContext';
import { usePlayer } from '../contexts/PlayerContext';

const { PLAYING, PAUSE, PLAY, WAITING, TIME_UPDATE, ERROR, CAN_PLAY, SEEKED, SEEKING, ENDED, DESTROY } = PLAYER_EVENTS;

export type PlaybackEvents = {
	onPlaybackStarted?: () => void;
	onPlaybackBuffering?: () => void;
	onSeekFinished?: (position: number) => void;
	onPlaybackPaused?: () => void;
	onPlaybackRequested?: () => void;
	onPlaybackInitialized?: () => void;
	onPlaybackStopped?: () => void;
	onPlaybackError?: (data: { errorObject: THEOplayerError; date: Date; type: string }) => void;
	onPlaybackProgress?: (progress: ProgressInfo) => void;
};

export const usePlaybackEvents = (playbackEvents: PlaybackEvents) => {
	const {
		onPlaybackError,
		onPlaybackBuffering,
		onPlaybackInitialized,
		onPlaybackPaused,
		onPlaybackRequested,
		onPlaybackStarted,
		onPlaybackStopped,
		onPlaybackProgress,
		onSeekFinished,
	} = playbackEvents;

	const initialized = useRef(false);

	const { generateEventListenerEffect, getPosition, getDuration, getCurrentProgramDateTime } = usePlayer();

	useEffect(
		() => generateEventListenerEffect(PLAYING, onPlaybackStarted),
		[onPlaybackStarted, generateEventListenerEffect]
	);
	useEffect(
		() => generateEventListenerEffect(SEEKING, onPlaybackBuffering),
		[onPlaybackBuffering, generateEventListenerEffect]
	);
	useEffect(
		() => generateEventListenerEffect(PAUSE, onPlaybackPaused),
		[onPlaybackPaused, generateEventListenerEffect]
	);
	useEffect(
		() => generateEventListenerEffect(PLAY, onPlaybackRequested),
		[onPlaybackRequested, generateEventListenerEffect]
	);
	useEffect(
		() => generateEventListenerEffect(WAITING, onPlaybackBuffering),
		[onPlaybackBuffering, generateEventListenerEffect]
	);
	useEffect(
		() => generateEventListenerEffect(ENDED, onPlaybackStopped),
		[onPlaybackStopped, generateEventListenerEffect]
	);

	useEffect(
		() => generateEventListenerEffect(DESTROY, onPlaybackStopped),
		[onPlaybackStopped, generateEventListenerEffect]
	);

	useEffect(() => generateEventListenerEffect(ERROR, onPlaybackError), [onPlaybackError, generateEventListenerEffect]);

	const onTimeUpdate = useCallback(() => {
		onPlaybackProgress?.({
			position: getPosition(),
			duration: getDuration(),
			currentProgramDateTime: getCurrentProgramDateTime(),
		});
	}, [getCurrentProgramDateTime, getDuration, getPosition, onPlaybackProgress]);

	useEffect(() => generateEventListenerEffect(TIME_UPDATE, onTimeUpdate), [onTimeUpdate, generateEventListenerEffect]);

	const onSeeked = useCallback(() => {
		onSeekFinished?.(getPosition());
	}, [getPosition, onSeekFinished]);

	useEffect(() => generateEventListenerEffect(SEEKED, onSeeked), [onSeeked, generateEventListenerEffect]);

	const onCanPlay = useCallback(() => {
		if (!initialized.current) {
			initialized.current = true;

			onPlaybackInitialized?.();
		}
	}, [onPlaybackInitialized]);

	useEffect(() => generateEventListenerEffect(CAN_PLAY, onCanPlay), [onCanPlay, generateEventListenerEffect]);
};
