import React, { createContext, useMemo } from 'react';
import { Client } from '../api';

type APIContextType = {
	client: Client;
};

export const APIContext = createContext<APIContextType>({
	client: null,
});

type APIContextProviderProps = {
	client: Client;
};

export const APIContextProvider: React.FC<APIContextProviderProps> = (props) => {
	const { client, children } = props;

	const value = useMemo(() => ({ client }), [client]);

	return <APIContext.Provider value={value}>{children}</APIContext.Provider>;
};
