import { Content } from './Content';
import type { Image } from './Image';
import type { Season } from './Season';
import { generateImagePath, ImagePathParams } from '../utils';
import { Episode } from './Episode';

export class Series extends Content {
	ageRating?: number;
	hopsterImage?: Image;
	seasons?: Season[];
	nextEpisode?: Episode;
	episodeCount?: number;

	getHopsterImageUrl(params: ImagePathParams = {}) {
		if (this.hopsterImage) {
			return generateImagePath({
				hash: this.hopsterImage.hash,
				ext: 'png',
				...params,
			});
		}

		return null;
	}
}
