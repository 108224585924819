import { ClientResponse } from '../api';
import { Exception } from '../exceptions';

export class KeyedObjectToModelInterceptor {
	modelMap: { [key: string]: any };

	constructor(modelMap: { [key: string]: any }) {
		if (!modelMap || Object.keys(modelMap).length <= 0) {
			throw new Exception('KeyedObjectToModelInterceptor requires modelMap');
		}
		this.modelMap = modelMap;
	}

	async response(response: ClientResponse) {
		Object.keys(this.modelMap).forEach(key => {
			if (response.data[key]) {
				response.data[key] = new this.modelMap[key](response.data[key]);
			}
		});
	}
}
