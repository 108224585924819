import { useNavigate, useParams } from 'react-router-dom';
import { useCategory } from '@vodafoneis/sjonvarpskjarni-js-lib';
import { CONTENT_LIMIT } from '../config/constants';
import styled from 'styled-components';
import { Grid } from '../components/Grid';
import { useContentRenderer } from '../hooks/useContentRenderer';
import { useCallback, useMemo } from 'react';
import { Container } from '../components/Container';
import { BackgroundImage } from '../components/BackgroundImage';
import logo from '../images/logo.png';
import { useOnBack } from '../hooks/useOnBack';
import { CONTENT_MARGIN, getPageSplit, SIDE_MARGINS } from '../config/layout';

const TitleWrapper = styled.div`
	margin: 50px ${SIDE_MARGINS}px 30px ${SIDE_MARGINS}px;
	display: flex;
	align-items: center;
`;

const Title = styled.div`
	font-size: 45px;
`;

const TitleLogo = styled.div`
	width: 89px;
	height: 52px;
	background-image: url(${logo});
	background-repeat: no-repeat;
	background-size: 100% auto;
	margin-right: 30px;
`;

const GridScreenGrid = styled(Grid)`
	height: 900px;
	padding-top: 20px;
`;

export const CategoryGridScreen = () => {
	const params = useParams<{ categoryId: string }>();

	const navigate = useNavigate();

	const { category, fetchMoreContent } = useCategory(Number(params.categoryId), CONTENT_LIMIT);

	const moduleType = category?.moduleType;

	const renderContent = useContentRenderer({ moduleType });

	useOnBack();

	const onItemSelected = useCallback(
		({ item }) => {
			navigate(`/content/${item.id}`);
		},
		[navigate]
	);

	const columns = useMemo(() => getPageSplit(moduleType), [moduleType]);

	return (
		<BackgroundImage>
			<TitleWrapper>
				<TitleLogo />
				<Title>{category?.title}</Title>
			</TitleWrapper>
			<Container>
				<GridScreenGrid
					items={category?.content ?? []}
					onItemSelected={onItemSelected}
					onEndReached={fetchMoreContent}
					hasInitialFocus
					renderItem={renderContent}
					gutter={CONTENT_MARGIN}
					columns={columns}
				/>
			</Container>
		</BackgroundImage>
	);
};
