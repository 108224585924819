import { useEffect } from 'react';
import { usePrevious } from 'react-use';

export const useOnChangeCallback = <T = any>(onChangedCallback: (prevValue: T | undefined) => void, value: T) => {
	const prevValue = usePrevious(value);

	useEffect(() => {
		if (value !== prevValue) {
			onChangedCallback(prevValue);
		}
	}, [onChangedCallback, prevValue, value]);
};
