import type { Image } from './Image';
import { generateImagePath, ImagePathParams } from '../utils/ImageUtils';

export class Product {
	id?: number;
	subscriptionProduct?: Product;
	title?: string;
	subtitle?: string;
	description?: string;
	terms?: string;
	leadText?: string;
	successText?: string;
	price?: number;
	color?: string;
	startTime?: Date;
	endTime?: Date;
	subscribed?: boolean;
	iconImage?: Image;
	backdropImage?: Image;
	moduleImage?: Image;

	constructor(data: any) {
		Object.assign(this, data);

		const { startTime, endTime } = data;

		if (startTime) this.startTime = new Date(startTime * 1000);

		if (endTime) this.endTime = new Date(endTime * 1000);
	}

	getIconImageUrl(params: ImagePathParams = {}) {
		if (this.iconImage) {
			return generateImagePath({
				hash: this.iconImage.hash,
				ext: 'png',
				...params,
			});
		}

		return null;
	}

	getBackdropImageUrl(params: ImagePathParams = {}) {
		if (this.backdropImage) {
			return generateImagePath({
				hash: this.backdropImage.hash,
				...params,
			});
		}

		return null;
	}

	getModuleImageUrl(params: ImagePathParams = {}) {
		if (this.moduleImage) {
			return generateImagePath({
				hash: this.moduleImage.hash,
				...params,
			});
		}

		return null;
	}
}
