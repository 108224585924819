import { useContext, useMemo } from 'react';
import { QueryContext } from '../contexts';

export const useQueryHeaders = (extraHeaders: { [key: string]: string } = {}) => {
	const { accessToken, language } = useContext(QueryContext);

	return useMemo(() => {
		let headers = { ...extraHeaders };

		if (accessToken) {
			headers = { ...headers, Authorization: `Bearer ${accessToken}` };
		}

		if (language) {
			headers = { ...headers, 'Accept-Language': language };
		}

		return headers;
	}, [extraHeaders, accessToken, language]);
};
