import { useMemo } from 'react';

type ExtraClassNames = {
	[key: string]: any;
};

export function useClassNames(defaultClassNames?: string, extraClassNames: ExtraClassNames = {}) {
	return useMemo(() => {
		const enabledClassNames = Object.keys(extraClassNames).filter(key => !!key && !!extraClassNames[key]);

		if (defaultClassNames && defaultClassNames.length > 0) {
			enabledClassNames.push(defaultClassNames);
		}

		return enabledClassNames.join(' ');
	}, [defaultClassNames, extraClassNames]);
}
