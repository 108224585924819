import React, { useEffect } from 'react';
import styled from 'styled-components';
import { PROGRESS_BACKGROUND, PROGRESS_FOREGROUND } from '../styles/colors';
import { FocusableButtonContainer, ButtonProps, ButtonSubtitle, ButtonTitle } from './Button';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';

const ProgressButtonIcon = styled.img`
	margin-right: 20px;
	height: 30px;
	width: 30px;
`;

const ButtonTitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

const ProgressButtonContainer = styled(FocusableButtonContainer)`
	position: relative;
	border: none;
	overflow: hidden;
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const ButtonProgressContainer = styled.div`
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	background: ${PROGRESS_BACKGROUND};
`;

type ButtonProgressProps = {
	progress?: number;
	icon?: any;
};

const ButtonProgress = styled.div<ButtonProgressProps>`
	width: ${({ progress }) => (progress ? Math.round(progress * 100) : 0)}%;
	height: 6px;
	background: ${PROGRESS_FOREGROUND};
`;

export const ProgressButton = withFocusable<ButtonProps & ButtonProgressProps>()(
	({ title, subtitle, progress, type, onClick, icon, hasInitialFocus, focused, stealFocus }) => {
		useEffect(() => {
			if (hasInitialFocus) {
				stealFocus();
			}
		}, [hasInitialFocus, stealFocus]);

		return (
			<ProgressButtonContainer type={type} onEnterPress={onClick} withSubtitle={!!subtitle} focused={focused}>
				{icon && <ProgressButtonIcon className={'icon'} src={icon} />}
				<ButtonTitleContainer>
					<ButtonTitle withSubtitle={!!subtitle}>{title}</ButtonTitle>
					<ButtonSubtitle withSubtitle={!!subtitle}>{subtitle}</ButtonSubtitle>
				</ButtonTitleContainer>
				{progress !== undefined && (
					<ButtonProgressContainer>
						<ButtonProgress progress={progress} />
					</ButtonProgressContainer>
				)}
			</ProgressButtonContainer>
		);
	}
);
