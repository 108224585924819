import { useContext } from 'react';
import { APIContext } from '../contexts';
import { Exception } from '../exceptions';

export const useApiClient = () => {
	const { client } = useContext(APIContext);

	if (!client) {
		throw new Exception(
			'No API client found in useApiClient. Are you sure the usage is wrapped in an APIContextProvider?'
		);
	}

	return client;
};
