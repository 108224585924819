import deepmerge, { Options } from 'deepmerge';

const combineMerge = (target: any[], source: any[], options?: Options) => {
	const destination = target.slice();

	source.forEach((item, index) => {
		const targetIndex = item.id ? target.findIndex(targetItem => targetItem.id === item.id) : index;
		if (targetIndex >= 0) {
			destination[index] = deepmerge(target[targetIndex], item, options);
		} else {
			destination.push(item);
		}
	});

	return destination;
};

export function merge<T>(x: Partial<T>, y: Partial<T>, options?: Options): T {
	return deepmerge<T>(x, y, {
		...options,
		arrayMerge: combineMerge,
	});
}
