import React, { createContext, useCallback, useContext, useMemo, useRef } from 'react';
import THEOPlayer, { PLAYER_EVENTS } from '../player/THEOPlayer';
import { TypedSource } from 'theoplayer';

type PlayerContextProps = {
	play: () => void;
	seek: (position: number) => void;
	pause: () => void;
	stop: () => void;
	togglePausePlay: () => void;
	setPlayerView: (playerView: HTMLDivElement) => void;
	setSources: (sources: TypedSource[]) => void;
	generateEventListenerEffect: (playerEvent: PLAYER_EVENTS, callback: any) => (() => void) | void;
	getPosition: () => number;
	getDuration: () => number;
	getCurrentProgramDateTime: () => Date | null;
};

export const PlayerContext = createContext<PlayerContextProps>({
	play: () => {},
	seek: () => {},
	pause: () => {},
	stop: () => {},
	togglePausePlay: () => {},
	setPlayerView: () => {},
	setSources: () => {},
	generateEventListenerEffect: () => {},
	getPosition: () => 0,
	getDuration: () => 0,
	getCurrentProgramDateTime: () => null,
});

export const usePlayer = () => useContext(PlayerContext);

export const PlayerContextProvider: React.FC = ({ children }) => {
	const player = useRef<THEOPlayer>();

	const setPlayerView = useCallback((playerView: HTMLDivElement) => {
		if (!player.current) {
			player.current = new THEOPlayer(playerView);
			player.current.setAutoplay(true);
		}
	}, []);

	const stop = useCallback(() => {
		player.current?.stop();
	}, []);

	const play = useCallback(() => {
		player.current?.play();
	}, []);

	const pause = useCallback(() => {
		player.current?.pause();
	}, []);

	const togglePausePlay = useCallback(() => {
		player.current?.togglePausePlay();
	}, []);

	const seek = useCallback((position: number) => {
		player.current?.seekToPosition(position);
	}, []);

	const setSources = useCallback((sources) => {
		player.current?.setSources(sources);
	}, []);

	const generateEventListenerEffect = useCallback(
		(playerEvent: PLAYER_EVENTS, callback: any) => {
			if (callback && player.current) {
				const playerInstance = player.current;
				playerInstance.addEventListener(playerEvent, callback);

				return () => {
					playerInstance.removeEventListener(playerEvent, callback);
				};
			}
		},
		[player]
	);

	const getPosition = useCallback(() => {
		return player.current?.getPosition() ?? 0;
	}, []);

	const getDuration = useCallback(() => {
		return player.current?.getDuration() ?? 0;
	}, []);

	const getCurrentProgramDateTime = useCallback(() => {
		return player.current?.getCurrentProgramDateTime() ?? null;
	}, []);

	const value = useMemo(
		() => ({
			play,
			pause,
			togglePausePlay,
			stop,
			seek,
			setPlayerView,
			setSources,
			generateEventListenerEffect,
			getPosition,
			getDuration,
			getCurrentProgramDateTime,
		}),
		[generateEventListenerEffect, getCurrentProgramDateTime, getDuration, getPosition, pause, play, seek, setPlayerView, setSources, stop, togglePausePlay]
	);

	return <PlayerContext.Provider value={value}>{children}</PlayerContext.Provider>;
};
