import styled from 'styled-components';
import { ButtonTitle, FocusableButtonContainer } from './Button';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import React, { useContext, useEffect, useMemo } from 'react';
import { SeriesContext } from '../contexts/SeriesContext';
import CaretDownIcon from '../images/caret-down-solid.svg';
import { useTranslation } from 'react-i18next';
import { SeasonsPickerModal } from './SeasonsPickerModal';
import { usePrevious } from 'react-use';

const ProgressButtonIcon = styled.img`
	margin-right: 20px;
	height: 30px;
	width: 30px;
`;

const TitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

const SeasonsPickerContainer = styled(FocusableButtonContainer)`
	position: relative;
	border: none;
	overflow: hidden;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 20px;
`;

export const SeasonPicker = withFocusable()(({ focused, stealFocus }) => {
	const { t } = useTranslation();
	const { selectedSeason, seasonPickerVisible, showSeasonPicker } = useContext(SeriesContext);

	const prevSeasonPickerVisible = usePrevious(seasonPickerVisible);

	useEffect(() => {
		if (!seasonPickerVisible && prevSeasonPickerVisible) {
			stealFocus();
		}
	}, [prevSeasonPickerVisible, seasonPickerVisible, stealFocus]);

	const seasonTitle = useMemo(() => {
		if (selectedSeason === null || selectedSeason === undefined) return null;

		if (selectedSeason === 0) return t('Series.UnknownSeason');

		return t('Series.Season', { episodeSeason: selectedSeason });
	}, [selectedSeason, t]);

	if (!seasonTitle) return null;

	return (
		<>
			<SeasonsPickerContainer withSubtitle={false} onEnterPress={showSeasonPicker} focused={focused}>
				<TitleContainer>
					<ButtonTitle withSubtitle={false}>{seasonTitle}</ButtonTitle>
				</TitleContainer>
				<ProgressButtonIcon className={'icon'} src={CaretDownIcon} />
			</SeasonsPickerContainer>
			{seasonPickerVisible ? <SeasonsPickerModal /> : null}
		</>
	);
});
