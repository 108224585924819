import { Request } from '@vodafoneis/sjonvarpskjarni-js-lib';
import APIClient from './APIClient';

export const detectDeviceType = () => {
	if (typeof window.tizen !== 'undefined') {
		return 'TIZEN';
	}

	if (typeof window.webos !== 'undefined') {
		return 'WEBOS';
	}

	return 'SMARTTV';
};

export const checkPin = async (pin: string) => {
	try {
		const checkPinRequest = new Request('pin/$$pin$$', {
			pathVariables: {
				pin,
			},
		});

		return await APIClient.get(checkPinRequest);
	} catch (exception) {
		return null;
	}
};

export const requestLoginPIN = async ({ deviceId }: { deviceId: string }) => {
	try {
		const device = {
			id: deviceId,
			type: 'PC',
			model: detectDeviceType(),
		};

		const pinRequest = new Request('pin', {
			body: {
				device,
			},
		});

		const response = await APIClient.post(pinRequest);

		if (response.isSuccess() && response.data && response.data.pin) {
			return response.data.pin;
		}
	} catch (exception) {}

	return null;
};
