import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

const ExpandableTextContent = styled.div<{ numberOfLines?: number }>`
	text-overflow: ellipsis;
	overflow: hidden;
	display: ${(props) => (props.numberOfLines ? '-webkit-box !important' : 'block')};
	-webkit-line-clamp: ${(props) => props.numberOfLines};
	-webkit-box-orient: vertical;
	white-space: normal;
`;

export const ExpandableText: React.FC<{ numberOfLines: number }> = ({ children, numberOfLines }) => {
	const [expanded, setExpanded] = useState(false);

	const onClick = useCallback(
		(e) => {
			e.preventDefault();
			setExpanded(!expanded);
		},
		[expanded]
	);

	return (
		<ExpandableTextContent onClick={onClick} numberOfLines={!expanded ? numberOfLines : undefined}>
			{children}
		</ExpandableTextContent>
	);
};
