import type { Movie, Product, License } from './index';

export class Purchase {
	id?: number;
	purchasedAt?: Date;
	movie?: Movie;
	license?: License;
	product?: Product;

	constructor(data: any) {
		Object.assign(this, data);

		if (data.purchasedAt) {
			this.purchasedAt = new Date(data.purchasedAt * 1000);
		}
	}
}
