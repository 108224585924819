import styled from 'styled-components';
import { ellipsis } from '../../styles/ellipsis';
import React, { useMemo } from 'react';
import { AgeRating } from './AgeRating';
import { Content, Episode } from '@vodafoneis/sjonvarpskjarni-js-lib';

const ContentInfoTitleContainer = styled.div`
	display: flex;
	align-items: center;
	overflow: hidden;
`;

const Title = styled.h1`
	font-size: 54px;
	font-weight: normal;
	margin: 0;
	${ellipsis};
`;

type ContentInfoTitleProps = {
	content: Content;
};

export const ContentInfoTitle: React.FC<ContentInfoTitleProps> = ({ content }) => {
	const title = useMemo(() => {
		if (content instanceof Episode) {
			return `${content?.series?.title} - ${content?.title}`;
		}

		return content?.title ?? null;
	}, [content]);

	return (
		<ContentInfoTitleContainer>
			<Title>{title}</Title>
			<AgeRating
				// @ts-ignore
				ageRating={content?.ageRating}
			/>
		</ContentInfoTitleContainer>
	);
};
