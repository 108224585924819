import React, { createContext, useCallback, useEffect, useMemo } from 'react';
import {
	AnalyticsEvent,
	BearerAuthInterceptor,
	ClientResponse,
	QueryContextProvider,
	UUID,
} from '@vodafoneis/sjonvarpskjarni-js-lib';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { useEffectOnce, useLocalStorage, usePrevious } from 'react-use';
import APIClient from '../api/APIClient';
import { useApolloClient } from '@apollo/client';
import { identify, track } from '../utils/analytics';

type User = {
	id: string;
	name: string;
	username: string;
	accessToken: string;
};

type Device = {
	id: string;
	name: string;
};

type UserContextProps = {
	user?: User | null;
	setUser: (user: User | null) => void;
	device?: Device | null;
	setDevice: (device: Device | null) => void;
	deviceId?: string | null;
	isLoggedIn: boolean;
	logout: () => void;
};

export const UserContext = createContext<UserContextProps>({
	user: null,
	setUser: (user: User | null) => {},
	device: null,
	setDevice: (device: Device | null) => {},
	deviceId: null,
	isLoggedIn: false,
	logout: () => {},
});

const { LOGOUT } = AnalyticsEvent;

export const UserContextProvider: React.FC = ({ children }) => {
	const [user, setUser] = useLocalStorage<User | null>('vfi_user');
	const [device, setDevice] = useLocalStorage<Device | null>('vfi_device');
	const [deviceId, setDeviceId] = useLocalStorage<string>('vfi_device_id');

	const prevUser = usePrevious(user);

	const apolloClient = useApolloClient();

	useEffect(() => {
		if (user === prevUser) return;

		// Reset the store when the user changes
		(async () => {
			try {
				await apolloClient.resetStore();
			} catch (exception) {} // Ignore error
		})();
	}, [apolloClient, prevUser, user]);

	const logout = useCallback(async () => {
		setUser(null);
		setDevice(null);
		track(LOGOUT);
	}, [setDevice, setUser]);

	useEffect(() => {
		(async () => {
			if (!deviceId) {
				const fp = await FingerprintJS.load();
				const data = await fp.get();
				const seeds = [data.visitorId];
				seeds.push(new Date().toDateString());
				seeds.push(new Date().toTimeString());
				setDeviceId(UUID.fromString(seeds.join('')));
			}
		})();
	}, [deviceId, setDeviceId]);

	useEffect(() => {
		if (prevUser !== user) {
			identify(user);
		}
	}, [prevUser, user]);

	// Add unauthorized interceptor to API client
	useEffectOnce(() => {
		const interceptor = {
			response: (response: ClientResponse<any>) => {
				if (response.statusCode === 401) {
					setUser(null);
					setDevice(null);
				}
			},
		};

		APIClient.addInterceptor(interceptor);

		return () => {
			APIClient.removeInterceptor(interceptor);
		};
	});

	// Add authentication interceptor to API client
	useEffect(() => {
		let bearerAuthInterceptor: BearerAuthInterceptor | null = null;

		if (user?.accessToken) {
			bearerAuthInterceptor = new BearerAuthInterceptor(user.accessToken);
			APIClient.addInterceptor(bearerAuthInterceptor);
		}

		return () => {
			if (bearerAuthInterceptor) {
				APIClient.removeInterceptor(bearerAuthInterceptor);
			}
		};
	}, [user]);

	const value = useMemo(
		() => ({
			user,
			setUser,
			device,
			setDevice,
			deviceId,
			isLoggedIn: !!user,
			logout,
		}),
		[device, deviceId, setDevice, setUser, user, logout]
	);

	return (
		<UserContext.Provider value={value}>
			<QueryContextProvider accessToken={user?.accessToken ?? null} language={'is'}>
				{children}
			</QueryContextProvider>
		</UserContext.Provider>
	);
};
