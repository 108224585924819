import { useCallback } from 'react';
import { useKey } from 'react-use';

export enum SamsungKey {
	Back = 'Back',
	MediaPlayPause = 'MediaPlayPause',
	MediaRewind = 'MediaRewind',
	MediaFastForward = 'MediaFastForward',
	MediaPlay = 'MediaPlay',
	MediaPause = 'MediaPause',
	MediaStop = 'MediaStop',
}

const keysToRegister = Object.values(SamsungKey);

export const registerKeys = () => {
	if (!window.tizen) return;

	const supportedKeys = window.tizen.tvinputdevice.getSupportedKeys().map(key => key.name);
	const supportedKeysToRegister = keysToRegister.filter((key) => supportedKeys.includes(key));

	if (window.tizen.tvinputdevice.registerKeyBatch) {
		window.tizen.tvinputdevice.registerKeyBatch(supportedKeysToRegister);
	} else {
		supportedKeysToRegister.forEach((key) => window.tizen.tvinputdevice.registerKey(key));
	}
};

export const useSamsungKeyHandler = (key: SamsungKey, callback: () => void) => {
	const keyFilter = useCallback(
		({ keyCode }: KeyboardEvent) => {
			return keyCode === window.tizen?.tvinputdevice?.getKey(key)?.code;
		},
		[key]
	);

	useKey(keyFilter, callback, { event: 'keydown' }, [callback]);
};
