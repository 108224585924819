import { useMemo } from 'react';
import { Timing, TimingType } from '../@types/graphqltypes';

const END_TIMING_OFFSET = 2;

export function useActiveTiming(
	timings: Timing[],
	position: number,
	duration: number,
	hasNextItem: boolean
): Timing | null {
	return useMemo(() => {
		if (!timings) return null;

		const activeTiming = timings.find(timing => {
			if (!timing) return false;

			const { start, end, type } = timing;

			// Don't use credits timing if there is no next item
			if (!hasNextItem && type === TimingType.Credits) return false;

			// We require both start and end to be defined for timings
			if (!Number.isInteger(start) || !Number.isInteger(end)) return false;

			// Timing has not started
			if (position <= start) return false;

			// Timing is valid until end of playback
			if (end === -1) return true;

			// Timing is between start and end time
			return position < end - END_TIMING_OFFSET;
		});

		if (activeTiming) {
			return activeTiming;
		}

		// Default credits timing 30 seconds from end of playback
		if (hasNextItem && duration > 30 && duration - position < 30) {
			return {
				type: TimingType.Credits,
				start: duration - 30,
				end: -1,
			};
		}

		// No timing active
		return null;
	}, [duration, hasNextItem, position, timings]);
}
