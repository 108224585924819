import { useKey } from 'react-use';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { SamsungKey } from './useSamsungKeyHandler';

const keyFilter = ({ keyCode, which, key }: KeyboardEvent) => {
	const code = window.event ? keyCode : which;

	// Webos back key
	if (window.webos && code === 461) return true;

	// Tizen back key
	if (window.tizen && code === window.tizen.tvinputdevice.getKey(SamsungKey.Back)?.code) return true;

	// Fallback to backspace
	return key === 'Backspace';
};

export const useOnBack = (callback?: () => boolean | void | Promise<boolean | void>) => {
	const navigate = useNavigate();

	const onBackPressed = useCallback(async () => {
		const backHandled = callback && (await callback());
		if (!backHandled) {
			navigate(-1);
		}
	}, [callback, navigate]);

	useKey(keyFilter, onBackPressed, { event: 'keydown' }, [onBackPressed]);
};
