import { ClientResponse } from '../api';
import { ClientInterceptor } from '.';
import { Exception } from '../exceptions';

export class ArrayToModelInterceptor implements ClientInterceptor {
	model: any;

	constructor(model: any) {
		if (!model) throw new Exception('ArrayToModelInterceptor requires model');
		this.model = model;
	}

	async response(response: ClientResponse) {
		if (response.data) {
			// eslint-disable-next-line new-cap
			response.data = response.data.map((data: any) => (data ? new this.model(data) : null));
		} else {
			response.data = [];
		}
	}
}
