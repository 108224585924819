import React, { useCallback, useMemo, useRef } from 'react';
import { ImagePreset, Series, useContent } from '@vodafoneis/sjonvarpskjarni-js-lib';
import styled from 'styled-components';
import heroOverlay from '../images/hero-overlay.png';
import logo from '../images/logo.png';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { Button } from './Button';
import { useNavigate } from 'react-router-dom';
import { CurrentUser } from './CurrentUser';
import { useTranslation } from 'react-i18next';
import { SIDE_MARGINS } from '../config/layout';

const HeroContainer = styled.div`
	width: 100%;
	height: 1080px;
	position: relative;
	transition: transform 300ms ease-in-out;
`;

const HeroBackgroundOverlay = styled.div`
	background-image: url(${heroOverlay});
	background-repeat: no-repeat;
	background-size: 100% auto;
`;

const HeroBackground = styled.div<{ imageUrl?: string }>`
	background-image: url(https://varnish.tv.c.is${(props) => props.imageUrl});
	background-repeat: no-repeat;
	background-size: 100% auto;
`;

const HeroLogo = styled.div`
	width: 108px;
	height: 63px;
	background-image: url(${logo});
	background-repeat: no-repeat;
	background-size: 100% auto;
	margin-bottom: 20px;
`;

const HeroContent = styled.div`
	color: #fff;
	position: relative;
	height: 730px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-end;
	max-width: 800px;
	margin: 0 ${SIDE_MARGINS}px;
`;

const HeroContentTitle = styled.div`
	font-size: 48px;
	font-weight: bold;
	margin-bottom: 20px;
`;

const HeroContentDescription = styled.div`
	font-size: 24px;
	margin-bottom: 40px;
`;

const HeroContentCallToAction = withFocusable({ trackChildren: true })(styled.div`
	display: flex;
	justify-content: space-between;
	margin: 0 ${SIDE_MARGINS}px 50px ${SIDE_MARGINS}px;
`);

const HeroContentCallToActionButtons = styled.div`
	display: flex;
	font-size: 22px;
	font-weight: bold;
`;

const HeroContentCallToActionButton = styled(Button)`
	display: flex;
	height: 72px;
	min-width: 200px;
	align-items: center;
	justify-content: center;
	margin-right: 20px;

	:last-child {
		margin-right: 0;
	}
`;

export const Hero: React.FC<{
	contentId: number;
}> = ({ children, contentId }) => {
	const ref = useRef<HTMLDivElement>();

	const { t } = useTranslation();

	const navigate = useNavigate();

	const { content, loading } = useContent(contentId, 0, 0);

	const imageUrl = useMemo(() => {
		return content?.getBackdropImageUrl({
			presets: [ImagePreset.BACKDROP_LARGE],
		});
	}, [content]);

	const onFocused = useCallback(() => {
		if (ref.current) {
			ref.current.style.transform = `translate3d(0, 0, 0)`;
		}
	}, []);

	const onBlurred = useCallback(() => {
		if (ref.current) {
			ref.current.style.transform = `translate3d(0, -810px, 0)`;
		}
	}, []);

	const onWatchPressed = useCallback(() => {
		if (!content) return;

		if (content instanceof Series) {
			if (content.nextEpisode) {
				navigate(`content/${content.nextEpisode.id}/play`);
			}
		} else {
			navigate(`content/${content.id}/play`);
		}
	}, [content, navigate]);

	const onDetailsPressed = useCallback(() => {
		if (!content) return;

		navigate(`content/${content.id}`);
	}, [content, navigate]);

	if (loading) return null;

	if (!content || !imageUrl) return <>{children}</>;

	return (
		// @ts-ignore
		<HeroContainer ref={ref}>
			<HeroBackground imageUrl={imageUrl}>
				<HeroBackgroundOverlay>
					<HeroContent>
						<HeroLogo />
						<HeroContentTitle>{content.title}</HeroContentTitle>
						<HeroContentDescription>{content.description}</HeroContentDescription>
					</HeroContent>
					<HeroContentCallToAction onBecameFocused={onFocused} onBecameBlurred={onBlurred}>
						<HeroContentCallToActionButtons>
							<HeroContentCallToActionButton hasInitialFocus title={t('Hero.Watch')} onClick={onWatchPressed} />
							<HeroContentCallToActionButton title={t('Hero.Details')} onClick={onDetailsPressed} />
						</HeroContentCallToActionButtons>
						<CurrentUser />
					</HeroContentCallToAction>
				</HeroBackgroundOverlay>
			</HeroBackground>
			{children}
		</HeroContainer>
	);
};
