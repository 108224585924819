import { Category, ModuleType, useCategory } from '@vodafoneis/sjonvarpskjarni-js-lib';
import styled from 'styled-components';
import React, { useCallback, useMemo } from 'react';
import { useContentRenderer } from '../hooks/useContentRenderer';
import { Direction, Grid } from './Grid';
import { useNavigate } from 'react-router-dom';
import { VStack } from './VStack';
import { BUTTON_BACKGROUND, PRIMARY, SECONDARY_DARKENED } from '../styles/colors';
import { CONTENT_LIMIT } from '../config/constants';
import { CONTENT_MARGIN, getModuleSize } from '../config/layout';

const SeeAllButtonTurned = styled.div<{
	moduleType?: ModuleType;
	focused?: boolean;
}>`
	height: 60px;
	width: ${({ moduleType }) => `${getModuleSize(moduleType).height}px`};
	transform: rotate(-90deg);
	color: ${SECONDARY_DARKENED};
	background-color: ${({ focused }) => (focused ? PRIMARY : BUTTON_BACKGROUND)};
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 24px;
	text-transform: uppercase;
	border-radius: 6px;
	margin-left: ${({ moduleType }) => `${-getModuleSize(moduleType).height / 2 + 30}px`};
	margin-top: ${({ moduleType }) => `${getModuleSize(moduleType).height / 2 - 30}px`};
`;

const SeeAllButtonWrapper = styled.div<{ moduleType?: ModuleType }>`
	width: 60px;
`;

const Wrapper = styled(VStack)`
	position: relative;
	margin-bottom: 40px;
`;

const Title = styled.h3`
	color: #fff;
	font-size: 28px;
	font-weight: normal;
	text-transform: uppercase;
	margin: 0;
`;

const CategoryListGrid = styled(Grid)`
	width: 1760px;
	padding-top: 20px;
	margin-left: -80px;
`;

type CategoryListProps = {
	category: Category;
	hasInitialFocus?: boolean;
};

let see_all_counter = 0;

const getSeeAllId = () => {
	see_all_counter++;
	return `SEE_ALL_${see_all_counter}`;
};

const SeeAllButton: React.FC<{ moduleType?: ModuleType; focused?: boolean }> = ({ focused, moduleType }) => {
	return (
		<SeeAllButtonWrapper moduleType={moduleType}>
			<SeeAllButtonTurned focused={focused} moduleType={moduleType}>
				Sjá allt
			</SeeAllButtonTurned>
		</SeeAllButtonWrapper>
	);
};

export const CategoryList: React.FC<CategoryListProps> = (props) => {
	const { category: originalCategory, hasInitialFocus } = props;

	const { category, fetchMoreContent } = useCategory(originalCategory.id as number, CONTENT_LIMIT);

	const items = useMemo(() => {
		if (!category?.content) return [];

		return [{ id: getSeeAllId() }, ...category.content];
	}, [category?.content]);

	const navigate = useNavigate();

	const renderContent = useContentRenderer({ moduleType: category?.moduleType });

	const renderItem = useCallback(
		(itemProps) => {
			const { item } = itemProps;
			if (String(item.id).includes('SEE_ALL')) {
				return <SeeAllButton moduleType={category?.moduleType} />;
			}

			return renderContent(itemProps);
		},
		[category?.moduleType, renderContent]
	);

	const onItemSelected = useCallback(
		({ item }) => {
			if (!category) return;

			if (String(item.id).includes('SEE_ALL')) {
				navigate(`/category/${category.id}/grid`);
			} else {
				navigate(`/content/${item.id}`);
			}
		},
		[category, navigate]
	);

	return (
		<Wrapper>
			<Title>{category?.title}</Title>
			<CategoryListGrid
				hasInitialFocus={hasInitialFocus}
				direction={Direction.Horizontal}
				onEndReached={fetchMoreContent}
				items={items}
				initialIndex={1}
				gutter={CONTENT_MARGIN}
				renderItem={renderItem}
				onItemSelected={onItemSelected}
			/>
		</Wrapper>
	);
};
