
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"TimingsFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Playable"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"recapTiming"},"name":{"kind":"Name","value":"timing"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"type"},"value":{"kind":"EnumValue","value":"RECAP"}}],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"TimingFragment"},"directives":[]}]}},{"kind":"Field","alias":{"kind":"Name","value":"introTiming"},"name":{"kind":"Name","value":"timing"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"type"},"value":{"kind":"EnumValue","value":"INTRO"}}],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"TimingFragment"},"directives":[]}]}},{"kind":"Field","alias":{"kind":"Name","value":"creditTiming"},"name":{"kind":"Name","value":"timing"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"type"},"value":{"kind":"EnumValue","value":"CREDITS"}}],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"TimingFragment"},"directives":[]}]}}]}}],"loc":{"start":0,"end":257}};
    doc.loc.source = {"body":"#import \"./TimingFragment.graphql\"\n\nfragment TimingsFragment on Playable {\n\trecapTiming: timing(type: RECAP) {\n\t\t...TimingFragment\n\t}\n\tintroTiming: timing(type: INTRO) {\n\t\t...TimingFragment\n\t}\n\tcreditTiming: timing(type: CREDITS) {\n\t\t...TimingFragment\n\t}\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("./TimingFragment.graphql").definitions));


      module.exports = doc;
    
