import styled from 'styled-components';

type VStackProps = {
	alignItems?: string;
	justifyContent?: string;
};

export const VStack = styled.div<VStackProps>`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: ${({ alignItems }) => alignItems};
	justify-content: ${({ justifyContent }) => justifyContent};
`;
