import { useMemo } from 'react';
import type { QueryHookOptions } from '@apollo/client';
import { Content, ContentType, factory } from '../models';
import { FetchMoreCallback } from './useFetchMore';
import { useFetchMoreQuery } from './useFetchMoreQuery';
import { WATCH_HISTORY } from '../queries';

const watchHistoryDuplicateFilter = (content: any, index: number, self: any[]) => {
	const seriesId = content.series?.id;

	return (
		self.findIndex((otherContent) => {
			if (content.__typename === EPISODE) {
				return otherContent.series?.id === seriesId;
			}

			return otherContent.id === content.id;
		}) === index
	);
};

const itemCountExtractor = (data: any) => data?.watched?.length ?? null;

const { EPISODE } = ContentType;

type UseWatchHistoryReturn = {
	watchHistory: Content[];
	fetchMoreWatchHistory: FetchMoreCallback;
	loadingWatchHistory: boolean;
	refetchWatchHistory: any;
};

export const useWatchHistory = (
	limit: number,
	queryOptions: QueryHookOptions<any, any> = {}
): UseWatchHistoryReturn => {
	const options = useMemo(
		() => ({
			variables: {
				limit,
			},
			...queryOptions,
		}),
		[limit, queryOptions]
	);

	const { data, fetchMore, loading, refetch } = useFetchMoreQuery(WATCH_HISTORY, itemCountExtractor, options);

	const watchHistory = useMemo(
		() => data?.watched.filter(watchHistoryDuplicateFilter).map((content: any) => factory(content)) ?? [],
		[data]
	);

	return {
		watchHistory,
		fetchMoreWatchHistory: fetchMore,
		loadingWatchHistory: loading,
		refetchWatchHistory: refetch,
	};
};
