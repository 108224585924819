import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { ProgressBar } from './ProgressBar';
import { usePlayer } from '../contexts/PlayerContext';
import { PlaybackState, usePlayback } from '../contexts/PlaybackContext';
import { PLAYBACK_CONTROLS_HIDE_TIMEOUT, SEEK_DELAY } from '../config/constants';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { useDebounce, usePrevious } from 'react-use';
import playImage from '../images/player-controls-play.png';
import pauseImage from '../images/player-controls-pause.png';
import controlsBackground from '../images/player-controls-bg.png';
import { SamsungKey, useSamsungKeyHandler } from '../hooks/useSamsungKeyHandler';

const playbackIconStyles = css`
	width: 150px;
	height: 150px;
	margin-right: 30px;
	background-size: 100% auto;
	background-repeat: no-repeat;
`;

const PlayIcon = styled.div`
	${playbackIconStyles}
	background-image: url(${playImage});
`;

const PauseIcon = styled.div`
	${playbackIconStyles}
	background-image: url(${pauseImage});
`;

const PlaybackControlsWrappper = styled.div`
	display: flex;
	align-items: center;
	position: absolute;
	flex-direction: row;
	height: 180px;
	padding: 30px 30px;
	left: 0;
	right: 0;
	bottom: 0;
	background: url(${controlsBackground}) repeat-x left bottom;
`;

type PlaybackControlsFocusableProps = {
	visible: boolean;
};

const PlaybackControlsFocusable = withFocusable<PlaybackControlsFocusableProps>()(
	({ children, stealFocus, visible }) => {
		useEffect(() => {
			stealFocus();
		}, [stealFocus]);

		if (!visible) return null;

		return <PlaybackControlsWrappper>{children}</PlaybackControlsWrappper>;
	}
);

const { Playing, Paused } = PlaybackState;

export const PlaybackControls: React.FC = () => {
	const hideTimeout = useRef<any>();
	const seekPositionRef = useRef<number | null>(null);

	const [visibilityRequested, setVisibilityRequested] = useState(false);

	const { pause, play, seek, togglePausePlay, getDuration, getPosition, stop } = usePlayer();

	const { position, duration, state } = usePlayback();

	const prevState = usePrevious(state);

	const [seekPosition, setSeekPosition] = useState<number | null>(null);

	useEffect(() => {
		if (state === prevState) return;

		// Reset seek position
		if (seekPosition && state === Playing) {
			setSeekPosition(null);
			seekPositionRef.current = null;
		}
	}, [seekPosition, prevState, state]);

	const clearHideTimeout = useCallback(() => {
		if (hideTimeout.current) {
			clearTimeout(hideTimeout.current);
		}
	}, []);

	const resetHideTimeout = useCallback(() => {
		clearHideTimeout();
		hideTimeout.current = setTimeout(() => {
			setVisibilityRequested(false);
		}, PLAYBACK_CONTROLS_HIDE_TIMEOUT);
	}, [clearHideTimeout]);

	useDebounce(
		() => {
			if (seekPosition !== null) {
				seek(seekPosition);
			}
		},
		SEEK_DELAY,
		[seek, seekPosition]
	);

	useEffect(() => {
		if (visibilityRequested) {
			resetHideTimeout();

			return () => {
				clearHideTimeout();
			};
		}
	}, [clearHideTimeout, resetHideTimeout, visibilityRequested]);

	const visible = useMemo(() => {
		if (state === Paused) return true;

		return visibilityRequested;
	}, [state, visibilityRequested]);

	const seekToOffset = useCallback(
		(offset) => {
			// Need to use duration and position directly from the player since useKey does not respect callback dependencies
			const currentDuration = getDuration();
			const nextPosition = (seekPositionRef.current ?? getPosition()) + offset;

			if (nextPosition <= 0) {
				seekPositionRef.current = 0;
			} else if (nextPosition >= currentDuration) {
				seekPositionRef.current = currentDuration;
			} else {
				seekPositionRef.current = nextPosition;
			}
			setSeekPosition(seekPositionRef.current);
		},
		[getDuration, getPosition]
	);

	const onEnter = useCallback(() => {
		togglePausePlay();
		setVisibilityRequested(true);
		resetHideTimeout();
	}, [resetHideTimeout, togglePausePlay]);

	const seekBack = useCallback(() => {
		seekToOffset(-10);
	}, [seekToOffset]);

	const seekForward = useCallback(() => {
		seekToOffset(10);
	}, [seekToOffset]);

	const onArrowPress = useCallback(
		(direction: Direction) => {
			if (!visible) {
				setVisibilityRequested(true);
			} else {
				resetHideTimeout();

				if (direction === 'left') {
					seekBack();
				} else if (direction === 'right') {
					seekForward();
				}
			}
		},
		[resetHideTimeout, seekBack, seekForward, visible]
	);

	const onPausePressed = useCallback(() => {
		pause();
	}, [pause]);

	const onPlayPressed = useCallback(() => {
		play();
		setVisibilityRequested(true);
		resetHideTimeout();
	}, [play, resetHideTimeout]);

	useSamsungKeyHandler(SamsungKey.MediaPlayPause, onEnter);
	useSamsungKeyHandler(SamsungKey.MediaPlay, onPlayPressed);
	useSamsungKeyHandler(SamsungKey.MediaPause, onPausePressed);
	useSamsungKeyHandler(SamsungKey.MediaFastForward, seekForward);
	useSamsungKeyHandler(SamsungKey.MediaRewind, seekBack);
	useSamsungKeyHandler(SamsungKey.MediaStop, stop);

	return (
		<PlaybackControlsFocusable visible={visible} onArrowPress={onArrowPress} onEnterPress={onEnter}>
			{state === Paused ? <PauseIcon /> : <PlayIcon />}
			<ProgressBar position={seekPosition || position} duration={duration} />
		</PlaybackControlsFocusable>
	);
};
