export class URLUtils {
	static async redirectUrl(url: string) {
		const { ok, url: redirectedUrl, statusText, status } = await fetch(url, {
			// @ts-ignore
			timeout: 5000,
		});
		if (ok) return redirectedUrl;
		return url;
	}
}
