import type { Category } from './Category';
import type { Product } from './Product';
import type { Image } from './Image';
import type { Content } from './Content';
import type { Collection } from './Collection';
import type { Property } from './Property';

import { generateImagePath, ImagePathParams } from '../utils/ImageUtils';

export class Ad {
	id?: number;
	title?: string;
	subtitle?: string;
	type?: string;
	image?: Image;
	category?: Category;
	content?: Content;
	product?: Product;
	collection?: Collection;
	properties?: Property[];

	constructor(data: object) {
		Object.assign(this, data);
	}

	getImageUrl(params: ImagePathParams = {}) {
		const { type, image, content, category, product } = this;

		let hash =
			image?.hash ??
			content?.promoImage?.hash ??
			content?.backdropImage?.hash ??
			category?.backdropImage?.hash ??
			null;

		if (type === 'product' && product?.backdropImage) {
			hash = product.backdropImage.hash;
		}

		if (hash) {
			return generateImagePath({
				hash,
				...params,
			});
		}

		return null;
	}

	getProduct() {
		return this.product ?? null;
	}

	getProperty(propertyName: string) {
		return this.properties?.find(({ name }) => name === propertyName) ?? null;
	}
}
