import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { FADE_BLACK, PRIMARY } from '../styles/colors';
import { SeriesContext } from '../contexts/SeriesContext';
import { Grid } from './Grid';
import { useTranslation } from 'react-i18next';
import { SCREEN_HEIGHT, SCREEN_WIDTH } from '../config/layout';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import type { GridProps } from './Grid';
import { createPortal } from 'react-dom';
import { useOnBack } from '../hooks/useOnBack';

const SeasonsPickerModalBackground = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: ${SCREEN_WIDTH}px;
	height: ${SCREEN_HEIGHT}px;
	z-index: 2;
	background-color: ${FADE_BLACK};
`;

const SeasonsGridScrollContainer = styled.div`
	overflow: hidden;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const SeasonsGrid = withFocusable<GridProps>({ blockNavigationOut: true })(styled(Grid)`
	max-height: 700px;
`);

const SeasonItem = styled.div<{ focused?: boolean }>`
	font-size: 50px;
	font-weight: bold;
	text-align: center;
	color: ${({ focused }) => (focused ? PRIMARY : '#fff')};
	transform: ${({ focused }) => (focused ? 'scale(1.2)' : 'scale(1)')};
	min-width: 500px;
`;

const modalRoot = document.getElementById('ModalRoot') as Element;

export const SeasonsPickerModal: React.FC = () => {
	const { t } = useTranslation();
	const { selectedSeason, series, setSelectedSeason, hideSeasonPicker } = useContext(SeriesContext);

	const onBack = useCallback(() => {
		hideSeasonPicker();
		return true;
	}, [hideSeasonPicker]);

	useEffect(() => {
		const rootElement = document.getElementById('root');
		if (rootElement) {
			rootElement.classList.add('blur');

			return () => {
				rootElement.classList.remove('blur');
			};
		}
	}, []);

	useOnBack(onBack);

	const seasons = useMemo(() => series?.seasons || [], [series?.seasons]);

	const initialIndex = useMemo(
		() => seasons.findIndex(({ number }) => number === selectedSeason),
		[seasons, selectedSeason]
	);

	const renderSeason = useCallback(
		({ item }) => (
			<SeasonItem>
				{item.number === 0 ? t('Series.UnknownSeason') : t('Series.Season', { episodeSeason: item.number })}
			</SeasonItem>
		),
		[t]
	);

	const onItemSelected = useCallback(
		({ item }) => {
			setSelectedSeason(item.number);
			hideSeasonPicker();
		},
		[hideSeasonPicker, setSelectedSeason]
	);

	if (!series || !selectedSeason) return null;

	return createPortal(
		<SeasonsPickerModalBackground>
			<SeasonsGridScrollContainer>
				<SeasonsGrid
					initialIndex={initialIndex > 0 ? initialIndex : 0}
					hasInitialFocus
					items={series.seasons ?? []}
					onItemSelected={onItemSelected}
					renderItem={renderSeason}
					gutter={30}
				/>
			</SeasonsGridScrollContainer>
		</SeasonsPickerModalBackground>,
		modalRoot
	);
};
