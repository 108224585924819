import React, { createContext, useMemo } from 'react';

type QueryContextType = {
	accessToken?: string | null;
	language?: string | null;
};

export const QueryContext = createContext<QueryContextType>({
	accessToken: null,
	language: null,
});

type QueryContextProviderProps = {
	accessToken: string | null;
	language: string | null;
};

export const QueryContextProvider: React.FC<QueryContextProviderProps> = (props) => {
	const { accessToken, language, children } = props;

	const value = useMemo(() => ({ accessToken, language }), [accessToken, language]);

	return <QueryContext.Provider value={value}>{children}</QueryContext.Provider>;
};
