import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { Exception } from '@vodafoneis/sjonvarpskjarni-js-lib';
import type { THEOplayerError } from 'theoplayer';
import { usePlaybackEvents } from '../hooks/usePlaybackEvents';
import PlaybackFailedException from '../exceptions/PlaybackFailedException';

export enum PlaybackState {
	Initializing = 'initializing',
	Initialized = 'initialized',
	Playing = 'playing',
	Paused = 'paused',
	Stopped = 'stopped',
	Buffering = 'buffering',
	Error = 'error',
}

export type ProgressInfo = {
	position: number;
	duration: number;
	currentProgramDateTime: Date | null;
};

type ProgressContextProps = ProgressInfo & {
	state: PlaybackState;
	error: Exception | null;
	onPlaybackError: (error: Exception) => void;
	clearPlaybackError: () => void;
};

const { Initializing, Initialized, Playing, Paused, Error, Buffering, Stopped } = PlaybackState;

export const PlaybackContext = createContext<ProgressContextProps>({
	position: 0,
	duration: 0,
	currentProgramDateTime: null,
	state: Initializing,
	error: null,
	onPlaybackError: () => {},
	clearPlaybackError: () => {},
});

export const usePlayback = () => useContext(PlaybackContext);

export const PlaybackContextProvider: React.FC = ({ children }) => {
	const [position, setPosition] = useState(0);
	const [duration, setDuration] = useState(0);
	const [currentProgramDateTime, setCurrentProgramDateTime] = useState<Date | null>(null);
	const [state, setState] = useState(Initializing);
	const [error, setError] = useState<Exception | null>(null);

	const clearPlaybackError = useCallback(() => {
		setError(null);
	}, []);

	const onPlaybackError = useCallback((error: Exception) => {
		setError(error);
		setState(Error);
	}, []);

	const playbackEvents = useMemo(
		() => ({
			onPlaybackInitialized: () => {
				setState(Initialized);
			},
			onPlaybackError: ({ errorObject }: { errorObject: THEOplayerError }) => {
				onPlaybackError(new PlaybackFailedException(errorObject?.message ?? 'THEOPlayer error'));
			},
			onPlaybackBuffering: () => {
				setState(Buffering);
			},
			onPlaybackPaused: () => {
				setState(Paused);
			},
			onPlaybackStarted: () => {
				setState(Playing);
			},
			onPlaybackStopped: () => {
				setState(Stopped);
			},
			onSeekFinished: () => {
				setState(Playing);
			},
			onPlaybackProgress: ({ position, duration, currentProgramDateTime }: ProgressInfo) => {
				setPosition(position);
				setDuration(duration);
				setCurrentProgramDateTime(currentProgramDateTime);
			},
		}),
		[onPlaybackError]
	);

	usePlaybackEvents(playbackEvents);

	const value = useMemo(
		() => ({ position, duration, currentProgramDateTime, state, error, onPlaybackError, clearPlaybackError }),
		[currentProgramDateTime, duration, error, position, state, onPlaybackError, clearPlaybackError]
	);

	return <PlaybackContext.Provider value={value}>{children}</PlaybackContext.Provider>;
};
