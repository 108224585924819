import { ModelFactoryException } from '../exceptions/ModelFactoryException';
import { ModelType } from './ModelType';
import {
	Position,
	Rental,
	Episode,
	Series,
	Movie,
	Actor,
	File,
	License,
	Purchase,
	Product,
	Provider,
	Genre,
	Price,
	Property,
	Recording,
	Season,
	Image,
	Timing,
	VAST,
	Tag,
	Event,
	Channel,
	Collection,
	Category,
	Ad,
	Director,
	SearchResults,
	UserSettings,
} from '.';

const {
	ACTOR,
	AD,
	CATEGORY,
	CHANNEL,
	COLLECTION,
	MOVIE,
	SERIES,
	EPISODE,
	EVENT,
	RENTAL,
	FILE,
	LICENSE,
	TIMING,
	IMAGE,
	TAG,
	GENRE,
	SEASON,
	DIRECTOR,
	RECORDING,
	PRODUCT,
	PROPERTY,
	POSITION,
	PRICE,
	PURCHASE,
	VAST_TYPE,
	PROVIDER,
	SEARCH_RESULTS,
	USER_SETTINGS
} = ModelType;

const runSubFactories = (Model: any, data: any) => {
	const model = new Model(data);

	Object.keys(data).forEach((key) => {
		if (Array.isArray(model[key])) {
			model[key] = model[key].map((item: any) => factory(item));
		} else if (data[key]) {
			model[key] = factory(model[key]);
		}
	});

	return model;
};

export function factory(data: any) {
	if (!data) return null;

	const { __typename } = data;

	if (!__typename) {
		return data;
	}

	switch (__typename) {
		case AD:
			return runSubFactories(Ad, data);
		case ACTOR:
			return runSubFactories(Actor, data);
		case CATEGORY:
			return categoryFactory(data);
		case CHANNEL:
			return runSubFactories(Channel, data);
		case COLLECTION:
			return runSubFactories(Collection, data);
		case DIRECTOR:
			return runSubFactories(Director, data);
		case MOVIE:
			return runSubFactories(Movie, data);
		case SERIES:
			return runSubFactories(Series, data);
		case EPISODE:
			return runSubFactories(Episode, data);
		case EVENT:
			return runSubFactories(Event, data);
		case RENTAL:
			return runSubFactories(Rental, data);
		case FILE:
			return new File(data);
		case LICENSE:
			return runSubFactories(License, data);
		case PRODUCT:
			return runSubFactories(Product, data);
		case PURCHASE:
			return runSubFactories(Purchase, data);
		case TIMING:
			return new Timing(data);
		case IMAGE:
			return new Image(data);
		case TAG:
			return new Tag(data);
		case GENRE:
			return new Genre(data);
		case SEARCH_RESULTS:
			return runSubFactories(SearchResults, data);
		case SEASON:
			return new Season(data);
		case PROVIDER:
			return new Provider(data);
		case RECORDING:
			return new Recording(data);
		case PROPERTY:
			return new Property(data);
		case POSITION:
			return new Position(data);
		case PRICE:
			return new Price(data);
		case VAST_TYPE:
			return new VAST(data);
		case USER_SETTINGS:
			return new UserSettings(data);
		default:
			throw new ModelFactoryException(`Could not create Content model for type (${__typename})`);
	}
}

const categoryFactory = (data: any) => {
	const category = runSubFactories(Category, data);

	const { content, product } = category;

	if (content && product) {
		category.content = content.map((contentModel: any) => {
			contentModel.product = factory(product);

			return contentModel;
		});
	}

	return category;
};
