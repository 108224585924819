import { Exception } from './Exception';

export class MissingParameterException extends Exception {
	parameter: string | null = null;

	constructor(message?: string, parameter: string | null = null) {
		super(message, 'MissingParameterException');
		this.parameter = parameter;
		Object.setPrototypeOf(this, MissingParameterException.prototype);
	}
}
