export enum AgeLevel {
	AGE_LEVEL_0 = 0,
	AGE_LEVEL_6 = 6,
	AGE_LEVEL_9 = 9,
	AGE_LEVEL_12 = 12,
	AGE_LEVEL_16 = 16,
	AGE_LEVEL_18 = 18,
}

export enum PinPlayContent {
	ALWAYS = 1,
	NOT_FREE = 2,
	AGE_LEVEL = 3,
}

export enum PinOnChannels {
	AGE_LEVEL = 1,
	NO = 0,
}

export enum PinOnTimeshift {
	AGE_LEVEL = 1,
	NO = 0,
}

export enum AllowSubscriptions {
	YES_WITH_PIN = 2,
	YES = 1,
	NO = 0,
}

export enum AllowPPV {
	YES_WITH_PIN = 2,
	YES = 1,
	NO = 0,
}

export enum Language {
	IS,
	EN,
}

export class UserSettings {
	language?: keyof Language;
	purchaseLimit?: number;
	recentlySearched?: string[];
	ageLevel?: keyof AgeLevel;
	pinPlayContent?: keyof PinPlayContent;
	pinOnChannels?: keyof PinOnChannels;
	pinOnTimeshift?: keyof PinOnTimeshift;
	allowSubscriptions?: keyof AllowSubscriptions;
	allowPPV?: keyof AllowPPV;

	constructor(data: object) {
		Object.assign(this, data);
	}

	shouldAskForPin(ageRating: number, price: number, allowedAgeRating: number = 0) {
		// @ts-ignore
		const ageLevel = AgeLevel[this.ageLevel];
		// @ts-ignore
		const pinLevel = PinPlayContent[this.pinPlayContent];

		if (ageRating > 0 && ageRating <= allowedAgeRating) {
			// If age rating is already allowed in the playlist.
			return false;
		}

		if (ageLevel > 0 && ageLevel <= ageRating) {
			// If ageLevel settings requires PIN.
			return true;
		}

		if (pinLevel === PinPlayContent.ALWAYS) {
			// Always ask for PIN
			return true;
		}

		if (pinLevel === PinPlayContent.NOT_FREE) {
			// Not for free content
			return price > 0;
		}

		return false;
	}
}
