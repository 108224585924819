import type { Content } from '@vodafoneis/sjonvarpskjarni-js-lib';
import { usePlayback } from '../contexts/PlaybackContext';
import { ContentBackgroundImage } from './ContentBackgroundImage';
import React, { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
	AccountBlockingException,
	AuthorizationException,
	EntitlementExpiredException,
	EntitlementLimitReachedException,
	GeoBlockingException,
} from '@vodafoneis/sjonvarpskjarni-js-lib';
import { usePlayer } from '../contexts/PlayerContext';
import { ERROR_TIMEOUT } from '../config/constants';
import { Button } from './Button';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { useEntitlement } from '../contexts/EntitlementContext';

const ErrorTitle = styled.div`
	font-size: 55px;
	font-weight: 300;
	margin-bottom: 10px;
`;

const ErrorMessage = styled.div`
	font-size: 46px;
	font-weight: 300;
	color: #aaa;
	margin-bottom: 30px;
`;

const ActionButtons = withFocusable({ blockNavigationOut: true })(styled.div`
	display: flex;
	font-size: 22px;
	font-weight: bold;
`);

const ActionButton = styled(Button)`
	flex-grow: 0;
	width: 200px;
	margin-right: 20px;

	:last-child {
		margin-right: 0;
	}
`;

type PlaybackErrorProps = {
	content: Content;
};

const PlaybackErrorContainer = styled(ContentBackgroundImage)`
	flex-direction: column;
	justify-content: flex-end;
	padding: 90px 150px;
`;

export const PlaybackError: React.FC<PlaybackErrorProps> = ({ content }) => {
	const { stop, pause } = usePlayer();
	const { error, clearPlaybackError } = usePlayback();
	const { t } = useTranslation();
	const { createEntitlement } = useEntitlement();

	useEffect(() => {
		console.log(error);
		// Entitlement limit reached has action, so we don't timeout the error
		if (!error || error instanceof EntitlementLimitReachedException) return;

		// Pause the player to make sure there is no sound while the error is displayed
		pause();

		const errorTimeout = setTimeout(() => {
			stop();
		}, ERROR_TIMEOUT);

		return () => {
			clearTimeout(errorTimeout);
		};
	}, [error, pause, stop]);

	const errorTitle = useMemo(() => {
		if (error instanceof EntitlementExpiredException) return 'Error.PlaybackCanceled';
		if (
			error instanceof EntitlementLimitReachedException ||
			error instanceof AccountBlockingException ||
			error instanceof GeoBlockingException ||
			error instanceof AuthorizationException
		)
			return 'Error.CouldNotStart';

		return 'Error.Title';
	}, [error]);

	const errorMessage = useMemo(() => {
		if (!error) return null;

		if (error instanceof AccountBlockingException) return 'Error.AccountBlocked';
		if (error instanceof GeoBlockingException) return 'Error.GeoBlocked';
		if (error instanceof EntitlementExpiredException) return 'Error.EntitlementExpired';
		if (error instanceof EntitlementLimitReachedException) return 'Error.EntitlementLimitReached';
		if (error instanceof AuthorizationException) return 'Error.NotSubscribed';
		// Fallback to general error

		return 'Error.General';
	}, [error]);

	const onConfirmTakeover = useCallback(() => {
		createEntitlement(true);
		clearPlaybackError();
	}, [clearPlaybackError, createEntitlement]);

	const actions = useMemo(() => {
		if (error instanceof EntitlementLimitReachedException) {
			return (
				<ActionButtons>
					<ActionButton hasInitialFocus title={t('Error.Actions.TakeOver')} onClick={onConfirmTakeover} />
					<ActionButton title={t('Error.Actions.Cancel')} onClick={stop} />
				</ActionButtons>
			);
		}

		return null;
	}, [error, onConfirmTakeover, stop, t]);

	if (!errorMessage) return null;

	return (
		<PlaybackErrorContainer content={content}>
			<ErrorTitle>{t(errorTitle)}</ErrorTitle>
			<ErrorMessage>{t(errorMessage)}</ErrorMessage>
			{actions}
		</PlaybackErrorContainer>
	);
};
