import React, { useContext } from 'react';
import { initNavigation } from '@noriginmedia/react-spatial-navigation';
import { CategoryListScreen } from './screens/CategoryListScreen';
import { ApolloProvider } from '@apollo/client';
import { HashRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { PlayContentScreen } from './screens/PlayContentScreen';
import { ContentScreen } from './screens/ContentScreen';
import { GlobalStyles } from './GlobalStyles';
import { UserContext, UserContextProvider } from './contexts/UserContext';
import { LoginScreen } from './screens/LoginScreen';
import ApolloClient from './api/ApolloClient';
import { APIContextProvider } from '@vodafoneis/sjonvarpskjarni-js-lib';
import APIClient from './api/APIClient';
import { CategoryGridScreen } from './screens/CategoryGridScreen';
import { registerKeys } from './hooks/useSamsungKeyHandler';

if (window.webos) {
	require('./libs/webOSTV');
} else if (window.tizen) {
	registerKeys();
}

initNavigation({ debug: false, visualDebug: false });

const RequireAuth = ({ children }: { children: JSX.Element }) => {
	const { user } = useContext(UserContext);
	let location = useLocation();

	if (!user) {
		return <Navigate to={'/login'} state={{ from: location }} replace />;
	}

	return children;
};

function App() {
	return (
		<ApolloProvider client={ApolloClient}>
			<APIContextProvider client={APIClient}>
				<UserContextProvider>
					<GlobalStyles />
					<HashRouter>
						<Routes>
							<Route
								path={'/'}
								element={
									<RequireAuth>
										<CategoryListScreen />
									</RequireAuth>
								}
							/>
							<Route path={'category/:categoryId'}>
								<Route
									path={'grid'}
									element={
										<RequireAuth>
											<CategoryGridScreen />
										</RequireAuth>
									}
								/>
							</Route>
							<Route path={'content/:contentId'}>
								<Route
									path={''}
									element={
										<RequireAuth>
											<ContentScreen />
										</RequireAuth>
									}
								/>
								<Route
									path={'season/:seasonNumber'}
									element={
										<RequireAuth>
											<ContentScreen />
										</RequireAuth>
									}
								/>
								<Route path={'play'}>
									<Route
										path={''}
										element={
											<RequireAuth>
												<PlayContentScreen />
											</RequireAuth>
										}
									/>
									<Route
										path={':position'}
										element={
											<RequireAuth>
												<PlayContentScreen />
											</RequireAuth>
										}
									/>
								</Route>
							</Route>
							<Route path={'/login'} element={<LoginScreen />} />
						</Routes>
					</HashRouter>
				</UserContextProvider>
			</APIContextProvider>
		</ApolloProvider>
	);
}

export default App;
