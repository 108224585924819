import { useMemo } from 'react';
import { ApolloError, QueryHookOptions, ApolloQueryResult } from '@apollo/client';
import { PRODUCTS_QUERY } from '../queries';
import { factory, Product, ProductType } from '../models';
import { useFetchMoreQuery } from './useFetchMoreQuery';
import { FetchMoreCallback } from './useFetchMore';

const itemCountExtractor = (data: any) => data?.products?.length ?? null;

type UseProductsReturn = {
	products: Product[];
	refetchProducts: () => Promise<ApolloQueryResult<any[]>>;
	fetchMoreProducts: FetchMoreCallback;
	loadingProducts: boolean;
	productsError: ApolloError;
};

export const useProducts = (
	productType: ProductType,
	limit: number,
	queryOptions: QueryHookOptions<any, any> = {}
): UseProductsReturn => {
	const options = useMemo(
		() => ({
			variables: {
				limit,
				productType,
			},
			...queryOptions,
		}),
		[limit, productType, queryOptions]
	);

	const { data, loading, refetch, fetchMore, error } = useFetchMoreQuery(PRODUCTS_QUERY, itemCountExtractor, options);

	const products = useMemo(() => data?.products?.map((product: any) => factory(product)) ?? [], [data]);

	return {
		products,
		loadingProducts: loading,
		refetchProducts: refetch,
		fetchMoreProducts: fetchMore,
		productsError: error,
	};
};
