export class Price {
	value?: number;

	constructor(data: object) {
		Object.assign(this, data);
	}

	isFree() {
		return this.value <= 0;
	}
}
