import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { checkPin, requestLoginPIN } from '../api/user';
import { useInterval } from 'react-use';
import { UserContext } from '../contexts/UserContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../images/logo.png';
import background from '../images/default-bg@1.5x.png';
import { page, track } from '../utils/analytics';
import { AnalyticsEvent } from '@vodafoneis/sjonvarpskjarni-js-lib';
import { useOnBack } from '../hooks/useOnBack';

const Wrapper = styled.div`
	display: flex;
	height: 100%;
	justify-content: center;
	align-items: center;
	background: url(${background}) no-repeat;
	background-size: 100% 100%;
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 50px 100px;
`;

const Title = styled.div`
	font-size: 62px;
	text-align: center;
	margin-bottom: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Logo = styled.div`
	width: 108px;
	height: 63px;
	background-image: url(${logo});
	background-repeat: no-repeat;
	background-size: 100% auto;
	margin-right: 40px;
`;

const Description = styled.div`
	margin: 20px auto;
	font-size: 32px;
	color: #fff;
	text-align: center;
	max-width: 1200px;
`;

const Link = styled.span`
	font-size: 32px;
	color: #e18008;
`;

const PinWrapper = styled.div`
	display: flex;
	margin: 50px auto;
	flex-direction: row;
`;

const PinCharacterWrapper = styled.div`
	display: flex;
	color: #fff;
	background-color: rgba(255, 255, 255, 0.1);
	width: 100px;
	height: 130px;
	margin-right: 25px;
	align-items: center;
	justify-content: center;
`;

const PinCharacter = styled.div`
	display: flex;
	font-size: 80px;
`;

const ErrorMessageWrapper = styled.div`
	display: flex;
	margin: 40px 0;
	font-size: 32px;
	font-weight: bold;
	color: #c00;
`;

const { LOGIN_SUCCESS } = AnalyticsEvent;

const usePin = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [pin, setPin] = useState<string | null>(null);
	const [lastError, setLastError] = useState<any>(null);

	const { user, setUser, setDevice, deviceId } = useContext(UserContext);

	useEffect(() => {
		if (user) {
			// @ts-ignore
			navigate(location?.state?.from ?? '/', { replace: true });
		}
	}, [user, location, navigate]);

	const updatePin = useCallback(async () => {
		if (deviceId) {
			const loginPin = await requestLoginPIN({ deviceId });
			if (loginPin) {
				setLastError(null);
				setPin(loginPin);
			} else {
				setLastError(new Error('Failed to request login PIN'));
				setPin(null);
			}
		}
	}, [deviceId]);

	useEffect(() => {
		let timeout: any = null;

		if (lastError) {
			timeout = setTimeout(() => {
				updatePin();
			}, 10000);
		}

		return () => {
			if (timeout) clearTimeout(timeout);
		};
	});

	useInterval(async () => {
		if (!pin) return;

		const response = await checkPin(pin);

		if (!response) return null;

		if (response.statusCode === 404) {
			updatePin();
			return;
		}

		if (!response.isSuccess()) {
			setLastError(new Error('Failed to login with PIN'));
			return;
		}

		const { id, accessToken, device, name, username } = response.data;

		if (accessToken) {
			setDevice(device);

			setUser({
				id,
				name,
				username,
				accessToken,
			});

			track(LOGIN_SUCCESS, {
				username,
			});
		}
	}, 2000);

	useEffect(() => {
		if (deviceId) {
			updatePin();
		}
	}, [deviceId, updatePin]);

	return {
		pin,
		lastError,
	};
};

export const LoginScreen: React.FC = () => {
	const { t } = useTranslation();
	const { pin, lastError } = usePin();

	useEffect(() => {
		page('Login');
	}, []);

	const handleBack = useCallback(() => {
		// TODO should we prompt before closing the app?
		// Close the app
		if (window.webOS) {
			window.webOS.platformBack();
		} else if (window.tizen) {
			window.tizen.application.getCurrentApplication().exit();
		}
		return true;
	}, []);

	useOnBack(handleBack);

	const PinElement = useMemo(() => {
		if (lastError) return null;

		const splitUpPin = pin ? pin.split('') : ['', '', '', '', '', ''];

		return (
			<PinWrapper>
				{splitUpPin.map((character, index) => (
					<PinCharacterWrapper key={String(index)}>
						<PinCharacter>{character}</PinCharacter>
					</PinCharacterWrapper>
				))}
			</PinWrapper>
		);
	}, [pin, lastError]);

	return (
		<Wrapper>
			<Container>
				<div>
					<Title>
						<Logo />
						{t('Login.Title')}
					</Title>
					<Description>{t('Login.Description')}</Description>
					<Description>
						{t('Login.Link.Description')}
						<Link>{t('Login.Link.URL')}</Link>
						{t('Login.Link.Description2')}
					</Description>
					{lastError && <ErrorMessageWrapper>{t('Login.Error')}</ErrorMessageWrapper>}
				</div>
				{PinElement}
			</Container>
		</Wrapper>
	);
};
