import { ModuleType } from '@vodafoneis/sjonvarpskjarni-js-lib';

export const SCREEN_WIDTH = 1920;
export const SCREEN_HEIGHT = 1080;
export const SIDE_MARGINS = 130;
export const CONTENT_WIDTH = SCREEN_WIDTH - 2 * SIDE_MARGINS;
export const CONTENT_MARGIN = 20;

const { POSTER, POSTER_LARGE, M2, M3, M4, HOPSTER } = ModuleType;
const generateModuleSize = (split: number, aspectRatio: number) => {
	const width = (CONTENT_WIDTH - (split - 1) * CONTENT_MARGIN) / split;

	return { width, height: width * aspectRatio };
};

export const getPageSplit = (moduleType?: ModuleType) => {
	switch (moduleType) {
		case POSTER:
			return 6;
		case POSTER_LARGE:
			return 5;
		case M2:
			return 2;
		case M3:
			return 3;
		case HOPSTER:
		case M4:
		default:
			return 4;
	}
};

export const POSTER_LARGE_SIZE = generateModuleSize(getPageSplit(POSTER_LARGE), 1.5);
export const POSTER_SIZE = generateModuleSize(getPageSplit(POSTER), 1.5);
export const M2_SIZE = generateModuleSize(getPageSplit(M2), 9 / 16);
export const M3_SIZE = generateModuleSize(getPageSplit(M3), 9 / 16);
export const M4_SIZE = generateModuleSize(getPageSplit(M4), 9 / 16);

export const getModuleSize = (moduleType?: ModuleType) => {
	switch (moduleType) {
		case POSTER:
			return POSTER_SIZE;
		case POSTER_LARGE:
			return POSTER_LARGE_SIZE;
		case M2:
			return M2_SIZE;
		case M3:
			return M3_SIZE;
		case M4:
		case HOPSTER:
		default:
			return M4_SIZE;
	}
};
