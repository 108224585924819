import React, { ComponentPropsWithoutRef, forwardRef, PropsWithChildren } from 'react';
import { useClassNames } from '../hooks';

type ColProps = PropsWithChildren<
	ComponentPropsWithoutRef<'div'> & {
		xl?: number;
		lg?: number;
		md?: number;
		sm?: number;
		xs?: number;
		className?: string;
	}
>;

export const Col = forwardRef<HTMLDivElement, ColProps>(({ xl, lg, md, sm, xs, className, children, ...rest }, ref) => {
	const classNames = useClassNames(className, {
		[`col-${xs}`]: !!xs,
		[`col-sm-${sm}`]: !!sm,
		[`col-md-${md}`]: !!md,
		[`col-lg-${lg}`]: !!lg,
		[`col-xl-${xl}`]: !!xl,
	});

	return (
		<div ref={ref} className={classNames} {...rest}>
			{children}
		</div>
	);
});
