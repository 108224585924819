import { useEffect, useCallback, useRef } from 'react';

export function useInfiniteScroll(callback: () => void) {
	const initialLoadDone = useRef(false);

	const initialLoad = useCallback(() => {
		if (initialLoadDone.current) return;

		initialLoadDone.current = true;

		const { innerHeight } = window;
		const { scrollHeight } = document.body;

		if (scrollHeight < innerHeight) {
			callback();
		}
	}, [callback]);

	const handlePageScroll = useCallback(() => {
		const { innerHeight, scrollY } = window;
		const { offsetHeight, scrollHeight } = document.body;

		// Fetch more when scrolled 70% or more.
		const threshold = scrollHeight * 0.3;

		if (innerHeight + scrollY >= offsetHeight - threshold) {
			callback();
		}
	}, [callback]);

	useEffect(() => {
		initialLoad();

		window.addEventListener('scroll', handlePageScroll);

		return () => {
			window.removeEventListener('scroll', handlePageScroll);
		};
	}, [handlePageScroll, initialLoad]);
}
