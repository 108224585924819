import { useMemo } from 'react';
import { ApolloError, ApolloQueryResult, QueryHookOptions } from '@apollo/client';
import { factory, Product } from '../models';
import { useAuthenticatedQuery } from './useAuthenticatedQuery';
import { PRODUCT_QUERY } from '../queries';

type UseProductReturnType = {
	product: Product;
	loadingProduct: boolean;
	productError: ApolloError;
	refetchProduct: () => Promise<ApolloQueryResult<any>>;
};

export function useProduct(productId: number, queryOptions: QueryHookOptions<any, any> = {}): UseProductReturnType {
	const options = useMemo(
		() => ({
			variables: { productId },
			...queryOptions,
		}),
		[productId, queryOptions]
	);
	const { data, loading, error, refetch } = useAuthenticatedQuery(PRODUCT_QUERY, options);

	const product = useMemo(() => (data?.product ? factory(data.product) : null), [data]);

	return { product, loadingProduct: loading, productError: error, refetchProduct: refetch };
}
