import React from 'react';
import { useParams } from 'react-router-dom';
import { Player } from '../player/Player';
import { PlaybackContextProvider } from '../contexts/PlaybackContext';
import { PlayerContextProvider } from '../contexts/PlayerContext';
import { EntitlementContextProvider } from '../contexts/EntitlementContext';
import { Playable, useContent } from '@vodafoneis/sjonvarpskjarni-js-lib';

export const PlayContentScreen = () => {
	const { contentId, position } = useParams<{ contentId: string; position: string }>();

	const { content } = useContent(contentId as string, 0, 0);

	if (!(content instanceof Playable)) return null;

	return (
		<PlayerContextProvider>
			<PlaybackContextProvider>
				<EntitlementContextProvider content={content as Playable}>
					<Player content={content as Playable} initialPosition={Number(position)} />
				</EntitlementContextProvider>
			</PlaybackContextProvider>
		</PlayerContextProvider>
	);
};
