import { useCallback, useEffect, useRef } from 'react';
import type { ApolloQueryResult, FetchMoreOptions, FetchMoreQueryOptions } from '@apollo/client';
import type { DocumentNode } from 'graphql';

export type ItemCountExtractor<TData> = (data?: TData) => number | null;

export type FetchMoreCallback<TData = any, TVariables = any> = (<K extends keyof TVariables>(
	fetchMoreOptions?: FetchMoreQueryOptions<TVariables, K> & FetchMoreOptions<TData, TVariables>
) => Promise<ApolloQueryResult<TData>>) &
	(<TData2, TVariables2, K extends keyof TVariables2>(
		fetchMoreOptions: {
			query?: DocumentNode;
		} & FetchMoreQueryOptions<TVariables2, K> &
			FetchMoreOptions<TData2, TVariables2>
	) => Promise<ApolloQueryResult<TData2>>);

type FetchMoreConfig<TData, TVariables> = {
	itemCountExtractor: ItemCountExtractor<TData>;
	fetchMore: FetchMoreCallback<TData, TVariables>;
	skipQuery: boolean;
	skip: number;
	limit: number;
	variables?: { [key: string]: any };
	loading: boolean;
};

export function useFetchMore<TData, TVariables>({
	fetchMore,
	itemCountExtractor,
	skipQuery,
	skip,
	limit,
	variables,
	loading,
}: FetchMoreConfig<TData, TVariables>) {
	const isFetchingMore = useRef(false);
	const canFetchMore = useRef(true);

	const resetSkip = useCallback(() => {
		canFetchMore.current = true;
	}, []);

	const fetchMoreCallback = useCallback(async () => {
		// Block fetch more
		if (
			skipQuery || // query is not allowed now, thus not fetch more either
			loading || // query is loading
			isFetchingMore.current === true || // fetch more already in progress
			canFetchMore.current === false || //  all content already fetched
			skip < limit // The initial query contained everything, no need to fetch more
		) {
			return;
		}

		isFetchingMore.current = true;

		try {
			const { data: fetchMoreResult } = await fetchMore({
				variables: {
					skip,
					...variables,
				},
			});

			const fetchMoreCount = itemCountExtractor(fetchMoreResult as TData);

			if (fetchMoreCount < limit) {
				canFetchMore.current = false;
			}
		} catch (exception) {}

		isFetchingMore.current = false;
	}, [skipQuery, loading, fetchMore, skip, variables, itemCountExtractor, limit]);

	return { fetchMore: fetchMoreCallback, resetSkip };
}
