import styled from 'styled-components';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Category, ModuleTemplate, useCategoryList } from '@vodafoneis/sjonvarpskjarni-js-lib';
import { CategoryList } from '../components/CategoryList';
import { Grid, RenderItem } from '../components/Grid';
import { Hero } from '../components/Hero';
import { Container } from '../components/Container';
import { CATEGORY_LIMIT, CONTENT_LIMIT, ROOT_CATEGORY_ID } from '../config/constants';
import { page } from '../utils/analytics';
import { useOnBack } from '../hooks/useOnBack';

const CategoryListPageGrid = styled(Grid)`
	height: 1050px;
`;

export const CategoryListScreen: React.FC = () => {
	const { category, fetchMoreCategories } = useCategoryList(ROOT_CATEGORY_ID, CATEGORY_LIMIT, CONTENT_LIMIT);

	const handleBack = useCallback(() => {
		// TODO should we prompt before closing the app?
		// Close the app
		if (window.webOS) {
			window.webOS.platformBack();
		} else if (window.tizen) {
			window.tizen.application.getCurrentApplication().exit();
		}
		return true;
	}, []);

	useOnBack(handleBack);

	useEffect(() => {
		if (category) {
			page('CategoryList', category.title, {
				categoryId: category.id,
			});
		}
	}, [category]);

	const renderItem: RenderItem<Category> = useCallback(({ item }) => {
		return <CategoryList key={item.id} category={item} />;
	}, []);

	const { heroContent, categories } = useMemo(() => {
		// Filter out empty categories
		const availableCategories = category?.categories?.filter((cat) => cat.content && cat.content.length > 0) ?? [];

		const firstCategory = availableCategories[0] ?? null;
		const firstContent = firstCategory?.content?.[0] ?? null;

		// Remove the category from the list if it is a hero only category
		if (firstCategory?.moduleTemplate === ModuleTemplate.HERO) {
			return {
				heroContent: firstContent,
				categories: availableCategories.slice(1) ?? [],
			};
		}

		return {
			heroContent: firstContent,
			categories: availableCategories,
		};
	}, [category]);

	if (!heroContent) return null;

	return (
		<Hero contentId={heroContent.id as number}>
			<Container>
				<CategoryListPageGrid
					onEndReached={fetchMoreCategories}
					endReachedThreshold={5}
					columns={1}
					items={categories}
					renderItem={renderItem}
				/>
			</Container>
		</Hero>
	);
};
