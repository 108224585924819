import { Exception } from '../exceptions';

export class VFIUtils {
	static async wait(time: number) {
		return new Promise<void>(resolve => setTimeout(() => resolve(), time));
	}

	// @ts-ignore
	static async checkUntil({ data, checker, resolver, interval, remainingChecks = 0 }: any) {
		if (resolver(data)) return data;
		const checkedData = await checker(data);
		if (resolver(checkedData)) return checkedData;
		if (remainingChecks <= 0) throw new Exception('Max checks exceeded');
		await VFIUtils.wait(interval);

		return VFIUtils.checkUntil({
			data: checkedData,
			checker,
			resolver,
			interval,
			remainingChecks: remainingChecks - 1,
		});
	}
}
