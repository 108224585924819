import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/is';

const initialLanguage = 'is';

moment.locale(initialLanguage);

i18n.use(initReactI18next).init({
	resources: {
		is: {
			translation: require('../config/is.json'),
		},
		en: {
			translation: require('../config/en.json'),
		},
	},
	lng: initialLanguage,
	fallbackLng: 'is',
	keySeparator: '.',
	interpolation: {
		escapeValue: false,
	},
});

export default i18n;
