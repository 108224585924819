import styled from 'styled-components';
import age_0 from '../../images/0@1.5x.png';
import age_6 from '../../images/6@1.5x.png';
import age_9 from '../../images/9@1.5x.png';
import age_12 from '../../images/12@1.5x.png';
import age_16 from '../../images/16@1.5x.png';
import age_18 from '../../images/18@1.5x.png';

const ageRatings: Record<number, string> = {
	0: age_0,
	6: age_6,
	9: age_9,
	12: age_12,
	16: age_16,
	18: age_18,
};

export const AgeRating = styled.div<{ ageRating?: number | null }>`
	float: left;
	width: 50px;
	height: 50px;
	margin-left: 30px;
	background-size: 50px 50px;
	background-repeat: no-repeat;
	background-image: ${({ ageRating }) => {
		if (ageRating !== null && ageRating !== undefined && ageRating in ageRatings) {
			return `url(${ageRatings[ageRating]})`;
		}

		return 'none';
	}};
`;
