import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button as BaseButton } from '@vodafoneis/sjonvarpskjarni-js-lib';
import {
	BUTTON_BACKGROUND,
	PRIMARY,
	SECONDARY,
	SECONDARY_DARKENED,
} from '../styles/colors';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';

type ButtonContainerProps = {
	withSubtitle: boolean;
	type?: 'submit' | 'reset' | 'button';
	focused?: boolean;
};

export const ButtonBaseContainer = styled(BaseButton)<ButtonContainerProps>`
	position: relative;
	height: 87px;
	min-width: 240px;
	padding: 12px 24px;
	background-color: ${({ focused }) => (focused ? PRIMARY : BUTTON_BACKGROUND)};
	color: ${SECONDARY_DARKENED};
	border: 1px solid transparent;
	border-radius: 6px;
	transition: background 0.2s ease-in;
	cursor: pointer;
	font-size: 26px;
`;

const ButtonContainer = styled(ButtonBaseContainer)<ButtonContainerProps>`
	display: flex;
	flex: auto;
	flex-direction: column;
	justify-content: center;
	align-items: ${(props) => (props.withSubtitle ? 'left' : 'center')};
`;

export const FocusableButtonContainer = withFocusable<
	ButtonContainerProps & { hasInitialFocus?: boolean; className?: string }
>()(({ children, hasInitialFocus, stealFocus, focused, ...restProps }) => {
	useEffect(() => {
		if (hasInitialFocus) {
			stealFocus();
		}
	}, [hasInitialFocus, stealFocus]);

	return (
		<ButtonContainer focused={focused} {...restProps}>
			{children}
		</ButtonContainer>
	);
});

export const ButtonTitle = styled.span<ButtonContainerProps>`
	font-size: 26px;
	line-height: 1.2;
	font-weight: bold;
	text-align: ${(props) => (props.withSubtitle ? 'left' : 'center')};
`;

export const ButtonSubtitle = styled(ButtonTitle)`
	color: ${SECONDARY};
	font-size: 24px;
`;

export type ButtonProps = {
	title?: string | null;
	className?: string;
	subtitle?: string | null;
	type?: 'submit' | 'reset' | 'button';
	onClick?: () => void;
	hasInitialFocus?: boolean;
};

export const Button: React.FC<ButtonProps> = ({ title, subtitle, className, type, onClick, hasInitialFocus }) => {
	return (
		<FocusableButtonContainer
			type={type}
			className={className}
			hasInitialFocus={hasInitialFocus}
			onEnterPress={onClick}
			withSubtitle={!!subtitle}
		>
			<ButtonTitle withSubtitle={!!subtitle}>{title}</ButtonTitle>
			<ButtonSubtitle withSubtitle={!!subtitle}>{subtitle}</ButtonSubtitle>
		</FocusableButtonContainer>
	);
};
