import { useMemo } from 'react';
import { ApolloQueryResult } from '@apollo/client/core/types';
import FRONTPAGE_ADS_QUERY from '../queries/FrontpageAds.graphql';
import FRONTPAGE_CATEGORIES_QUERY from '../queries/FrontpageCategories.graphql';
import { useAuthenticatedQuery } from './useAuthenticatedQuery';
import { Ad, factory } from '../models';

type UseFrontpageProps = {
	largeRegionId: number;
	smallRegionId: number;
	servicesRegionId: number;
	categoriesRegionId: number;
	contentLimit: number;
};

type UseFrontpageReturn = {
	large: Ad[];
	small: Ad[];
	services: Ad[];
	categories: Ad[];
	refetchAds: () => Promise<ApolloQueryResult<Ad[]>>;
	refetchCategories: () => Promise<ApolloQueryResult<Ad[]>>;
	loadingAds: boolean;
	loadingCategories: boolean;
};

export const useFrontpage = ({
	largeRegionId,
	smallRegionId,
	servicesRegionId,
	categoriesRegionId,
	contentLimit,
}: UseFrontpageProps): UseFrontpageReturn => {
	const adsQueryOptions = useMemo(
		() => ({
			variables: {
				largeRegionId,
				smallRegionId,
				servicesRegionId,
			},
		}),
		[largeRegionId, servicesRegionId, smallRegionId]
	);
	const { data: adsData, refetch: refetchAds, loading: loadingAds } = useAuthenticatedQuery(
		FRONTPAGE_ADS_QUERY,
		adsQueryOptions
	);

	const categoriesQueryOptions = useMemo(
		() => ({
			variables: { categoriesRegionId, contentLimit },
		}),
		[categoriesRegionId, contentLimit]
	);

	const { data: categoriesData, refetch: refetchCategories, loading: loadingCategories } = useAuthenticatedQuery(
		FRONTPAGE_CATEGORIES_QUERY,
		categoriesQueryOptions
	);

	const large = useMemo(() => adsData?.large?.map((ad: Ad) => factory(ad)) ?? [], [adsData]);
	const small = useMemo(() => adsData?.small?.map((ad: Ad) => factory(ad)) ?? [], [adsData]);
	const services = useMemo(() => adsData?.services?.map((ad: Ad) => factory(ad)) ?? [], [adsData]);
	const categories = useMemo(() => categoriesData?.categories?.map((ad: Ad) => factory(ad)) ?? [], [categoriesData]);

	return { large, small, services, categories, refetchAds, refetchCategories, loadingAds, loadingCategories };
};
