import { QueryHookOptions } from '@apollo/client';
import { useMemo } from 'react';
import type { Purchase } from '../models';
import type { FetchMoreCallback } from './useFetchMore';
import { useFetchMoreQuery } from './useFetchMoreQuery';
import { PURCHASES_QUERY } from '../queries';
import { factory } from '../models';

const itemCountExtractor = (data: any) => data?.purchases?.length ?? null;

type UsePurchasesReturn = {
	purchases: Purchase[];
	fetchMorePurchases: FetchMoreCallback;
	loadingPurchases: boolean;
	refetchPurchases: any;
};

export const usePurchases = (limit: number, queryOptions: QueryHookOptions<any, any> = {}): UsePurchasesReturn => {
	const options = useMemo(
		() => ({
			variables: {
				limit,
			},
			...queryOptions,
		}),
		[limit, queryOptions]
	);

	const { data, fetchMore, loading, refetch } = useFetchMoreQuery(PURCHASES_QUERY, itemCountExtractor, options);

	const purchases = useMemo(() => data?.purchases?.map((purchase: Purchase) => factory(purchase)) ?? [], [data]);

	return { purchases, fetchMorePurchases: fetchMore, loadingPurchases: loading, refetchPurchases: refetch };
};
