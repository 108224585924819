import { useQuery, QueryHookOptions, OperationVariables, QueryResult } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { useMemo } from 'react';
import { useQueryHeaders } from './useQueryHeaders';

export function useAuthenticatedQuery<TData = any, TVariables = OperationVariables>(
	query: DocumentNode,
	options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> {
	const headers = useQueryHeaders(options?.context?.headers ?? {});

	const queryOptions = useMemo(
		() => ({
			...options,
			context: {
				...(options?.context ? options.context : {}),
				headers,
			},
		}),
		[headers, options]
	);

	return useQuery(query, queryOptions);
}
