import { UUID } from '@vodafoneis/sjonvarpskjarni-js-lib';
import { detectDeviceType } from '../api/user';

export const identify = (user: any) => {
	if (window.analytics && user) {
		analytics.identify(user.id, {
			name: user.name,
			email: user.username,
		});
	}
};

export const page = (category?: string, name?: string, properties?: { [key: string]: any }) => {
	if (window.analytics) {
		analytics.page(category, name, properties);
	}
};

export const track = (event: string, data?: { [key: string]: any }) => {
	if (window.analytics) {
		analytics.track(event, data, {
			device: {
				model: detectDeviceType(),
			},
		});
	}
};

export const generatePlaybackSessionId = (contentId: string, deviceId: string) => {
	const parts = [contentId, deviceId, new Date().toISOString()];

	return UUID.fromString(parts.join('_'));
};
