import { useMemo } from 'react';
import type { QueryHookOptions } from '@apollo/client';
import { ApolloError } from '@apollo/client';
import type { Content } from '../models';
import { useFetchMoreQuery } from './useFetchMoreQuery';
import { FAVORITES_QUERY } from '../queries';
import { factory } from '../models';
import type { FetchMoreCallback } from './useFetchMore';

const itemCountExtractor = (data: any) => data?.favorites?.length ?? null;

type UseFavoritesReturn = {
	favorites: Content[] | null;
	fetchMoreFavorites: FetchMoreCallback;
	loadingFavorites: boolean;
	favoritesError: ApolloError;
	refetchFavorites: any;
};

export const useFavorites = (limit: number, queryOptions: QueryHookOptions<any, any> = {}): UseFavoritesReturn => {
	const options = useMemo(
		() => ({
			variables: {
				limit,
			},
			fetchMoreVariables: { onlyContent: true },
			...queryOptions,
		}),
		[limit, queryOptions]
	);

	const { data, fetchMore, loading, refetch, error } = useFetchMoreQuery(
		FAVORITES_QUERY,
		itemCountExtractor,
		options
	);

	const favorites = useMemo(() => (data?.favorites ? data.favorites.map((c: any) => factory(c)) : null), [data]);

	return {
		favorites,
		fetchMoreFavorites: fetchMore,
		loadingFavorites: loading,
		favoritesError: error,
		refetchFavorites: refetch,
	};
};
