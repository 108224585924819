import { useMemo } from 'react';
import { File, FileType, License, Playable } from '../models';

const { DASH } = FileType;

export type UsePlayableReturn = {
	watchableLicense: License;
	files: File[];
	licenses: License[];
	isWatchable: boolean;
	isPlayable: boolean;
};

export const usePlayable = (playable: Playable): UsePlayableReturn => {
	const files = useMemo(() => playable?.files.filter((file) => [DASH].includes(file.type)) ?? null, [playable]);

	const licenses = useMemo(() => playable?.licenses ?? [], [playable]);

	const watchableLicense = useMemo(() => playable?.getWatchableLicense(), [playable]);

	const isPlayable = useMemo(() => files?.length > 0, [files]);

	const isWatchable = useMemo(() => isPlayable === true && watchableLicense !== null, [isPlayable, watchableLicense]);

	return { watchableLicense, files, licenses, isPlayable, isWatchable };
};
