import { Playable } from './Playable';
import type { Series } from './Series';
import type { Image } from './Image';
import { generateImagePath, ImagePathParams } from '../utils/ImageUtils';

export class Episode extends Playable {
	episodeSeason?: number;
	episodeNumber?: number;
	series?: Series;
	stillImage?: Image;
	maxViewingDuration?: number;

	getPosterImageUrl(params: ImagePathParams = {}) {
		if (this.series?.posterImage) {
			return generateImagePath({
				hash: this.series.posterImage.hash,
				...params,
			});
		}

		return super.getPosterImageUrl(params);
	}

	getBackdropImageUrl(params: ImagePathParams = {}) {
		if (this.series?.backdropImage) {
			return generateImagePath({
				hash: this.series.backdropImage.hash,
				...params,
			});
		}

		return super.getBackdropImageUrl(params);
	}

	getStillImageUrl(params: ImagePathParams = {}) {
		if (this.stillImage) {
			return generateImagePath({
				hash: this.stillImage.hash,
				...params,
			});
		}

		return null;
	}

	getPromoImageUrl(params: ImagePathParams = {}) {
		if (this.series?.promoImage) {
			return generateImagePath({
				hash: this.series.promoImage.hash,
				...params,
			});
		}

		return super.getBackdropImageUrl(params);
	}

	getStatisticsData() {
		return {
			...super.getStatisticsData(),
			...(this.series
				? {
						seriesId: this.series.id,
						seriesTitle: this.series.title,
						episodeSeason: this.episodeSeason,
						episodeNumber: this.episodeNumber,
				  }
				: {}),
		};
	}
}
