import styled from 'styled-components';
import React from 'react';
import { ExpandableText } from '../ExpandableText';

export const ContentInfoDescriptionContainer = styled.div`
	font-size: 30px;
	line-height: 1.2;
	margin-top: 20px;
	max-width: 1065px;
	font-weight: normal;
`;

type ContentInfoDescriptionProps = {
	description?: string;
};

export const ContentInfoDescription: React.FC<ContentInfoDescriptionProps> = ({ description }) => {
	return (
		<ContentInfoDescriptionContainer>
			<ExpandableText numberOfLines={5}>{description}</ExpandableText>
		</ContentInfoDescriptionContainer>
	);
};
