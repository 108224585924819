import { ClientResponse } from '../api';
import { Exception } from '../exceptions';

export class KeyedObjectToModelArrayInterceptor {
	modelMap: { [key: string]: any };
	constructor(modelMap: { [key: string]: any }) {
		if (!modelMap || Object.keys(modelMap).length <= 0) {
			throw new Exception('KeyedObjectToModelInterceptor requires modelMap');
		}
		this.modelMap = modelMap;
	}

	async response(response: ClientResponse) {
		Object.keys(this.modelMap).forEach(key => {
			if (response.data && key in response.data) {
				const modelArray = response.data[key];
				if (modelArray && modelArray.length > 0) {
					response.data[key] = modelArray.map((data: any) => new this.modelMap[key](data));
				}
			}
		});
	}
}
