import styled from 'styled-components';
import React from 'react';
import { useClassNames } from '@vodafoneis/sjonvarpskjarni-js-lib';
import { SIDE_MARGINS } from '../config/layout';

const OverflowContainer = styled.div`
	overflow: hidden;
	height: 100%;
	width: 100%;
`;

export const ContentContainer = styled.div`
	margin: 0 ${SIDE_MARGINS}px;
`;

type ContainerProps = {
	className?: string;
};

export const Container: React.FC<ContainerProps> = ({ children, className }) => {
	const classNames = useClassNames('container', { [className as any]: !!className });

	return (
		<OverflowContainer className={classNames}>
			<ContentContainer>{children}</ContentContainer>
		</OverflowContainer>
	);
};
