export enum ModelType {
	ACTOR = 'Actor',
	AD = 'Ad',
	CATEGORY = 'Category',
	CHANNEL = 'Channel',
	COLLECTION = 'Collection',
	MOVIE = 'Movie',
	SERIES = 'Series',
	EPISODE = 'Episode',
	EVENT = 'Event',
	RECORDING = 'Recording',
	RENTAL = 'Rental',
	FILE = 'File',
	LICENSE = 'License',
	TIMING = 'Timing',
	IMAGE = 'Image',
	TAG = 'Tag',
	GENRE = 'Genre',
	SEASON = 'Season',
	DIRECTOR = 'Director',
	PRODUCT = 'Product',
	PROPERTY = 'Property',
	POSITION = 'Position',
	PRICE = 'Price',
	PURCHASE = 'Purchase',
	VAST_TYPE = 'Vast',
	PROVIDER = 'Provider',
	SEARCH_RESULTS = 'SearchResults',
	USER_SETTINGS = 'UserSettings',
}
