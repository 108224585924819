import type { Content } from './Content';
import type { License } from './License';

export class Rental {
	id?: number;
	validFrom?: Date;
	validUntil?: Date;
	ready?: boolean;
	content?: Content;
	license?: License;

	constructor(data: any) {
		Object.assign(this, data);

		const { validFrom, validUntil } = data;

		if (validFrom) this.validFrom = new Date(validFrom * 1000);

		if (validUntil) this.validUntil = new Date(validUntil * 1000);
	}

	isValid() {
		if (!this.validFrom || !this.validUntil) return false;

		const now = new Date();

		return this.validFrom.getTime() <= now.getTime() && this.validUntil.getTime() >= now.getTime();
	}

	isReady() {
		return this.ready;
	}
}
