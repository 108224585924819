import { ContentComponent } from '../components/ContentComponent';
import { useCallback } from 'react';
import { Content, ModuleType } from '@vodafoneis/sjonvarpskjarni-js-lib';
import { RenderItem } from '../components/Grid';

type UseContentRendererOptions = {
	moduleType?: ModuleType;
	onItemFocused?: FocusedCallback;
	hasInitialFocus?: boolean;
};

export const useContentRenderer = ({ moduleType }: UseContentRendererOptions): RenderItem<Content> => {
	return useCallback(
		({ item, index }) => <ContentComponent key={item.id} moduleType={moduleType} content={item} />,
		[moduleType]
	);
};
