import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import { Content } from '../models';
import { UPDATE_FAVORITES } from '../queries';
import { Request } from '../api';
import { useApiClient } from './useApiClient';

export const useFavorite = (content: Content) => {
	const apolloClient = useApolloClient();

	const apiClient = useApiClient();

	const createFavorite = useCallback(async () => {
		if (!content) return;

		const request = new Request('favorite', {
			body: {
				contentId: content.id,
			},
		});

		const response = await apiClient.post(request);

		return response.isSuccess();
	}, [apiClient, content]);

	const deleteFavorite = useCallback(async () => {
		if (!content) return;

		const request = new Request('favorite/$$contentId$$', {
			pathVariables: {
				contentId: String(content.id),
			},
		});

		const response = await apiClient.del(request);

		return response.isSuccess();
	}, [apiClient, content]);

	const toggle = useCallback(async () => {
		if (!content) return;

		const currentValue = content.favorite;

		apolloClient.writeQuery({
			query: UPDATE_FAVORITES,
			data: {
				content: {
					id: content.id,
					favorite: !currentValue,
				},
			},
			variables: { id: content.id },
		});

		let success = false;
		try {
			if (currentValue === true) {
				success = await deleteFavorite();
			} else if (currentValue === false) {
				success = await createFavorite();
			}
		} catch (exception) {}

		if (!success) {
			// Rollback if update was not successful.
			apolloClient.writeQuery({
				query: UPDATE_FAVORITES,
				data: {
					content: {
						id: content.id,
						favorite: currentValue,
					},
				},
				variables: { id: content.id },
			});
		}
	}, [apolloClient, content, createFavorite, deleteFavorite]);

	return { favorite: content?.favorite ?? null, toggleFavorite: toggle };
};
