import { useMemo } from 'react';
import { Playable } from '../models';

type UseProgressReturn = {
	progress: number;
	hasProgress: boolean;
	position: number;
};

export const useProgress = (content: Playable): UseProgressReturn => {
	const progress = useMemo(() => content?.getViewingProgress(), [content]);
	const hasProgress = progress > 0 && progress < 1;

	return { progress, hasProgress, position: content?.getPosition() };
};
