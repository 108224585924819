import type { Image } from './Image';
import type { Content } from './Content';
import type { Ad } from './Ad';
import { generateImagePath, ImagePathParams } from '../utils';
import { ModuleType } from './ModuleType';
import { ModuleTemplate } from './ModuleTemplate';

export class Category {
	id?: number;
	title?: string;
	color?: string;
	moduleType?: ModuleType;
	moduleTemplate?: ModuleTemplate;
	viewTemplate?: string;
	posterImage?: Image;
	backdropImage?: Image;
	categories?: Category[];
	content?: Content[];
	ads?: Ad[];

	constructor(data: object) {
		Object.assign(this, data);
	}

	getPosterImageUrl(params: ImagePathParams = {}) {
		if (this.posterImage) {
			return generateImagePath({
				hash: this.posterImage.hash,
				...params,
			});
		}

		return null;
	}

	getBackdropImageUrl(params: ImagePathParams = {}) {
		if (this.backdropImage) {
			return generateImagePath({
				hash: this.backdropImage.hash,
				...params,
			});
		}

		return null;
	}
}
