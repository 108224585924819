import { DocumentNode } from 'graphql';

import AdFragment from './AdFragment.graphql';
import AdImagesFragment from './AdImagesFragment.graphql';
import Category from './Category.graphql';
import CategoryFragment from './CategoryFragment.graphql';
import CategoryList from './CategoryList.graphql';
import Channel from './Channel.graphql';
import Collection from './Collection.graphql';
import ChannelFragment from './ChannelFragment.graphql';
import Channels from './Channels.graphql';
import CollectionFragment from './CollectionFragment.graphql';
import Content from './Content.graphql';
import ContentDetailsFragment from './ContentDetailsFragment.graphql';
import ContentFragment from './ContentFragment.graphql';
import ContentImagesFragment from './ContentImagesFragment.graphql';
import Events from './Events.graphql';
import Favorites from './Favorites.graphql';
import File from './File.graphql';
import FilesFragment from './FilesFragment.graphql';
import FrontpageAds from './FrontpageAds.graphql';
import FrontpageCategories from './FrontpageCategories.graphql';
import GenresFragment from './GenresFragment.graphql';
import LicensesAuthenticatedFragment from './LicensesAuthenticatedFragment.graphql';
import LicensesFragment from './LicensesFragment.graphql';
import PositionFragment from './PositionFragment.graphql';
import Product from './Product.graphql';
import ProductDetailsFragment from './ProductDetailsFragment.graphql';
import ProductFragment from './ProductFragment.graphql';
import Products from './Products.graphql';
import Purchases from './Purchases.graphql';
import Rentals from './Rentals.graphql';
import Search from './Search.graphql';
import SeriesEpisodes from './SeriesEpisodes.graphql';
import TimingFragment from './TimingFragment.graphql';
import TimingsFragment from './TimingsFragment.graphql';
import UpcomingLive from './UpcomingLive.graphql';
import UpdateFavorites from './UpdateFavorites.graphql';
import UpdateUser from './UpdateUser.graphql';
import UpdatePosition from './UpdatePosition.graphql';
import User from './User.graphql';
import UserFragment from './UserFragment.graphql';
import WatchHistory from './WatchHistory.graphql';

export const AD_FRAGMENT: DocumentNode = AdFragment;
export const AD_IMAGES_FRAGMENT: DocumentNode = AdImagesFragment;
export const CATEGORY_QUERY: DocumentNode = Category;
export const CATEGORY_FRAGMENT: DocumentNode = CategoryFragment;
export const CATEGORY_LIST_QUERY: DocumentNode = CategoryList;
export const CHANNEL_QUERY: DocumentNode = Channel;
export const CHANNEL_FRAGMENT: DocumentNode = ChannelFragment;
export const CHANNELS_QUERY: DocumentNode = Channels;
export const COLLECTION_QUERY: DocumentNode = Collection;
export const COLLECTION_FRAGMENT: DocumentNode = CollectionFragment;
export const CONTENT_QUERY: DocumentNode = Content;
export const CONTENT_DETAILS_FRAGMENT: DocumentNode = ContentDetailsFragment;
export const CONTENT_FRAGMENT: DocumentNode = ContentFragment;
export const CONTENT_IMAGES_FRAGMENT: DocumentNode = ContentImagesFragment;
export const EVENTS_QUERY: DocumentNode = Events;
export const FAVORITES_QUERY: DocumentNode = Favorites;
export const FILE_QUERY: DocumentNode = File;
export const FILES_FRAGMENT: DocumentNode = FilesFragment;
export const FRONTPAGE_ADS_QUERY: DocumentNode = FrontpageAds;
export const FRONTPAGE_CATEGORIES_QUERY: DocumentNode = FrontpageCategories;
export const GENRES_FRAGMENT: DocumentNode = GenresFragment;
export const LICENSES_AUTHENTICATED_FRAGMENT: DocumentNode = LicensesAuthenticatedFragment;
export const LICENSES_FRAGMENT: DocumentNode = LicensesFragment;
export const POSITION_FRAGMENT: DocumentNode = PositionFragment;
export const PRODUCT_QUERY: DocumentNode = Product;
export const PRODUCT_DETAILS_FRAGMENT: DocumentNode = ProductDetailsFragment;
export const PRODUCT_FRAGMENT: DocumentNode = ProductFragment;
export const PRODUCTS_QUERY: DocumentNode = Products;
export const PURCHASES_QUERY: DocumentNode = Purchases;
export const RENTALS_QUERY: DocumentNode = Rentals;
export const SEARCH_QUERY: DocumentNode = Search;
export const SERIES_EPISODES_QUERY: DocumentNode = SeriesEpisodes;
export const TIMING_FRAGMENT: DocumentNode = TimingFragment;
export const TIMINGS_FRAGMENT: DocumentNode = TimingsFragment;
export const UPCOMING_LIVE: DocumentNode = UpcomingLive;
export const UPDATE_FAVORITES: DocumentNode = UpdateFavorites;
export const UPDATE_USER_QUERY: DocumentNode = UpdateUser;
export const UPDATE_POSITION: DocumentNode = UpdatePosition;
export const USER_QUERY: DocumentNode = User;
export const USER_FRAGMENT: DocumentNode = UserFragment;
export const WATCH_HISTORY: DocumentNode = WatchHistory;
