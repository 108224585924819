import { Content, useMaxViewingDuration } from '@vodafoneis/sjonvarpskjarni-js-lib';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useAvailableUntil } from '../../hooks/useAvailableUntil';
import { useRentedUntil } from '../../hooks/useRentedUntil';
import { ContentInfoYear } from './ContentInfoYear';
import { ContentInfoDataItem } from './ContentInfoDataItem';

const ContentInfoDetailsContainer = styled.div`
	font-size: 24px;
	font-weight: bold;
	line-height: 1.2;
	margin-top: 20px;
	overflow: hidden;
`;

type ContentInfoDetailsProps = {
	content: Content;
};

export const ContentInfoDetails: React.FC<ContentInfoDetailsProps> = ({ content }) => {
	const { t } = useTranslation();

	const maxViewingDuration = useMaxViewingDuration(content);
	const availableUntil = useAvailableUntil(content);
	const rentedUntil = useRentedUntil(content);

	return (
		<ContentInfoDetailsContainer>
			<ContentInfoYear content={content} />
			{maxViewingDuration && !rentedUntil && (
				<ContentInfoDataItem
					title={t('ContentInfo.MaxViewingDuration.Title')}
					value={t('ContentInfo.MaxViewingDuration.Data', { maxViewingDuration: maxViewingDuration / 3600 })}
				/>
			)}
			{availableUntil && !rentedUntil && (
				<ContentInfoDataItem title={t('ContentInfo.AvailableUntil.Title')} value={availableUntil} />
			)}
			{rentedUntil && <ContentInfoDataItem title={t('ContentInfo.RentedUntil.Title')} value={rentedUntil} />}
		</ContentInfoDetailsContainer>
	);
};
