import { useMemo } from 'react';
import { ApolloQueryResult, QueryHookOptions } from '@apollo/client';
import { useAuthenticatedQuery } from './useAuthenticatedQuery';
import { SERIES_EPISODES_QUERY } from '../queries';
import { Episode, factory } from '../models';

type UseEpisodesReturn = {
	episodes: Episode[];
	refetchEpisodes: () => Promise<ApolloQueryResult<any>>;
	episodesLoading: boolean;
};

export const useEpisodes = (
	seriesId: number,
	season: number,
	queryOptions: QueryHookOptions<any, any> = {}
): UseEpisodesReturn => {
	const options = useMemo(
		() => ({
			skip: !seriesId || season === null,
			variables: {
				seriesId: seriesId ?? null,
				season: season ?? null,
			},
			...queryOptions,
		}),
		[queryOptions, season, seriesId]
	);

	const { data, refetch, loading } = useAuthenticatedQuery(SERIES_EPISODES_QUERY, options);

	const episodes = useMemo(() => {
		if (!data?.series) return [];

		return data.series.episodes
			.map((episode: any) => factory({ ...episode, series: data.series }))
			.filter((episode: Episode) => episode.licenses.length > 0)
			.sort((a: Episode, b: Episode) => {
				const licenseA = a.getFirstLicense();
				const licenseB = b.getFirstLicense();

				const sortA = licenseA && licenseA.isCatchup() ? -licenseA.validFrom.getTime() : a.episodeNumber;
				const sortB = licenseB && licenseB.isCatchup() ? -licenseB.validFrom.getTime() : b.episodeNumber;

				if (sortA === sortB) return 0;

				return sortA > sortB ? 1 : -1;
			});
	}, [data]);

	return { episodes, refetchEpisodes: refetch, episodesLoading: loading };
};
