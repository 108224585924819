import { ClientInterceptor } from '../interceptors';

export class Request {
	interceptors: ClientInterceptor[];
	url: string;
	headers: { [key: string]: any };
	pathVariables: { [key: string]: any };
	queryParams: { [key: string]: any };
	body?: { [key: string]: any } | null;
	method?: 'GET' | 'POST' | 'PUT' | 'DELETE';

	constructor(url: string, options: RequestOptions = {}) {
		this.interceptors = [];
		this.url = url;
		this.headers = {
			'Access-Control-Request-Headers': 'X-Status-Code,X-Status-Reason',
			...options?.headers,
		};
		this.pathVariables = options.pathVariables || {};
		this.queryParams = options.queryParams || {};
		this.body = options.body || null;
	}

	addInterceptor(interceptor: ClientInterceptor) {
		this.interceptors.push(interceptor);
		return this;
	}

	removeInterceptor(interceptor: ClientInterceptor) {
		this.interceptors.splice(this.interceptors.indexOf(interceptor));
		return this;
	}

	getFullUrl() {
		let fullUrl = this.pathVariables
			? Request.generatePathFromPathVariables(this.url, this.pathVariables)
			: this.url;
		fullUrl = this.queryParams ? Request.appendQueryParameters(fullUrl, this.queryParams) : fullUrl;
		return fullUrl;
	}

	withHeaders(headers: { [key: string]: string }) {
		this.headers = Object.assign(this.headers, headers);
		return this;
	}

	addHeaders(headers: { [key: string]: string }) {
		this.headers = { ...this.headers, ...headers };
		return this;
	}

	addPathVariables(pathVariables: { [key: string]: string }) {
		this.pathVariables = { ...this.pathVariables, ...pathVariables };
		return this;
	}

	addQueryParams(queryParams: { [key: string]: string }) {
		this.queryParams = { ...this.queryParams, ...queryParams };
		return this;
	}

	addToBody(body: { [key: string]: string }) {
		this.body = { ...this.body, ...body };
		return this;
	}

	static generatePathFromPathVariables(url: string, pathVariables: { [key: string]: string }) {
		if (pathVariables) {
			Object.keys(pathVariables).forEach(key => {
				// eslint-disable-next-line no-param-reassign
				url = url.replace(`$$${key}$$`, encodeURIComponent(pathVariables[key]));
			});
		}
		return url;
	}

	static appendQueryParameters(url: string, queryParameters: { [key: string]: any }) {
		if (queryParameters) {
			const queryParamKeys = Object.keys(queryParameters);
			if (queryParamKeys.length > 0) {
				let queryParamsArr: string[] = [];
				queryParamKeys.forEach(paramName => {
					const paramValue = queryParameters[paramName];
					if (paramValue !== null && paramValue !== undefined) {
						if (paramValue.constructor === Array) {
							queryParamsArr = queryParamsArr.concat(
								paramValue.map(
									(value: string) => `${encodeURIComponent(paramName)}=${encodeURIComponent(value)}`
								)
							);
						} else {
							queryParamsArr.push(`${encodeURIComponent(paramName)}=${encodeURIComponent(paramValue)}`);
						}
					}
				});
				// eslint-disable-next-line no-param-reassign
				url += `?${queryParamsArr.join('&')}`;
			}
		}
		return url;
	}

	cancel() {
		// Does not work.
	}
}
